import React from "react";
import { connect } from "react-redux";
import Switch, { Case, Default } from "react-switch-case";

import _ from "lodash";

import SlideView from "components/view/SlideView";
import claimTypesConstants from "constants/claimTypes";
import flowConstants from "constants/flow";
import StateRouterCarContainer from "containers/StateRouterCarContainer";
import StateRouterGeneralContainer from "containers/StateRouterGeneralContainer";
import StateRouterHomeContainer from "containers/StateRouterHomeContainer";
import StateRouterPersonContainer from "containers/StateRouterPersonContainer";
import ClaimTypeStepContainer from "containers/step/claimType/ClaimTypeStepContainer";
import IntroStepContainer from "containers/step/intro/IntroStepContainer";

function StateRouterContainer({ direction, step, fromSummary, choiceType }) {
  return (
    <SlideView step={step} direction={direction === flowConstants.direction.FORWARD ? "leftRight" : "rightLeft"} fromSummary={fromSummary}>
      <Switch condition={step.name}>
        <Case value={flowConstants.steps.INTRO.NAME}>
          {/* step 1 */}
          <IntroStepContainer />
        </Case>
        <Case value={flowConstants.steps.CLAIM_TYPE.NAME}>
          {/* step 2 */}
          <ClaimTypeStepContainer />
        </Case>
        <Default>
          <Switch condition={choiceType}>
            <Case value={claimTypesConstants.CAR}>
              <StateRouterCarContainer step={step} />
            </Case>
            <Case value={claimTypesConstants.HOME}>
              <StateRouterHomeContainer step={step} />
            </Case>
            <Case value={claimTypesConstants.PERSON}>
              <StateRouterPersonContainer step={step} />
            </Case>
            <Case value={claimTypesConstants.GENERAL}>
              <StateRouterGeneralContainer step={step} />
            </Case>
          </Switch>
        </Default>
      </Switch>
    </SlideView>
  );
}

const mapStateToProps = (state) => ({
  step: state.flow.step,
  direction: state.flow.direction,
  choiceType: _.at(state, "flow.forms.claimType.choiceType")[0] || "",
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StateRouterContainer);
