import {find, isUndefined} from "underscore";

import mapsAddressTypeConstants from "constants/mapsAddressTypes";

export function getAddress(addressComponents) {
  return {
    city: {
      zip: getItem(addressComponents, mapsAddressTypeConstants.ZIP_CODE),
      city: getItem(addressComponents, mapsAddressTypeConstants.CITY) || getItem(addressComponents, mapsAddressTypeConstants.POSTAL_TOWN),
    },
    address: {
      street: {
        name: getItem(addressComponents, mapsAddressTypeConstants.STREET) || getItem(addressComponents, mapsAddressTypeConstants.NEIGHBORHOOD),
      },
      streetNumber: getItem(addressComponents, mapsAddressTypeConstants.STREET_NUMBER),
    },
    country: getItem(addressComponents, mapsAddressTypeConstants.COUNTRY),
    countryCode: getItem(addressComponents, mapsAddressTypeConstants.COUNTRY, mapsAddressTypeConstants.PROPERTY_NAME_SHORT_NAME),
  };
}

function getItem(addressComponents, key, propertyName) {
  const component = find(addressComponents, addressComponent => !isUndefined(find(addressComponent.types, type => type === key)));
  return isUndefined(component) ? "" : (propertyName ? component[propertyName] : component.long_name);
}
