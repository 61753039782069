import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import VehicleIcon from "components/step/VehicleIcon";

import styles from "components/step/VehicleIconLabel.module.scss";

export default function VehicleIconLabel({label, info}) {
  const labelKey = info.isOwnVehicle ? "own" : "other";
  const labelElement =
    label
      ? <Text element="span" className={styles.text} label={label}/>
      : <Text element="span" className={styles.text} label={`vehicleInfo.label.${labelKey}.${info.type}`}/>;

  return (
    <Layout.OneRow spacing={10}>
      <VehicleIcon variant="variant2" {...info} />
      <div className={styles.label}>
        {labelElement}
      </div>
    </Layout.OneRow>
  );
}
