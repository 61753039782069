import React, {Component} from "react";

import Measure from "react-measure";

export default class Resizeable extends Component {
  state = {
    dimensions: null,
  };

  onResize = (contentRect) => {
    this.setState({
      dimensions: contentRect.bounds,
    });
  };

  render() {
    const {className, children} = this.props;

    return (
      <Measure bounds onResize={this.onResize}>
        {({measureRef}) => (
          <div ref={measureRef} className={className}>
            {children(this.state.dimensions)}
          </div>
        )}
      </Measure>
    );
  }
}
