import React, {Component} from 'react';

import {isMobile} from "react-device-detect";

import Layout from "components/common/Layout";
import MapForm from "components/form/MapForm";
import PickLocationMapField from "components/form/PickLocationMapField";
import {MapContext} from "containers/context/MapContainer";
import AccidentLocationSelectedLocation from "containers/step/accidentLocation/AccidentLocationSelectedLocation";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {getAddress} from "selectors/selectedLocation";

export default class AccidentLocationRight extends Component {
  static contextType = MapContext;

  onLocationClick = (location, callback) => {
    const {setFieldValue} = this.props;

    const geocoder = new this.context.maps.Geocoder();
    geocoder.geocode({location: location}, function (results, status) {
      if (status === 'OK') {
        setFieldValue("selectedLocation", results[0].formatted_address);
        setFieldValue("selectedLocationAddress", getAddress(results[0].address_components));
        setFieldValue("accidentSearch", null);
      } else {
        setFieldValue("selectedLocation", `${location.lat}, ${location.lng}`);
      }

      callback();
    });
  };

  render() {
    const {values: {focusLocation, countryName}} = this.props;

    return (
      <SplitViewContainer.Right noSpacing={true} map={true} {...this.props}>
        {isMobile
          ? null
          : (
            <MapForm buttonLabel="accidentLocation.content.openMap.label">
              <PickLocationMapField name="accidentLocation"
                                    center={focusLocation}
                                    country={countryName}
                                    onLocationClick={this.onLocationClick}/>
            </MapForm>
          )
        }
        <Layout>
          <Layout.OneColumn maxWidth={700} width="100%" justifyContent="center" spacing={10}>
            <AccidentLocationSelectedLocation {...this.props} places={this.context.maps.places}
                                              onLocationClick={this.onLocationClick}/>
          </Layout.OneColumn>
        </Layout>
      </SplitViewContainer.Right>
    );
  }
}
