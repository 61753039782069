import React, {Component} from "react";

import {Field} from "formik";
import _ from "lodash";
import {contains, identity, isArray, isEmpty, sortBy, without} from "underscore";

import CheckBox from "components/form/CheckBox/CheckBox";

const CheckBoxGroupContext = React.createContext();

export default class CheckBoxGroup extends Component {
  render() {
    const {name, onClick, choice = true, multiple = false, children} = this.props;

    return (
      <Field
        name={name}
        render={(fieldProps) => (
          <CheckBoxGroupContext.Provider value={{fieldProps, onClick, choice, multiple}}>
            {children}
          </CheckBoxGroupContext.Provider>
        )}
      />
    );
  }
}

class CheckBoxGroupChoice extends Component {
  static contextType = CheckBoxGroupContext;

  render() {
    const {label, isHtml, variant} = this.props;
    const labelProps = {
      element: "span",
      variant: variant,
      label,
      isHtml,
    };

    return (
      <CheckBoxGroupChoiceInner {...this.props} labelProps={labelProps}
                                context={this.context}/>
    );
  }
}

class CheckBoxGroupChoiceInner extends Component {
  onClick = () => {
    const {value, context, resetMultiple = false} = this.props;
    const {fieldProps, onClick} = context;
    const {field: {name, value: currentValue}, form: {setFieldValue, validateForm, values}} = fieldProps;

    let newValue = value;
    if (context.multiple && !resetMultiple) {
      newValue = isArray(currentValue) ? currentValue : [];

      if (contains(newValue, value)) {
        newValue = without(newValue, value);
      } else {
        newValue = sortBy([...newValue, value], identity);
      }

      if (isEmpty(newValue)) {
        newValue = null;
      }
    }

    setFieldValue(name, newValue, true);
    const newValues = _.set(_.cloneDeep(values), name, newValue);

    if (onClick) {
      onClick(newValue, () => {
        validateForm(newValues);
      });
    } else {
      validateForm(newValues);
    }
  };

  render() {
    const {value, context, condition} = this.props;
    const {field: {value: currentValue}} = context.fieldProps;

    if (condition === false) {
      return null;
    }

    const checked =
      isArray(currentValue)
        ? contains(currentValue, value)
        : value === currentValue;

    return (
      <CheckBox {...this.props}
                checked={checked}
                onClick={this.onClick}/>
    );
  }
}

CheckBoxGroup.Choice = CheckBoxGroupChoice;
