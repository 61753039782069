import React, {Component} from "react";

import Layout from "components/common/Layout";
import AutoCompleteField from "components/form/AutoCompleteField";
import {RegistryContext} from "containers/context/RegistyContainer";
import {filterMarkers, formatMarkerSuggestionValue} from "utility/mapSearch";

export default class MapSearch extends Component {
  static contextType = RegistryContext;

  fetchMapSearch = async (value) => {
    const {markers} = this.props;

    return filterMarkers(value, markers);
  };

  render() {
    const {name, searchSelected, isRequired = false} = this.props;

    return (
      <InnerMapSearch name={name} isRequired={isRequired} fetchMapSearch={this.fetchMapSearch} searchSelected={searchSelected}/>
    );
  }
}

class InnerMapSearch extends Component {
  render() {
    const {name, isRequired, fetchMapSearch, searchSelected} = this.props;

    return (
      <Layout.OneRow flex={1} spacing={8}>
        <AutoCompleteField name={name}
                           placeholder="mapSearch.placeholder"
                           variant="variant8"
                           isRequired={isRequired}
                           fetchSuggestions={fetchMapSearch}
                           getSuggestionValue={value => value ? formatMarkerSuggestionValue(value) : ""}
                           onSuggestionSelected={searchSelected}/>
      </Layout.OneRow>
    );
  }
}