import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import IcoMoon from "components/icon/IcoMoon";
import fontConstants from "constants/font";

export default class OnSiteRight extends Component {
  render() {
    const {onNextStep, isValid} = this.props;

    return (
      <NextStepButton component={ToggleButton}
                      buttonProps={{name: "isOnSite"}}
                      onNextStep={onNextStep}
                      isValid={isValid}>
        <Layout.OneRow spacing={10} flex={1}>
          <ToggleButton.Choice value={true}
                               label="button.yes"
                               icon={<IcoMoon icon={fontConstants.ICOMOON.PENCIL}/>}/>
          <ToggleButton.Choice value={false}
                               label="button.no"
                               icon={<IcoMoon icon={fontConstants.ICOMOON.HAND_SHAKE}/>}/>
        </Layout.OneRow>
      </NextStepButton>
    );
  }
}
