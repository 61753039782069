import _ from "lodash";
import {
  contains,
  every,
  filter,
  findIndex,
  isNull,
  isUndefined,
  keys,
  negate,
  object,
  rest,
  some
} from "underscore";

import booleanTypesConstants from "constants/booleanTypes"
import claimTypesConstants from "constants/claimTypes";
import flowConstants from "constants/flow";
import userIdentificationTypeConstants from "constants/userIdentificationType";
import vehicleTypeConstants from "constants/vehicleTypes";
import {calculateFutureFormState} from "selectors/flow/calculateFutureFormState";
import {determineFormsForReset} from "selectors/flow/determineFormsForReset";
import {postCalculateFormState} from "selectors/flow/postCalculateFormState";
import {preCalculateFormState} from "selectors/flow/preCalculateFormState/preCalculateFormState";
import {getSteps} from "selectors/flow/getSteps";

export function calculateFormState(state, nextStep, shouldFinishCurrentStep = false) {
  const {step} = state;

  const postCalculated = postCalculateFormState(state.step, state.forms);

  const withCurrentStep = step.name !== flowConstants.steps.CLAIM_TYPE.NAME
    ? shouldFinishCurrentStep
      ? [...state.finishedSteps, state.step]
      : state.finishedSteps
    : [
      {name: flowConstants.steps.INTRO.NAME},
      {name: flowConstants.steps.CLAIM_TYPE.NAME, skip: false},
    ];
  const finishedSteps = resetFinishedSteps({...state, forms: postCalculated, finishedSteps: withCurrentStep});

  const futureCalculated = calculateFutureFormState(state.step, postCalculated);
  const forms = preCalculateFormState(nextStep, futureCalculated, state.configuration);

  const previousForms = _.cloneDeep(forms);

  return {forms, previousForms, ...finishedSteps};
}

export function prepareFormState(state) {
  const postCalculated = postCalculateFormState(state.step, state.forms);
  const finishedSteps = resetFinishedSteps({...state, forms: postCalculated});
  const forms = calculateFutureFormState(state.step, postCalculated);

  return {
    ...state,
    forms,
    ...finishedSteps,
  };
}

function resetFinishedSteps(flowState) {
  const formsForReset = determineFormsForReset(flowState);

  return {
    finishedSteps: filterSteps(flowState.finishedSteps, formsForReset),
    activeStepsSnapshot: filterSteps(flowState.activeStepsSnapshot, formsForReset),
    fromSummaryActiveStepsSnapshot: filterSteps(flowState.fromSummaryActiveStepsSnapshot, formsForReset),
  };
}

function filterSteps(from, to) {
  return from.filter(fromStep => !some(to, toStep => isStepEqual(fromStep, toStep)))
}

export function getSummaryActiveStepsSnapshot(flowState, payload) {
  return [
    ...getSteps(flowState),
    ...flowState.finishedSteps,
  ].filter(step => !isStepEqual(step, payload));
}

export function getStoreKey(step) {
  const {name, substep, index} = step;

  return name + (isUndefined(substep) ? "" : `.${substep}`) + (isUndefined(index) ? "" : `[${index}]`);
}

export function getStepIndex(flowState) {
  const {step} = flowState;
  const steps = getSteps(flowState);

  return findStepIndex(step, steps);
}

export function getStepCount(flowState) {
  const steps = getSteps(flowState);

  return steps.length;
}

export function hasPreviousStep(flowState) {
  const previousStep = getPreviousStep(flowState);

  return !isStepEqual(previousStep, flowState.step);
}

export function getNextStep(flowState) {
  const {inSummary, step, finishedSteps, activeStepsSnapshot, fromSummaryActiveStepsSnapshot, dirtySteps} = flowState;
  const steps = getSteps(flowState);

  const didStepsChange = diffSteps(steps, activeStepsSnapshot).length > 0;
  if (inSummary) {
    const summarySteps = [
      ...diffSteps(steps, fromSummaryActiveStepsSnapshot),
      {name: flowConstants.steps.SUMMARY.NAME},
    ];

    if (didStepsChange) {
      return getNextUnfinishedStep(step, summarySteps, dirtySteps, finishedSteps);
    } else {
      return getNextDirectStep(step, summarySteps, finishedSteps);
    }
  } else {
    if (didStepsChange) {
      return getNextDirectStep(step, steps, finishedSteps);
    } else {
      return getNextUnfinishedStep(step, steps, dirtySteps, finishedSteps);
    }
  }
}

export function getPreviousStep(flowState) {
  const {inSummary, step, fromSummaryActiveStepsSnapshot} = flowState;
  const steps = getSteps(flowState);

  if (inSummary) {
    const summarySteps = diffSteps(steps, fromSummaryActiveStepsSnapshot);
    return getPreviousDirectStep(step, summarySteps);
  } else {
    return getPreviousDirectStep(step, steps);
  }
}

function getNextDirectStep(step, steps) {
  const index = findStepIndex(step, steps);
  if (index === -1 || index === steps.length - 1) { // not found (bug?) or last step
    return step;
  }

  if (steps[index + 1].skip) {
    return getNextDirectStep(steps[index + 1], steps);
  }

  return steps[index + 1];
}

function getPreviousDirectStep(step, steps) {
  const index = findStepIndex(step, steps);
  if (index === -1 || index === 0) { // not found (bug?) or first step
    return step;
  }

  if (steps[index - 1].skip) {
    return getPreviousDirectStep(steps[index - 1], steps);
  }

  return steps[index - 1];
}

function getNextUnfinishedStep(step, steps, dirtySteps, finishedSteps = []) {
  const index = findStepIndex(step, steps);
  if (index === -1) {
    return step;
  }

  const restSteps = rest(steps, index + 1);
  const foundIndex = findIndex(restSteps, step => findIndex(dirtySteps, dirtyStep => step.name === dirtyStep.name) !== -1 || !isFinished(step, finishedSteps) && (isUndefined(step.skip) ? true : !step.skip));
  if (foundIndex === -1) {
    return getNextDirectStep(step, steps);
  }

  return restSteps[foundIndex];
}

function findStepIndex(step, steps) {
  return steps.findIndex(value => isStepEqual(value, step));
}

function diffSteps(left, right) {
  return left.filter(leftStep => !some(right, rightStep => isStepEqual(leftStep, rightStep) && (!!leftStep.skip === !!rightStep.skip)));
}

function isFinished(step, finishedSteps) {
  return some(
    finishedSteps,
    finishedStep => isStepEqual(step, finishedStep),
  );
}

function isStepEqual(left, right) {
  return left.name === right.name && left.index === right.index;
}

export function evaluateRule(rule, filterData) {
  return every(keys(rule), function (key) {
    if (key === "damageType") {
      return isNull(rule[key]) || contains(filterData.damageType, rule[key])
    } else {
      return isNull(rule[key]) || rule[key] === filterData[key]
    }
  })
}

export function getFieldConfiguration(flowState) {
  if (!flowState.configuration.loaded) {
    return {};
  }

  const filterData = getFilterData(flowState.forms);

  const fieldConfiguration =
    flowState.configuration.data
      .map(configuration => {
        if (configuration.code.indexOf("#") === -1) {
          return null;
        }

        const [left, right] = configuration.code.split("#");
        if (left !== flowState.step.name) {
          return null;
        }

        const visible = configuration.alwaysShow || some(configuration.rules, (rule) => evaluateRule(rule, filterData));
        return [right, visible];
      })
      .filter(negate(isNull));

  return object(fieldConfiguration);
}

export function getAllFieldConfigurations(forms, configuration) {

  if (isUndefined(configuration) || !configuration.loaded) {
    return {};
  }

  const filterData = getFilterData(forms);

  const fieldConfiguration =
    configuration.data
      .map(configuration => {
        if (configuration.code.indexOf("#") === -1) {
          return null;
        }
        const visible = filterData.claimType === claimTypesConstants.GENERAL ? true : configuration.alwaysShow || some(configuration.rules, (rule) => evaluateRule(rule, filterData));
        return [configuration.code, visible];
      })
      .filter(negate(isNull));

  return object(fieldConfiguration);
}

export function getFilterData(forms) {
  const steps = flowConstants.steps;
  const homeSteps = flowConstants.homeSteps;
  const personSteps = flowConstants.personSteps;

  const claimTypeForm = forms[steps.CLAIM_TYPE.NAME] || {};
  const participantsNumberForm = forms[steps.PARTICIPANTS_NUMBER.NAME] || {};
  const carDamageTypeForm = forms[steps.CAR_DAMAGE_TYPE.NAME] || {};
  const parkedForm = forms[steps.PARKED.NAME];

  const homeDamageTypeForm = forms[homeSteps.HOME_DAMAGE_TYPE.NAME] || {};
  const homeSomeoneElseResponsibleForm = forms[homeSteps.HOME_SOMEONE_ELSE_RESPONSIBLE.NAME] || {};

  const medicalCareTypeForm = forms[personSteps.MEDICAL_CARE_TYPE.NAME] || {};

  return {
    claimType: claimTypeForm.choiceType,
    accidentType: carDamageTypeForm.carDamageType,
    damageType: homeDamageTypeForm.finalSelected,
    medicalCareType: medicalCareTypeForm.medicalCareType,
    medicalCareTypeDescription: medicalCareTypeForm.medicalCareTypeDescription,
    oneParticipant:
      isUndefined(participantsNumberForm) || isUndefined(participantsNumberForm.finalMultipleParticipants)
        ? null
        : participantsNumberForm.finalMultipleParticipants !== booleanTypesConstants.YES,
    moving: isUndefined(parkedForm) || isUndefined(parkedForm.finalParked) ? null : !parkedForm.finalParked,
    isBikeOwnVehicle: isBikeOwnVehicle(forms[steps.PARTICIPANTS_NUMBER.NAME]),
    someoneElseResponsible: isUndefined(homeSomeoneElseResponsibleForm.someoneElseResponsible)
      ? null
      : homeSomeoneElseResponsibleForm.someoneElseResponsible === booleanTypesConstants.YES
  };
}

export function transformLoadedState(data) {
  function convertToDate(date) {
    return date ? new Date(date) : date;
  }

  _.set(data, "state.forms.accidentDate.accidentDate", convertToDate(_.at(data, "state.forms.accidentDate.accidentDate")[0]));
  _.set(data, "state.forms.accidentDate.accidentDateFrom", convertToDate(_.at(data, "state.forms.accidentDate.accidentDateFrom")[0]));
  _.set(data, "state.forms.accidentDate.accidentDateTo", convertToDate(_.at(data, "state.forms.accidentDate.accidentDateTo")[0]));
  _.set(data, "state.save.version", _.at(data, "version")[0]);

  return data.state;
}

export function removeSkippedParticipants(state) {
  const {forms: {participantsNumber}} = state;

  const participants = filter(participantsNumber.participants, participant => !(participant.skip === true));
  const multipleParticipants = participants.length === 0 ? booleanTypesConstants.NO : participantsNumber.multipleParticipants;

  const newParticipantsNumber = {
    ...participantsNumber,
    participants,
    multipleParticipants,
  };

  const newForms = {
    ...state.forms,
    [flowConstants.steps.PARTICIPANTS_NUMBER.NAME]: newParticipantsNumber,
  };

  return {
    ...state,
    forms: {
      ...postCalculateFormState({name: flowConstants.steps.PARTICIPANTS_NUMBER.NAME}, newForms),
    },
  };
}

export function addSkipParticipant(state, index) {
  const {forms: {participantsNumber}} = state;
  const {participants} = participantsNumber;

  const participantIndex = findIndex(participants, participant => participant.index === index);

  const clone = [...participants];
  clone[participantIndex] = {
    ...participants[participantIndex],
    skip: true,
  };

  const newParticipantsNumber = {
    ...participantsNumber,
    participants: clone,
  };


  const newForms = {
    ...state.forms,
    [flowConstants.steps.PARTICIPANTS_NUMBER.NAME]: newParticipantsNumber,
  };

  return {
    ...state,
    forms: {
      ...postCalculateFormState({name: flowConstants.steps.PARTICIPANTS_NUMBER.NAME}, newForms),
    },
  };
}

export function getInitials(flow) {
  const defaultInitials = "H3";
  const {counterUser} = flow;

  if (isUndefined(counterUser) || !counterUser.authenticated) {
    return defaultInitials;
  }

  return counterUser.firstName[0] + counterUser.lastName[0];
}

export function isBikeOwnVehicle(participantsNumberForm) {
  return _.get(participantsNumberForm, "finalParticipants[0].type") === vehicleTypeConstants.BIKE;
}

export function removeSelectedGood(state, damagedGoodType) {
  const {forms: {homeDamagedGoods}} = state;

  const selected = filter(homeDamagedGoods.homeDamagedGoods.selected, selectedGood => selectedGood !== damagedGoodType);

  const newDamagedGoods = {
    ...homeDamagedGoods,
    homeDamagedGoods: {
      ...homeDamagedGoods.homeDamagedGoods,
      selected,
    }
  };

  return {
    ...state,
    forms: {
      ...state.forms,
      [flowConstants.homeSteps.HOME_DAMAGED_GOODS.NAME]: newDamagedGoods,
    },
  };
}

export function storeLoadedUserData(state, userData) {

  const newForms = {
    ...state.forms,
    [flowConstants.steps.USER_LOGIN.NAME]: {
      ...state.forms[flowConstants.steps.USER_LOGIN.NAME],
      userData,
    },
  };

  return {
    ...state,
    forms: {
      ...newForms,
    },
    loadingUserData: false,
  };
}

export function storeLoadedUserDataMobileApp(state, userData) {

  const newForms = {
    ...state.forms,
    [flowConstants.steps.USER_LOGIN.NAME]: {
      ...state.forms[flowConstants.steps.USER_LOGIN.NAME],
      userData,
    },
    [flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME]: {
      ...state.forms[flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME],
      'userIdentificationType' :  userIdentificationTypeConstants.GENERALI,
    },
  };

  return {
    ...state,
    forms: {
      ...newForms,
    },
    loadingUserData: false,
  };
}

export function storeLoadedCascoData(state, hasCasco) {
  const newForms = {
    ...state.forms,
    [flowConstants.steps.USER_IDENTIFICATION.NAME]: {
      ...state.forms[flowConstants.steps.USER_IDENTIFICATION.NAME],
      hasCasco,
    },
  };

  return {
    ...state,
    forms: {
      ...newForms,
    },
    loadingCascoData: false,
  };
}