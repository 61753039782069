import React from "react";

import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import {NextStepContext} from "containers/context/NextStepContainer";

export default function GeneraliLoginRight() {
  return (
    <NextStepContext.Consumer>
      {context => (
        <InnerGeneraliLoginRight context={context}/>
      )}
    </NextStepContext.Consumer>
  )
}

function InnerGeneraliLoginRight ({context}) {
  return (
    <Layout maxWidth={450} spacing={5} width="100%" justifyContent="center">
      <Layout.OneColumn>
        <TextField name="username"
                   placeholder="generaliLogin.content.username.placeholder"
                   label="generaliLogin.content.username.label"
                   isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="password"
                   placeholder="generaliLogin.content.password.placeholder"
                   label="generaliLogin.content.password.label"
                   password={true}
                   isRequired={true}/>
      </Layout.OneColumn>
    </Layout>
  );
}