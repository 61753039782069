import React from "react";

import BikeSvg from "components/svg/BikeSvg";
import CarSvg from "components/svg/CarSvg";
import MotorcycleSvg from "components/svg/MotorcycleSvg";
import SemiTruckSvg from "components/svg/SemiTruckSvg";
import vehicleTypesConstants from "constants/vehicleTypes";

const componentMapping = {
  [vehicleTypesConstants.CAR]: CarSvg,
  [vehicleTypesConstants.SEMI_TRUCK]: SemiTruckSvg,
  [vehicleTypesConstants.MOTORCYCLE]: MotorcycleSvg,
  [vehicleTypesConstants.BIKE]: BikeSvg,
};

export default function VehicleSvg({value, type}) {
  const Component = componentMapping[type];

  return (
    <Component value={value} />
  );
};
