import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import ConditionalField from "components/form/ConditionalField";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "constants/booleanTypes";

export default function OtherParticipantsInfoNoInfo() {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
      <ConditionalField name="injuryType">
        <Layout.OneColumn>
          <Text element="span" variant="variant2" label="otherParticipantsInfoNoInfo.injury.title"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <ToggleButton name="otherParticipantsInfoNoInfo.injuryType">
            <Layout.OneRow flex={1} spacing={10}>
              <ToggleButton.Choice label="button.yes"
                                   value={booleanTypesConstants.YES}
                                   variant="variant11"/>
              <ToggleButton.Choice label="button.no"
                                   value={booleanTypesConstants.NO}
                                   variant="variant11"/>
              <ToggleButton.Choice label="button.dontKnow"
                                   value={booleanTypesConstants.DONT_KNOW}
                                   variant="variant11"/>
            </Layout.OneRow>
          </ToggleButton>
        </Layout.OneColumn>
      </ConditionalField>
      <Layout.OneColumn spacing={20}></Layout.OneColumn>
      <ConditionalField name="passengers">
        <Layout.OneColumn>
          <Text element="span" variant="variant2" label="otherParticipantsInfoNoInfo.passengers.title"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <ToggleButton name="otherParticipantsInfoNoInfo.passengers">
            <Layout.OneRow flex={1} spacing={10}>
              <ToggleButton.Choice label="button.yes"
                                   value={booleanTypesConstants.YES}
                                   variant="variant11"/>
              <ToggleButton.Choice label="button.no"
                                   value={booleanTypesConstants.NO}
                                   variant="variant11"/>
              <ToggleButton.Choice label="button.dontKnow"
                                   value={booleanTypesConstants.DONT_KNOW}
                                   variant="variant11"/>
            </Layout.OneRow>
          </ToggleButton>
        </Layout.OneColumn>
      </ConditionalField>
    </Layout>
  );
}
