import React, {Component} from "react";

import {FieldArray} from "formik";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import FadeView from "components/view/FadeView";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import NaturalPerson from "containers/step/responsiblePersonType/NaturalPerson";

export default function NaturalPersons({responsiblePersonChoice}) {
  if (responsiblePersonChoice !== responsiblePersonTypes.NATURAL_PERSON) {
    return null;
  }

  return (
    <Layout width="100%" spacing={10} maxWidth={450}>
      <Layout.OneColumn spacing={20}/>
      <Layout.OneColumn>
        <NaturalPersonsData/>
      </Layout.OneColumn>
    </Layout>
  );
}

function NaturalPersonsData() {

  return (
    <FieldArray name="naturalPersons" render={arrayHelpers => (
      <NaturalPersonsArray arrayHelpers={arrayHelpers}/>
    )}/>
  );
}

class NaturalPersonsArray extends Component {
  add = () => {
    this.props.arrayHelpers.push({});
  };

  render() {
    const {arrayHelpers} = this.props;
    const naturalPersons = arrayHelpers.form.values.naturalPersons;

    const naturalPersonsValues =
      naturalPersons.map((naturalPerson, i) => (
        <FadeView.Child childKey={i} key={i}>
          <NaturalPerson index={i} onRemove={() => arrayHelpers.remove(i)} showRemove={naturalPersons.length > 1}
                         naturalPerson={naturalPerson}/>
        </FadeView.Child>
      ));

    return (
      <Layout flex={1}>
        <Layout.OneColumn>
          <FadeView>
            {naturalPersonsValues}
          </FadeView>
        </Layout.OneColumn>
        <Layout.OneColumn textAlign="right">
          <Button variant="variant10" label="responsiblePersonType.content.naturalPerson.button.add"
                  onClick={this.add}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
