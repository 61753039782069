export default {
  CAR_ACCIDENT: "carAccident", // prometna nesreča
  FIRE: "fire", // požar
  THEFT: "theft", // tatvina
  MALICIOUS_ACTS: "maliciousActs", // zlonamerna dejanja
  NATURAL_DISASTER: "naturalDisaster", // naravne nesreče, poplave
  HAIL: "hail", // Toča
  BREAKING_GLASS: "breakingGlass", // razbitje stekla
  BREAKING_LIGHTS_MIRRORS: "breakingLightsMirrors", // razbitje svetlobnih teles, ogledal
  ANIMAL_ENCOUNTERS: "animalEncouters", // nalet živali
  DAMAGE_PARKING_LOT: "damageParkingLot", // poškodbe na parkirišču
  KEY_LOSS: "keyLoss", // izguba ključev
  MECHANICAL_DAMAGE: "mechanicalDamage", // mehanske okvare ali poškodbe
  OTHER: "other", // drugo
  COLLISION: "collision",
};
