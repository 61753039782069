import React, {Component} from "react";

import axios from "axios";

import Layout from "components/common/Layout";
import AutoCompleteField from "components/form/AutoCompleteField";
import {ConfigContext} from "containers/context/ConfigContainer";

export default class Country extends Component {
  static contextType = ConfigContext;

  fetchCountry = async (value) => {
    const result = await axios.get(this.context.url.countries, {params: {term: value}});

    return result.data;
  };

  render() {
    return (
      <InnerCountry {...this.props} fetchCountry={this.fetchCountry}/>
    );
  }
}

class InnerCountry extends Component {
  render() {
    const {name, isRequired, setCountry, fetchCountry, variant, placeholder, label} = this.props;

    return (
      <Layout.OneRow flex={1} spacing={8}>
        <AutoCompleteField name={name}
                           placeholder={placeholder ? placeholder: "country.label"}
                           label={label ? label: "country.placeholder"}
                           isRequired={isRequired}
                           onSuggestionSelected={setCountry}
                           fetchSuggestions={fetchCountry}
                           getSuggestionValue={value => value ? value.name : ""}
                           variant={variant}/>
      </Layout.OneRow>
    );
  }
}