import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import booleanTypesConstants from "constants/booleanTypes";
import FadeView from "components/view/FadeView";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";

export default class OwnVehicleInfoReportedPoliceRight extends Component {
    render() {
    const {values, onNextStep} = this.props;

    return (
      <Layout flex={1}>
        <Layout.OneColumn>
          <NextStepButton component={ToggleButton}
                          buttonProps={{name: "reportedPolice"}}
                          onNextStep={onNextStep}
                          isValid={true}
                          condition={value => value === booleanTypesConstants.NO}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
              <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={20}/>
        <Layout.OneColumn>
          <FadeView step={values.reportedPolice}>
            <Switch condition={values.reportedPolice}>
              <Case value={booleanTypesConstants.YES}>
                <Layout width="100%">
                  <Layout.OneColumn>
                    <Text element="span"
                          variant="variant3"
                          label="ownVehicle.reportedPolice.content.policeStation.label"/>
                  </Layout.OneColumn>
                  <Layout.OneColumn>
                    <TextField name="reportedPoliceStation"
                               placeholder="ownVehicle.reportedPolice.content.policeStation.placeholder"
                               variant="variant5"
                               maxLength={fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_REPORTED_POLICE.NAME].reportedPoliceStation + 1}/>
                  </Layout.OneColumn>
                </Layout>
              </Case>
              <Default>{null}</Default>
            </Switch>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
