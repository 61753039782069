import React, {Component} from "react";

import {connect, Field} from "formik";
import _ from "lodash";
import {contains, isEmpty, isObject, isUndefined, values, some} from "underscore";

import Layout from "components/common/Layout";
import {InnerAutoCompleteField} from "components/form/AutoCompleteField";
import ErrorMessage from "components/form/ErrorMessage";
import FieldLabel from "components/form/FieldLabel";
import {InnerSelectField} from "components/form/SelectField";
import {InnerTextField} from "components/form/TextField";
import labelStatusTypeConstants from "constants/labelStatusTypes";

export const CompositeContext = React.createContext();

class CompositeField extends Component {
  handleFocus = (name) => {
    this.setState(state => ({
      label: {
        ...state.label,
        status: {
          ...state.label.status,
          [name]: labelStatusTypeConstants.FOCUSED,
        },
      },
    }));
  };

  handleBlur = (value, name) => {
    const status = isEmpty(value) ? labelStatusTypeConstants.EMPTY : labelStatusTypeConstants.NOT_EMPTY;

    this.setState(state => ({
      label: {
        ...state.label,
        status: {
          ...state.label.status,
          [name]: status,
        },
      },
    }));
  };

  initialNotEmpty = (name) => {
    this.setState(state => ({
      label: {
        ...state.label,
        status: {
          ...state.label.status,
          [name]: labelStatusTypeConstants.NOT_EMPTY,
        },
      },
    }));
  };

  state = {
    name: this.props.name,
    invalid: false,
    error: null,
    label: {
      handleFocus: this.handleFocus,
      handleBlur: this.handleBlur,
      initialNotEmpty: this.initialNotEmpty,
    }
  };

  componentDidMount() {
    this.updateInvalid();
  }

  componentDidUpdate() {
    this.updateInvalid();
  }

  updateInvalid = () => {
    const {name, formik} = this.props;
    const errors = _.at(formik.errors, name);
    const touched = _.at(formik.touched, name);
    const triedToSubmit = formik.submitCount > 0;

    const error = isObject(errors[0]) ? Object.values(errors[0])[0] : errors[0];
    const finalTouched = isObject(touched[0]) ? contains(touched[0], true) : touched[0];

    const invalid = (triedToSubmit || finalTouched) && !isUndefined(error);

    if (this.state.invalid !== invalid || this.state.error !== error) {
      this.setState({
        invalid,
        error,
      });
    }
  };

  render() {
    const {label, variant, children} = this.props;
    const {label: {status}, error, invalid} = this.state;

    const show = some(values(status), status => status !== labelStatusTypeConstants.EMPTY);

    return (
      <CompositeContext.Provider value={this.state}>
        <Layout spacing={2} flex={1}>
          <Layout.OneColumn>
            <FieldLabel label={label} show={show} variant={variant}/>
          </Layout.OneColumn>
          <Layout.OneColumn>
            {_.isFunction(children) ? children(this.state) : children}
          </Layout.OneColumn>
          <Layout.OneColumn>
            <ErrorMessage error={error} showError={invalid || false}/>
          </Layout.OneColumn>
        </Layout>
      </CompositeContext.Provider>
    );
  }
}

class CompositeTextField extends Component {
  static contextType = CompositeContext;

  render() {
    const {name, formik, ...props} = this.props;

    return (
      <Field
        name={`${this.context.name}.${name}`}
        render={(fieldProps) => (
          <InnerTextField {...fieldProps.field} {...props} form={fieldProps.form} name={`${this.context.name}.${name}`} context={this.context}/>
        )}
      />
    );
  }
}

class CompositeSelectField extends Component {
  static contextType = CompositeContext;

  render() {
    const {name, formik, ...props} = this.props;

    return (
      <Field
        name={`${this.context.name}.${name}`}
        render={(fieldProps) => (
          <InnerSelectField {...fieldProps.field} {...props} context={this.context}/>
        )}
      />
    );
  }
}

class CompositeAutoCompleteField extends Component {
  static contextType = CompositeContext;

  render() {
    const {name, formik, ...props} = this.props;

    return (
      <Field
        name={`${this.context.name}.${name}`}
        render={(fieldProps) => (
          <InnerAutoCompleteField {...fieldProps.field} form={fieldProps.form} fieldProps={fieldProps} name={`${this.context.name}.${name}`} {...props} context={this.context}/>
        )}
      />
    );
  }
}

CompositeField.TextField = CompositeTextField;
CompositeField.SelectField = CompositeSelectField;
CompositeField.AutoCompleteField = CompositeAutoCompleteField;

export default connect(CompositeField);
