import React, {Component} from "react";

import Text from "components/common/Text";
import {CompositeContext} from "components/form/CompositeField";
import {ConfigContext} from "containers/context/ConfigContainer";
import SplitViewContainer from "containers/view/SplitViewContainer";
import ThankYouRight from "containers/step/thankYou/ThankYouRight";

// step 38
export default class ThankYouStepContainer extends Component {
  static contextType = ConfigContext;

  left = ({claimType}) => {
    return (
      <SplitViewContainer.Left>
        <Text element="h2" label={`thankYou.sidebar.${claimType}.title`}/>
        <Text element="div" label={`thankYou.sidebar.${claimType}.subtitle`}/>
      </SplitViewContainer.Left>
    );
  }

  right = (props) => {
    return (
      <SplitViewContainer.Right showNavigation={false}>
        <CompositeContext.Consumer>
          {configContext => <ThankYouRight configContext={configContext} {...props}/>}
        </CompositeContext.Consumer>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}