import React from 'react';

import Map from 'components/map/Map';

export default function PickMarkerMap({center, markers, renderMarkerInfo, onCurrentLocationClicked, ...props}) {
  return (
    <Map center={center}
         markers={markers}
         renderMarkerInfo={renderMarkerInfo}
         getLocation={marker => ({lat: parseFloat(marker.x), lng: parseFloat(marker.y)})}
         onCurrentLocationClicked={onCurrentLocationClicked}
         {...props}/>
  );
}
