import * as Yup from "yup";
import * as Lodash from "lodash";
import libphonenumber from 'google-libphonenumber';
import IBAN from "iban";

import {isValidSloTaxNumber} from 'utility/utilityFunctions';

Yup.addMethod(Yup.object, 'atLeastOneRequired', function atLeastOneRequired(list, message) {
  return this.shape(list.reduce((acc, field) => ({
    ...acc,
    [field]: this.fields[field].when(Lodash.without(list, field), {
      is: (...values) => !values.some(item => item),
      then: this.fields[field].required(message),
    }),
  }), {}), list.reduce((acc, item, idx, all) => [...acc, ...all.slice(idx + 1).map(i => [item, i])], []));
});

Yup.addMethod(Yup.string, 'validateBankAccount', function validateBankAccount(message) {
  return this.test("iban", message, function (value) {
    const {path, createError} = this;
    const validIban = IBAN.isValid(value);
    const isSlovenianBankAccount = (value || "").toUpperCase().startsWith("SI56");

    return isSlovenianBankAccount && !validIban ? createError({path, message: message}) : true;
  });
});

Yup.addMethod(Yup.string, 'validateSloTaxNumber', function validateSloTaxNumber(message) {
  return this.test("taxNumber", message, function (value) {
    const {path, createError} = this;

    return isValidSloTaxNumber(value) ? true : createError({path, message: message});
  });
});

Yup.addMethod(Yup.string, 'validatePhoneNumber', function validatePhoneNumber(message) {
  return this.test("phoneNumber", message, function (value) {
    const {path, createError} = this;

    if (!value) {
      return true;
    }

    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    var phoneNumber = null;

    try {
      phoneNumber = phoneUtil.parse(value);
    }
    catch(err) {
      // do nothing
    }

    const isValid = phoneNumber != null && phoneUtil.isValidNumber(phoneNumber);
    return isValid ? true : createError({path, message: message});
  });
});

export default Yup;

export function getMax(validationSchema, length) {
  return validationSchema.max(length, `validation.length|${length}`);
}