import React, {Component} from "react";
import {connect} from "react-redux";

import axios from "axios";
import {isUndefined} from "underscore";

import {storeCounterUser} from "actions/flow";
import Loading from "components/common/Loading";
import {ConfigContext} from "containers/context/ConfigContainer";

export const CounterUserContext = React.createContext({});

class CounterUserContainer extends Component {
  static contextType = ConfigContext;

  state = {
    city: "",
    authenticated: false,
  };

  async componentDidMount() {
    const {storeCounterUser} = this.props;

    const response = await axios.get(this.context.url.counterUser);

    if (response.data.authenticated) {
      storeCounterUser(response.data);
      this.setState({
        city: response.data.city,
        authenticated: true,
      });
    } else {
      storeCounterUser({authenticated: false});
      this.setState({
        city: "",
        authenticated: false,
      });
    }
  }

  render() {
    const {storedCounterUser, children} = this.props;

    if (!storedCounterUser) {
      return <Loading/>;
    }

    return (
      <CounterUserContext.Provider value={this.state}>
        {children}
      </CounterUserContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  storedCounterUser: !isUndefined(state.flow.counterUser),
});

const mapDispatchToProps = (dispatch) => ({
  storeCounterUser: (user) => dispatch(storeCounterUser(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CounterUserContainer);