import React, {Component} from "react";

import * as Yup from "yup";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import ParticipantsNumberRight from "containers/step/participantsNumber/ParticipantsNumberRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import claimTypes from "constants/claimTypes";

const validationSchema = (claimType) => Yup.object().shape({
  multipleParticipants: claimType === claimTypes.CAR ? Yup.string().trim().required("validation.required") : Yup.string(),
});

// step 12
export default class ParticipantsNumberStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label="participantsNumber.sidebar.title"/></div>
        <div><Text element="div" label="participantsNumber.sidebar.subtitle"/></div>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <ParticipantsNumberRight {...props} />
      </SplitViewContainer.Right>
    );
  };

  validationSchema = ({step}) => {return validationSchema(step.claimType)};

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={this.validationSchema}/>
    );
  }
}
