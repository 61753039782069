import React, {Component} from "react";

import {isMobile} from "react-device-detect";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Modal from "components/common/Modal";

import mapMarker from "images/mapMarker.svg";

export default class MapForm extends Component {
  state = {
    showMap: false,
  };

  onOpenMap = () => {
    this.setState({
      showMap: true,
    })
  };

  onCloseMap = () => {
    this.setState({
      showMap: false,
    })
  };

  render() {
    const { children, buttonLabel, width = "100%" } = this.props;

    return (
      <>
        {isMobile
          ? 
         <Layout minWidth={215} maxWidth={400} width={width}>
            <Layout.OneColumn spacing={20}></Layout.OneColumn>
            <Layout.OneRow justifyContent="center" spacing={0}>
              <Layout.OneColumn width="100%">
                <Button label={buttonLabel}
                          onClick={this.onOpenMap}
                          icon={<img src={mapMarker} alt="map-marker" />}
                          variant="variant16" />
              </Layout.OneColumn>
            </Layout.OneRow>
          </Layout>
          : children}
        <Modal visible={this.state.showMap} onClose={this.onCloseMap} map="true">
          {children}
        </Modal>
      </>
    );
  }
}
