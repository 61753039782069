import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import booleanTypeConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import PersonReportedPoliceRight from "containers/step/personReportedPolice/PersonReportedPoliceRight"
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  policeStation: Yup.string().when("reportedToPolice", {
    is: booleanTypeConstants.YES,
    then: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.PERSON_REPORTED_POLICE.NAME].policeStation),
  }),
});

export default class PersonReportedPoliceStepContainer extends Component {
  left = ({fromSummary, step}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="personReportedPolice.sidebar.title"/>
        <Text element="div" label="personReportedPolice.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right
        showNextStepButton={props.values.reportedToPolice === booleanTypeConstants.YES} {...props}>
        <PersonReportedPoliceRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
