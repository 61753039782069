import React, {Component} from "react";

import Switch, {Case} from "react-switch-case";

import Box from "components/common/Box";
import Button from "components/common/Button";
import NextStepButton from "components/common/NextStepButton";
import Layout from "components/common/Layout";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import flowConstants from "constants/flow";
import helpInformationConstants from "constants/helpInformation";

import styles from "containers/step/helpInformation/HelpInformationRight.module.scss";

import phone from "images/phone.svg";

export default class HelpInformationRight extends Component {
  render() {
    const {onNextStep, onJumpStepAndResetAfter, values: {infoType}, isValid} = this.props;

    return (
      <>
        <Layout flex={1} spacing={10}>
          <Layout.RowChildren flex={1} spacing={10}>
            <ToggleButton name="infoType">
              <Layout spacing={10} flex={1}>
                <Layout.OneColumn>
                  <ToggleButton.Choice value={helpInformationConstants.INJURED_PERSON}
                                       label="helpInformation.injuredPerson.title"/>
                </Layout.OneColumn>
                <Layout.OneColumn>
                  <ToggleButton.Choice value={helpInformationConstants.NOT_DRIVEABLE_MALFUNCTION}
                                       label="helpInformation.notDriveableMalfunction.title"/>
                </Layout.OneColumn>
                <Layout.OneColumn>
                  <ToggleButton.Choice value={helpInformationConstants.NOT_DRIVEABLE_ACCIDENT}
                                       label="helpInformation.notDriveableAccident.title"/>
                </Layout.OneColumn>
                <Layout.OneColumn>
                  <ToggleButton.Choice value={helpInformationConstants.IN_OVERSEAS}
                                       label="helpInformation.inOverseas.title"/>
                </Layout.OneColumn>
              </Layout>
            </ToggleButton>
            <FadeView step={infoType}>
              <HelpInformationBox infoType={infoType}/>
            </FadeView>
          </Layout.RowChildren>
          <Layout.OneColumn spacing={20}></Layout.OneColumn>
          <Layout.OneColumn>
            <Layout.OneRow spacing={10} justifyContent="flex-end">
              <Button onClick={onJumpStepAndResetAfter({name: flowConstants.steps.CLAIM_TYPE.NAME})}
                      label="button.exit"
                      variant="variant27"/>
              <NextStepButton component={Button}
                              onNextStep={onNextStep}
                              isValid={isValid}
                              buttonProps={{
                                label: "button.reportDamage",
                              }}/>
            </Layout.OneRow>
          </Layout.OneColumn>
        </Layout>
      </>
    );
  }
}

function HelpInformationBox({infoType}) {
  return (
    <Box className={styles.box} icon={<img src={phone} alt="phone"/>}>
      <Switch condition={infoType}>
        <Case value={helpInformationConstants.INJURED_PERSON}>
          <Text element="h5" label="helpInformation.injuredPerson.emergency.title" className={styles.title}/>
          <Text element="a"
                label="helpInformation.injuredPerson.emergency.number"
                componentProps={{href: value => `tel:${value}`}}
                className={styles.number}/>
          <Text element="h5" label="helpInformation.injuredPerson.police.title" className={styles.title}/>
          <Text element="a"
                label="helpInformation.injuredPerson.police.number"
                componentProps={{href: value => `tel:${value}`}}
                className={styles.number}/>
        </Case>
        <Case value={helpInformationConstants.NOT_DRIVEABLE_MALFUNCTION}>
          <Text element="h5" label="helpInformation.notDriveableMalfunction.helpOnRoad.title" className={styles.title}/>
          <Text element="a"
                label="helpInformation.notDriveableMalfunction.helpOnRoad.number"
                componentProps={{href: value => `tel:${value}`}}
                className={styles.number}/>
        </Case>
        <Case value={helpInformationConstants.NOT_DRIVEABLE_ACCIDENT}>
          <Text element="h5" label="helpInformation.notDriveableAccident.police.title" className={styles.title}/>
          <Text element="a"
                label="helpInformation.notDriveableAccident.police.number"
                componentProps={{href: value => `tel:${value}`}}
                className={styles.number}/>
        </Case>
        <Case value={helpInformationConstants.IN_OVERSEAS}>
          <Text element="h5" label="helpInformation.inOverseas.generalInformation.title" className={styles.title}/>
          <Text element="a"
                label="helpInformation.inOverseas.generalInformation.number"
                componentProps={{href: value => `tel:${value}`}}
                className={styles.number}/>
          <Text element="h5" label="helpInformation.inOverseas.helpOnRoad.title" className={styles.title}/>
          <Text element="a"
                label="helpInformation.inOverseas.helpOnRoad.number"
                componentProps={{href: value => `tel:${value}`}}
                className={styles.number}/>
        </Case>
      </Switch>
    </Box>
  );
}
