import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import booleanTypesConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import PersonInsuranceRight from "containers/step/personInsurance/PersonInsuranceRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const arrayObjectValidationSchema = Yup.object().shape({
  name: (getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.PERSON_INSURANCE.NAME].name)),
});

const validationSchema = Yup.object().shape({
  insuranceOwners: Yup.array().when("personInsurance", {
    is: booleanTypesConstants.YES,
    then: Yup.array().of(arrayObjectValidationSchema),
  })
});

export default class PersonInsuranceStepContainer extends Component {

  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="personInsurance.sidebar.title"/>
        <Text element="div" label="personInsurance.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <PersonInsuranceRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
