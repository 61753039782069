import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import AccidentOccurrenceTypeRight from "containers/step/accidentOccurrenceType/AccidentOccurrenceTypeRight";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";

export default class AccidentOccurrenceTypeStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="accidentOccurrenceType.sidebar.title"/>
        <Text element="div" label="accidentOccurrenceType.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    const {onNextStep} = props;

    return (
      <SplitViewContainer.Right {...props} showNextStepButton={false}>
        <AccidentOccurrenceTypeRight {...props}/>
      </SplitViewContainer.Right>
    );
  }

  render() {
    return <SplitViewContainer left={this.left} right={this.right}/>
  }
}
