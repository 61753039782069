import React, {Component} from "react";

import {Field} from "formik";

import {Picker, Wrapper} from "components/form/CommonDatePicker";

export default class DatePicker extends Component {
  handleChange = (fieldProps) => (date) => {
    fieldProps.form.setFieldValue(fieldProps.field.name, date);
  };

  render() {
    const {name} = this.props;

    return (
      <Field
        name={name}
        render={(fieldProps) => (
          <Wrapper>
            <Picker
              maxDate={new Date()}
              inline
              fixedHeight
              selected={fieldProps.field.value}
              onChange={this.handleChange(fieldProps)}
            />
          </Wrapper>
        )}
      />
    );
  }
}
