import React, {Component} from "react";

import {connect} from "react-redux";

import Switch, {Case, Default} from "react-switch-case";

import _ from "lodash";
import Text from "components/common/Text";
import booleanTypesConstants from "constants/booleanTypes";
import claimTypes from "constants/claimTypes";
import fieldLengthConstants from "constants/fieldLength";
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes";
import isUndefinedOrNull from "utility/utilityFunctions";

export const VehicleInfoContext = React.createContext();

class VehicleInfoLogic extends Component {
  render() {
    const {info: {isVehicle, isOwnVehicle}, participantInfo, choiceType} = this.props;
    const isPersonFlow = claimTypes.PERSON;

    if (!isVehicle && isOwnVehicle && !isPersonFlow) {
      return <BikeOwnVehicle {...participantInfo}/>
    } else if (!isVehicle && !isOwnVehicle && !isPersonFlow) {
      return <Pedestrian {...participantInfo} />
    } else {
      return (
        <Vehicle {...participantInfo}/>
      );
    }
  }
}

function Vehicle(participantInfo) {
  return (
    <Switch condition={participantInfo.inputType}>
      <Case value={vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA}>
        {isUndefinedOrNull(participantInfo.vehicleData) || participantInfo.vehicleData.success
          ? <RegisteredInSlovenia {...participantInfo.vehicleData}/>
          : <RawRegistration {...participantInfo.inSlovenia}/>}
      </Case>
      <Case value={vehicleInfoInputTypesConstants.REGISTERED_ABROAD}>
        <RegisteredAbroad {...participantInfo.abroad}/>
      </Case>
      <Case value={vehicleInfoInputTypesConstants.COMPANY_VEHICLE}>
        <CompanyVehicle {...participantInfo.company}/>
      </Case>
      <Case value={vehicleInfoInputTypesConstants.NOT_REGISTERED}>
        <NotRegistered {...participantInfo.notRegistered}/>
      </Case>
      <Case value={vehicleInfoInputTypesConstants.UNKNOWN}>
        <Unknown/>
      </Case>
      <Default>
        {null}
      </Default>
    </Switch>
  );
}

class VehicleInfoLogicLine extends Component {
  static contextType = VehicleInfoContext;

  render() {
    const {component: Component, choice, ...rest} = this.context;

    if (choice === booleanTypesConstants.DONT_KNOW) {
      return (
        <Component {...this.props} {...rest}>
          <Text label="vehicleInfo.label.dontKnow"/>
        </Component>
      );
    }

    return (
      <Component {...this.props} {...rest} />
    );
  }
}

function RegisteredInSlovenia(props) {
  return (
    <>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.registrationNumber">
        {props.registrationNumber || <Text label="summary.content.common.boolean.dontKnow"/>}
      </VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.manufacturer">{props.manufacturer ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.model">{props.model ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.insuranceCompany">{props.insuranceCompany ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.policyNumber">{props.policyNumber ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
    </>
  );
}

function RawRegistration(props) {
  return (
    <>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.registrationNumber">
        {props.registrationNumber || <Text label="summary.content.common.boolean.dontKnow"/>}
      </VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.vehicleMark">{props.vehicleMark ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.vehicleType">{props.vehicleType ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
    </>
  );
}

function RegisteredAbroad(props) {
  return (
    <>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.country">{props.countryName ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.registrationNumber">
        {props.registrationNumber || <Text label="summary.content.common.boolean.dontKnow"/>}
      </VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.vehicleMark">{props.vehicleMark ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.vehicleType">{props.vehicleType ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.insuranceCompany">{props.insuranceCompany ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
    </>
  );
}

function CompanyVehicle(props) {
  return (
    <>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.vehicleType">
        <Text label="vehicleInfo.vehicle.vehicleType.company"/>
      </VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.vehicleChassisNumber">
        {props.vehicleChassisNumber || <Text label="summary.content.common.boolean.dontKnow"/>}
      </VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.vehicleMark">{props.vehicleMark ||
      <Text label="summary.content.common.boolean.dontKnow"/>}</VehicleInfoLogicLine>
    </>
  );
}

function NotRegistered(props) {
  const shortDescription = (props.vehicleDescription || "").substring(0, fieldLengthConstants.vehicleInfoLogic.notRegistered.description);

  return (
    <>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.registrationNumber">
        <Text label="vehicleInfo.vehicle.registrationNumber.notRegistered"/>
      </VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.vehicle.vehicleDescription">
        {shortDescription || <Text label="summary.content.common.boolean.dontKnow"/>}
      </VehicleInfoLogicLine>
    </>
  );
}

function Unknown() {
  return (
    <Text label="vehicleInfo.vehicle.unknown"/>
  );
}

function Pedestrian(props) {
  const name = _.at(props, "nameAndSurname.name")[0];
  const surname = _.at(props, "nameAndSurname.surname")[0];

  return (
    <>
      <VehicleInfoLogicLine label="vehicleInfo.pedestrian.firstName">
        {name ? name : <Text label="summary.content.common.boolean.dontKnow"/>}
      </VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.pedestrian.lastName">
        {surname ? surname : <Text label="summary.content.common.boolean.dontKnow"/>}
      </VehicleInfoLogicLine>
    </>
  );
}

function BikeOwnVehicle(props) {
  const hasBill = props.receiptChoice === booleanTypesConstants.YES;

  return (
    <>
      <VehicleInfoLogicLine label="vehicleInfo.bike.description">
        {hasBill ? <Text label="vehicleInfo.bike.hasBill"/> : props.bikeDescription.description}
      </VehicleInfoLogicLine>
      <VehicleInfoLogicLine label="vehicleInfo.bike.year">
        {hasBill ? <Text label="vehicleInfo.bike.hasBill"/> : props.bikeDescription.purchaseYear}
      </VehicleInfoLogicLine>
    </>
  );
}

const mapStateToProps = (state) => ({
  choiceType: _.at(state, "flow.forms.claimType.choiceType")[0] || "",
});

export default connect(mapStateToProps)(VehicleInfoLogic);