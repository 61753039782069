import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {RegistryContext} from "containers/context/RegistyContainer";
import {capitalize} from "utility/textTransform";

export default function BankAccountOtherLegalPerson(props) {
  return (
    <RegistryContext.Consumer>
      {context => (
        <InnerBankAccountOtherLegalPerson slovenia={context.registry.slovenia} {...props} name="otherPerson"/>
      )}
    </RegistryContext.Consumer>
  );
}

function InnerBankAccountOtherLegalPerson({name}) {
  return (
    <Layout spacing={5} width="100%" maxWidth={450} justifyContent="center">
      <Layout.OneColumn spacing={20}/>
      <Layout.OneColumn>
        <Text element="h4" label="bankAccounts.content.other.legal.person.title" variant="variant2"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`${name}.name`}
                   transform={capitalize}
                   placeholder="bankAccounts.content.other.legal.person.name.placeholder"
                   label="bankAccounts.content.other.legal.person.name.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].name + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={20}/>
    </Layout>
  );
}