import React, {Component} from "react";

import _ from "lodash";
import * as Yup from "yup";

import accidentDateTypeConstants from "constants/accidentDateTypes";
import booleanTypeConstants from "constants/booleanTypes";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import claimTypesConstants from "constants/claimTypes";
import Header from "containers/step/Header";
import AccidentTimeRight from "containers/step/accidentTime/AccidentTimeRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import isUndefinedOrNull, {timeStringLaterThan} from "utility/utilityFunctions";

const numberValidation = (checkFuture, isMinute = false, hourInPast = false) => {
  let validation = Yup.number()
    .required("validation.required")
    .test("is-number", "validation.number", value => {
      return !_.isNaN(value) && Math.floor(value) === value;
    })
    .min(0, "validation.number.min");

  if (checkFuture) {
    if (isMinute) {
      validation = validation.max(59, "validation.minutes");

      if (!hourInPast) {
        validation = validation.lessThan(new Date().getMinutes() + 1, "validation.minutes.future");
      }
    } else {
      validation = validation.max(23, "validation.hours").lessThan(new Date().getHours() + 1, "validation.hours.future");
    }
  } else {
    if (isMinute) {
      validation = validation.max(59, "validation.minutes");
    } else {
      validation = validation.max(23, "validation.hours");
    }
  }

  return validation
};

const validationSchema = (checkFuture, isRange) => Yup.object().shape({
  accidentDateType: Yup.string(),
  accidentTimeFrom:
    Yup.mixed()
      .when("accidentTimeRange", {
        is: true,
        then: Yup.string()
          .required("validation.required")
          .transform(value => (value.length <= 2 && value.length > 0 ? value + ":00" : value))
          .matches(/^((\d)|(\d\d)):((\d)|(\d\d))$/, "validation.timeFormat")
          .matches(/^((\d)|(([01]\d)|([2][0-3]))):((\d)|(\d\d))$/, "validation.hours")
          .matches(/^((\d)|(\d\d)):((\d)|([0-5]\d))$/, "validation.minutes"),
      }),
  accidentTimeTo:
    Yup.mixed()
      .when("accidentTimeRange", {
        is: true,
        then: Yup.string()
          .required("validation.required")
          .transform(value => (value.length <= 2 && value.length > 0 ? value + ":00" : value))
          .matches(/^((\d)|(\d\d)):((\d)|(\d\d))$/, "validation.timeFormat")
          .matches(/^((\d)|(([01]\d)|([2][0-3]))):((\d)|(\d\d))$/, "validation.hours")
          .matches(/^((\d)|(\d\d)):((\d)|([0-5]\d))$/, "validation.minutes"),
      }),
  accidentTimeHour:
    Yup.mixed()
      .when("accidentTimeRange", {
        is: false,
        then: numberValidation(checkFuture),
      }),
  accidentTimeMinute:
    Yup.mixed()
      .when(["accidentTimeRange", "accidentTimeHour"], {
        is: (accidentTimeRange, accidentTimeHour) => !accidentTimeRange && !isUndefinedOrNull(accidentTimeHour) && accidentTimeHour === new Date().getHours(),
        then: numberValidation(checkFuture, true),
      })
      .when(["accidentTimeRange", "accidentTimeHour"], {
        is: (accidentTimeRange, accidentTimeHour) => !accidentTimeRange && !isUndefinedOrNull(accidentTimeHour) && accidentTimeHour !== new Date().getHours(),
        then: numberValidation(checkFuture, true, true),
      })
      .when(["accidentTimeRange", "accidentTimeHour"], {
        is: (accidentTimeRange, accidentTimeHour) => !accidentTimeRange && isUndefinedOrNull(accidentTimeHour),
        then: numberValidation(checkFuture, true),
      }),
})
  .test("accidentTimeTo", "validation.lesser", function (value) {
    const {createError} = this;
    const {accidentTimeTo, accidentTimeFrom, accidentTimeRange} = value;

    if (isRange || !accidentTimeRange) {
      return true;
    }

    return timeStringLaterThan(accidentTimeFrom, accidentTimeTo)
      ? true
      : createError(
        {
          path: `accidentTimeTo`,
          message: "validation.lesser",
        });
  });

// step 7
export default class AccidentTimeStepContainer extends Component {

  left = ({claimType, step, fromSummary}) => {
    const label = claimType !== claimTypesConstants.PERSON ? `${claimType}` : `${claimType}.${step.medicalCareTypeDescription}`;
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label={`accidentTime.sidebar.${label}.title`}/></div>
        <div><Text element="div" label={`accidentTime.sidebar.${label}.subtitle`}/></div>
      </SplitViewContainer.Left>
    );
  };

  onNextStep = (props) => () => {
    const {setFieldValue, onNextStep} = props;

    setFieldValue("choice", booleanTypeConstants.YES)

    setTimeout(onNextStep, 0);
  }

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props} onNextStep={this.onNextStep(props)}>
         <AccidentTimeRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  validationSchema = ({step}) => {
    return validationSchema(step.accidentDateType === accidentDateTypeConstants.TODAY, step.accidentDateType === accidentDateTypeConstants.RANGE);
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={this.validationSchema}/>
    );
  }
}
