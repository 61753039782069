import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import homeCalculationTypeConstants from "constants/homeCalculationTypes";

export default class HomeCalculationTypeRight extends Component {
  render() {
    const {onNextStep} = this.props;

    return (
      <NextStepButton component={ToggleButton}
                      onNextStep={onNextStep}
                      isValid={true}
                      buttonProps={{
                        name: "calculationType",
                      }}>
      <Layout spacing={10}>
        <Layout.OneColumn flex={1}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={homeCalculationTypeConstants.RECEIPTS} label={`homeCalculationType.content.button.${homeCalculationTypeConstants.RECEIPTS}`}/>
              <ToggleButton.Choice value={homeCalculationTypeConstants.EVALUATION} label={`homeCalculationType.content.button.${homeCalculationTypeConstants.EVALUATION}`}/>
              <ToggleButton.Choice value={homeCalculationTypeConstants.DONT_KNOW} label={`homeCalculationType.content.button.${homeCalculationTypeConstants.DONT_KNOW}`}/>
            </Layout.OneRow>
        </Layout.OneColumn>
      </Layout>
      </NextStepButton>
    )
  }
}
