import React, {Component} from "react";

import Yup, {getMax} from "selectors/yup";

import Text from "components/common/Text";
import SummaryHeader from "components/common/SummaryHeader";
import AbleToDriveRight from "containers/step/ableToDrive/AbleToDriveRight";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

const validationSchema = Yup.object().shape({
  ableToDrive: Yup.boolean().required("validation.required"),
  vehicleLocationDescription: Yup.string().when("ableToDrive", {
    is: false,
    then: getMax(Yup.string().trim().required("validation.required"), fieldLengthConstants[flowConstants.steps.ABLE_TO_DRIVE.NAME].description),
  }),
});

// step 10
export default class AbleToDriveStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label="ableToDrive.sidebar.title"/></div>
        <div><Text element="div" label="ableToDrive.sidebar.subtitle"/></div>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
          <AbleToDriveRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
