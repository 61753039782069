import React, {Component} from "react";

import * as Yup from "yup";

import VehicleInfoContainer from "components/step/VehicleInfoContainer";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import booleanTypesConstants from "constants/booleanTypes";
import claimTypes from "constants/claimTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import Header from "containers/step/Header";
import OwnVehicleInfoWasDriverRight from "containers/step/ownVehicleInfo/OwnVehicleInfoWasDriverRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {getMax} from "selectors/yup";

const otherDriverValidationSchema = (claimType) => Yup.object().shape({
  name: getMax(Yup.string().trim().required("validation.name"), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].name),
  surname: getMax(Yup.string().trim().required("validation.surname"), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].surname),
  birthDate: Yup.string().nullable().trim().required("validation.birthDate"),
  sex: Yup.string().trim().required("validation.required"),
  address: Yup.object().shape({
    countryName: Yup.string().trim().required("validation.address.country"),
    address: Yup.object().shape({
      streetName: getMax(Yup.string().typeError("validation.address.street").trim().required("validation.address.street"), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].streetName),
      streetNumber: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].streetNumber),
    }),
    city: Yup.object().shape({
      zip: getMax(Yup.string().trim().required("validation.address.zip"), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].zip),
      city: getMax(Yup.string().trim().required("validation.address.city"), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].city),
    }),
  }),
  phoneNumber: getMax(Yup.string().trim().validatePhoneNumber("validation.telephoneNumber.notMandatory.format"), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].telephoneNumber),
  licenceNumber: claimType === claimTypes.PERSON ?
    getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].licenceNumber)
    : getMax(Yup.string().trim().required("validation.licenceNumber"), fieldLengthConstants[flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME].licenceNumber)
});

const validationSchema = (claimType) => Yup.object().shape({
  wasDriver: claimType === claimTypes.PERSON ? Yup.string() : Yup.string().trim().required("validation.required"),
  otherDriver: Yup.object()
    .when("wasDriver", {
      is: booleanTypesConstants.NO,
      then: otherDriverValidationSchema(claimType),
    }),
});

// step 19
export default class OwnVehicleInfoWasDriverStepContainer extends Component {
  left = ({fromSummary, step}) => {
    const title =
      step.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH ? `${step.medicalCareTypeDescription}.title` : "title";

    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label={`ownVehicle.wasDriver.content.${title}`}/></div>
        <div><Text element="div" label="ownVehicle.wasDriver.content.subtitle"/></div>
        <VehicleInfoContainer {...step.participant}/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <OwnVehicleInfoWasDriverRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  validationSchema = ({step}) => {return validationSchema(step.claimType)};

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={this.validationSchema}/>
    );
  }
}
