import "react-app-polyfill/ie11";
import "core-js";
import "url-search-params-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import App from "containers/App";
import createInitStore from "store";

import "sass/index.scss";
import "lightgallery.js/dist/css/lightgallery.css";

const store = createInitStore();

ReactDOM.render(<App store={store}/>, document.getElementById("root"));

if (module.hot) {
  module.hot.accept("containers/App", () => {
    const NextApp = require("containers/App").default;
    ReactDOM.render(
      <NextApp store={store}/>,
      document.getElementById("root"),
    );
  });
}
