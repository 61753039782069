import React, {Component} from "react";

import * as Yup from "yup";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import booleanTypeConstants from "constants/booleanTypes";
import carDamageTypeConstants from "constants/carDamageTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import regexConstants from "constants/regex";
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes";
import vehicleTypesConstants from "constants/vehicleTypes";
import {UploadContext} from "containers/context/UploadContainer";
import Header from "containers/step/Header";
import ParticipantsInfoRight from "containers/step/participantsInfo/ParticipantsInfoRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {getMax} from "selectors/yup";
import isUndefinedOrNull from "utility/utilityFunctions";

const vehicleValidationSchemaOnlyMax = Yup.object().shape({
  participantInfo: Yup.object().shape({
    inSlovenia: Yup.object()
      .when(["inputType", "vehicleData"], {
        is: (inputType, vehicleData) => inputType === vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA && (isUndefinedOrNull(vehicleData) || vehicleData.success),
        then: Yup.object().shape({
          registrationNumber: getMax(Yup.string().typeError("participantsInfo.validation.vehicleData"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber),
        }),
      })
      .when(["inputType", "vehicleData"], {
        is: (inputType, vehicleData) => inputType === vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA && vehicleData && !vehicleData.success,
        then: Yup.object().shape({
          registrationNumber: getMax(Yup.string().typeError("participantsInfo.validation.vehicleData"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber),
          vehicleMark: Yup.string(),
          vehicleType: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleType),
        }),
      }),
    vehicleData: Yup.mixed().when("inputType", {
      is: vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA,
      then: Yup.mixed(),
    }),
    company: Yup.object().when("inputType", {
      is: vehicleInfoInputTypesConstants.COMPANY_VEHICLE,
      then: Yup.object().shape({
        vehicleChassisNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleChassisNumber),
        vehicleMark: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleMark),
      }),
    }),
    abroad: Yup.object().when("inputType", {
      is: vehicleInfoInputTypesConstants.REGISTERED_ABROAD,
      then: Yup.object().shape({
        registrationNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber),
        vehicleMark: Yup.string(),
        vehicleType: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleType),
        insuranceCompany: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].insuranceCompany),
      }),
    }),
    notRegistered: Yup.object().when("inputType", {
      is: vehicleInfoInputTypesConstants.NOT_REGISTERED,
      then: Yup.object().shape({
        vehicleDescription: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleDescription),
      }),
    }),
  }),
});

const pedestrianValidationSchemaOnlyMax = Yup.object().shape({
  participantInfo: Yup.object().shape({
    nameAndSurname: Yup.object().shape({
      name: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].name),
      surname: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].surname),
    }),
    birthDate: Yup.string(),
    address: Yup.object().shape({
      countryName: Yup.string().nullable(),
      address: Yup.object().shape({
        streetName: getMax(Yup.string().typeError("validation.address.street"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].streetName),
        streetNumber: getMax(Yup.string().typeError("validation.address.streetNo"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].streetNumber),
      }),
      city: Yup.object().shape({
        zip: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].zip),
        city: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].city),
      }),
    }),
    heavyInjuryType: Yup.string(),
  }),
});

const pedestrianValidationSchema = Yup.object().shape({
  participantInfo: Yup.object().shape({
    nameAndSurname: Yup.object().shape({
      name: getMax(Yup.string().trim().required("validation.name"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].name),
      surname: getMax(Yup.string().trim().required("validation.surname"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].surname),
    }),
    birthDate: Yup.string().trim().required("validation.birthDate"),
    sex: Yup.string().trim().required("validation.required"),
    address: Yup.object().shape({
      countryName: Yup.string().trim().required("validation.address.country"),
      address: Yup.object().shape({
        streetName: getMax(Yup.string().typeError("validation.address.street").trim().required("validation.address.street"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].streetName),
        streetNumber: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].streetNumber),
      }),
      city: Yup.object().shape({
        zip: getMax(Yup.string().trim().required("validation.address.zip"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].zip),
        city: getMax(Yup.string().trim().required("validation.address.city"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].city),
      }),
    }),
    heavyInjuryType: Yup.string().required("participantsInfo.validation.heavyInjuryType"),
  }),
});

const vehicleValidationSchema = (vehicleType) => Yup.object().shape({
  participantInfo: Yup.object().shape({
    inSlovenia: Yup.object()
      .when(["inputType", "vehicleData"], {
        is: (inputType, vehicleData) => inputType === vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA && (isUndefinedOrNull(vehicleData) || vehicleData.success),
        then: Yup.object().shape({
          registrationNumber: getMax(Yup.string().typeError("participantsInfo.validation.vehicleData").ensure().trim().required("participantsInfo.validation.vehicleData"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber),
        }),
      })
      .when(["inputType", "vehicleData"], {
        is: (inputType, vehicleData) => inputType === vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA && vehicleData && !vehicleData.success,
        then: Yup.object().shape({
          registrationNumber: getMax(Yup.string().typeError("participantsInfo.validation.vehicleData").ensure().trim().required("participantsInfo.validation.vehicleData").matches(regexConstants.REGISTRATION, "participantsInfo.validation.vehicleData"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber),
          vehicleMark: Yup.string().trim().required("participantsInfo.validation.inSlovenia.vehicleMark"),
          vehicleType: getMax(Yup.string().trim().required("participantsInfo.validation.inSlovenia.vehicleType"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleType),
        }),
      }),
    vehicleData: Yup.mixed().when("inputType", {
      is: vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA,
      then: Yup.mixed().required("participantsInfo.validation.vehicleData"),
    }),
    company: Yup.object().when("inputType", {
      is: vehicleInfoInputTypesConstants.COMPANY_VEHICLE,
      then: Yup.object().shape({
        vehicleChassisNumber: getMax(Yup.string().trim().required("participantsInfo.validation.company.vehicleChassisNumber"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleChassisNumber),
        vehicleMark: getMax(Yup.string().trim().required("participantsInfo.validation.company.vehicleMark"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleMark),
      }),
    }),
    abroad: Yup.object().when("inputType", {
      is: vehicleInfoInputTypesConstants.REGISTERED_ABROAD,
      then: Yup.object().shape({
        countryName: Yup.string().trim().required("validation.required"),
        registrationNumber: getMax(Yup.string().nullable().trim().required("participantsInfo.validation.abroad.registrationNumber"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber),
        vehicleMark: Yup.mixed().when("countryName", { 
          is: () => vehicleType === vehicleTypesConstants.MOTORCYCLE || vehicleType === vehicleTypesConstants.SEMI_TRUCK,
          then: Yup.string().notRequired(),
          otherwise: Yup.string().trim().required("participantsInfo.validation.abroad.vehicleMark"),
        }),
        vehicleType: Yup.mixed().when("countryName", { 
          is: () => vehicleType === vehicleTypesConstants.MOTORCYCLE || vehicleType === vehicleTypesConstants.SEMI_TRUCK,
          then: Yup.string().notRequired(),
          otherwise: getMax(Yup.string().trim().required("participantsInfo.validation.abroad.vehicleType"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleType),
        }),
        insuranceCompany: getMax(Yup.string().trim().required("participantsInfo.validation.abroad.insuranceCompany"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].insuranceCompany),
      }),
    }),
    notRegistered: Yup.object().when("inputType", {
      is: vehicleInfoInputTypesConstants.NOT_REGISTERED,
      then: Yup.object().shape({
        vehicleDescription: getMax(Yup.string().trim().required("participantsInfo.validation.notRegistered.vehicleDescription"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleDescription),
      }),
    }),
    inputType: Yup.string().required()
  }),
});

const bikeOwnVehicleValidationSchema = Yup.object().shape({
  participantInfo: Yup.object().shape({
    bikeDescription: Yup.object().when("receiptChoice", {
      is: booleanTypeConstants.NO,
      then: Yup.object().shape({
        description: getMax(Yup.string().trim().required("participantsInfo.validation.bikeDescription.description"), fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].bikeDescription.description),
        purchaseYear: Yup.string().required("participantsInfo.validation.bikeDescription.purchaseYear"),
      }),
    }),
    photos: Yup.array().when("receiptChoice", {
      is: booleanTypeConstants.YES,
      then: Yup.array().min(1, "participantsInfo.validation.photos"),
    }),
  })
});

// step 16-18
export default class ParticipantsInfoStepContainer extends Component {
  left = ({fromSummary, step}) => {
    const {participant, carDamageType} = step;
    const ownVehicle = participant.isOwnVehicle ? "ownVehicle" : "otherVehicle";
    const damageType = carDamageType === carDamageTypeConstants.KEY_LOSS ? "keyLoss" : "other"

    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label={`participantsInfo.sidebar.${ownVehicle}.${damageType}.title`}/>
        <Text element="div" label={`participantsInfo.sidebar.${damageType}.subtitle`}/>
      </SplitViewContainer.Left>
    );
  };

  right(props) {
    return (
      <SplitViewContainer.Right {...props}>
        <UploadContext.Consumer>
          {uploadContext => <ParticipantsInfoRight uploadContext={uploadContext} {...props} />}
        </UploadContext.Consumer>
      </SplitViewContainer.Right>
    );
  }

  validationSchema = ({step}) => {
    if (!step.participant.isOwnVehicle) {
      if (step.participant.isVehicle) {
        return vehicleValidationSchemaOnlyMax;
      } else {
        return pedestrianValidationSchemaOnlyMax;
      }
    } else if (step.participant.isVehicle) {
      return vehicleValidationSchema(step.participant.type);
    } else if (step.participant.type === vehicleTypesConstants.BIKE) {
      return bikeOwnVehicleValidationSchema;
    } else {
      return pedestrianValidationSchema;
    }
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={this.validationSchema}/>
    );
  }
}
