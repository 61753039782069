import React, {Component} from "react";

import cx from "classnames";
import styles from "components/icon/Asdom.module.scss";

export default class Asdom extends Component {
  render() {
    const {icon, variant} = this.props;

    const className = cx({
      [styles.root]: true,
      [styles.variant1]: variant === "variant1",
      [styles.variant2]: variant === "variant2",
    });

    return (
      <div className={className}>
        {icon}
      </div>
    );
  }
}
