import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import TextField from "components/form/TextField";
import IcoMoon from "components/icon/IcoMoon";
import FadeView from "components/view/FadeView";
import claimTypesConstants from "constants/claimTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import fontConstants from "constants/font";
import {RegistryContext} from "containers/context/RegistyContainer";

export default class ClaimTypeRight extends Component {
  render() {
    const {values, onNextStep} = this.props;

    return (
      <NextStepButton component={ToggleButton}
                      onNextStep={onNextStep}
                      condition={value => value !== claimTypesConstants.GENERAL}
                      isValid={true}
                      buttonProps={{
                        name: "choiceType",
                      }}>
        <Layout spacing={10} flex={1} textAlign="center">
          <Layout.OneColumn>
            <RegistryContext.Consumer>
              {context => (
                <ClaimTypeChoice context={context}/>
              )}
            </RegistryContext.Consumer>
          </Layout.OneColumn>
          <Layout.OneColumn>
            <FadeView step={values.choiceType}>
              <Switch condition={values.choiceType}>
                <Case value={claimTypesConstants.GENERAL}>
                  <OtherClaimTypeInfo {...this.props}/>
                </Case>
                <Default>
                  {null}
                </Default>
              </Switch>
            </FadeView>
          </Layout.OneColumn>
        </Layout>
      </NextStepButton>
    );
  }
}

function ClaimTypeChoice(context) {
  const {context: {registry: {enabledFlows}}} = context;

  return (
    <Layout.OneRow spacing={10} flex={1} style={{flexBasis: "200px"}}>
      {enabledFlows.map((claimType, index) => claimTypeToggleChoice(claimType, index))}
    </Layout.OneRow>
  );
}

function OtherClaimTypeInfo({onNextStep, isValid}) {
  return (
    <Layout width="100%" maxWidth={430} justifyContent="center">
      <Layout.OneColumn spacing={10}/>
      <Layout.OneColumn>
        <TextField name="otherChoice"
                   label="claimType.content.otherClaimType.label"
                   placeholder="claimType.content.otherClaimType.placeholder"
                   maxLength={fieldLengthConstants[flowConstants.steps.CLAIM_TYPE.NAME].otherChoice + 1}
                   multiLine={true}/>
      </Layout.OneColumn>
    </Layout>
  );
}

function claimTypeToggleChoice(claimType, index) {
  let icon = null;

  switch (claimType) {
    case claimTypesConstants.CAR:
      icon = fontConstants.ICOMOON.CAR;
      break;
    case claimTypesConstants.HOME:
      icon = fontConstants.ICOMOON.HOME;
      break;
    case claimTypesConstants.PERSON:
      icon = fontConstants.ICOMOON.INJURED_PERSON;
      break;
    case claimTypesConstants.GENERAL:
      icon = fontConstants.ICOMOON.THREE_DOTS;
      break;
  }

  return (
    <ToggleButton.Choice value={claimType}
                         key={index}
                         icon={<IcoMoon icon={icon}/>}
                         label={`claimType.content.button.${claimType}.label`}
                         descriptionLabel={`claimType.content.button.${claimType}.description`}
                         showCheckMark={false}/>
  );
}
