import {keys, findLastIndex} from "underscore";

export function getRefForScroll(refString, availableRefs) {
  const availableRefKeys = keys(availableRefs);
  const index = findLastIndex(availableRefKeys, ref => removeWords(ref) <= removeWords(refString));

  return availableRefs[availableRefKeys[index]];
}

function removeWords(ref) {
  return ref.split("-").filter((value, index) => index % 2 === 0).join("-");
}

export function formatCountryAndTaxNumber(country, taxNumber) {
  const countryString = country ? country : "Ni znano";
  const taxNumberString = taxNumber ? taxNumber : "Ni znano";

  return countryString + ", " + taxNumberString;
}