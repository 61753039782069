import React, {Component} from "react";

import {FieldArray} from "formik";

import UploadButton from "components/common/UploadButton";

export default function UploadField({name, ...props}) {
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <InnerUploadField arrayHelpers={arrayHelpers} buttonProps={props}/>
      )}
    />
  );
}

class InnerUploadField extends Component {
  onChange = (files) => {
    const {arrayHelpers} = this.props;

    files.forEach(arrayHelpers.push);
  };

  render() {
    const {buttonProps} = this.props;

    return (
      <UploadButton onChange={this.onChange} {...buttonProps}/>
    );
  }
}
