import React from "react";

import detectIt from "detect-it";
import {DragDropContextProvider} from "react-dnd";
import MouseBackend from "react-dnd-mouse-backend";
import TouchBackend from "react-dnd-touch-backend";

export default function DragDropContainer({children}) {
  return (
    <DragDropContextProvider backend={detectIt.hasTouch ? TouchBackend : MouseBackend}>
      {children}
    </DragDropContextProvider>
  );
}
