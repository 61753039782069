import React, {Component} from "react";

import axios from "axios";
import {Formik} from "formik";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Text from "components/common/Text";
import FormErrorMessage from "components/form/FormErrorMessage";
import TextField from "components/form/TextField";
import {ConfigContext} from "containers/context/ConfigContainer"

import styles from "containers/Login.module.scss";

export default class Login extends Component {
  static contextType = ConfigContext;

  state = {
    invalidCredentials: false,
  };

  onSubmit = async (values) => {
    const response = await axios.post(this.context.url.login, values);

    if (response.data.authenticated) {
      this.setState({
        invalidCredentials: "",
      });
      document.body.onbeforeunload = "return false";
      window.location.href = "/";
    } else {
      this.setState({
        invalidCredentials: "login.failed",
      });
    }
  };

  onEnterPressed = formProps => async () => {
    formProps.submitForm();
  };

  render() {
    return (
      <>
        <Formik initialValues={{}} onSubmit={this.onSubmit}>
          {(formProps) => (
            <div className={styles.root}>
              <div className={styles.inner}>
                <Layout width="100%" maxWidth={450} spacing={5}>
                  <Layout.OneColumn>
                    <Text element="p" label="login.sidebar.title" variant="variant36"/>
                  </Layout.OneColumn>
                  <Layout.OneColumn spacing={10}/>
                  <Layout.OneColumn>
                    <Text element="div" label="login.sidebar.subtitle"/>
                  </Layout.OneColumn>
                  <Layout.OneColumn>
                    <TextField name="username"
                              placeholder="login.username.placeholder"
                              label="login.username.label"/>
                  </Layout.OneColumn>
                  <Layout.OneColumn>
                    <TextField name="password"
                              type="password"
                              placeholder="login.password.placeholder"
                              label="login.password.label"
                              password={true}
                              onEnterPressed={this.onEnterPressed(formProps)}/>
                  </Layout.OneColumn>
                  <Layout.OneColumn>
                    <FormErrorMessage error={this.state.invalidCredentials}
                                      showError={this.state.invalidCredentials.length > 0}/>
                  </Layout.OneColumn>
                  <Layout.OneColumn spacing={20}/>
                  <Layout.OneColumn textAlign="right">
                    <Button label="login.button" onClick={formProps.submitForm}/>
                  </Layout.OneColumn>
                </Layout>
              </div>
            </div>
          )}
        </Formik>
      </>
    );
  }
}