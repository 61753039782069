import React from "react";

import {isUndefined} from "underscore";

import Text from "components/common/Text";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryBankAccount({bankAccount, jumpTo}) {
  const hasBankAccount = !isUndefined(bankAccount);
  const bankAccountNumber = hasBankAccount ? bankAccount.bankAccount : undefined;
  const bank = hasBankAccount && !isUndefined(bankAccount.bank) ? bankAccount.bank : {};

  return (
    <>
      <Summary.ItemValue label="summary.content.bankAccounts.bank.account.number"
                         formName={jumpTo}
                         refName={summaryRefConstants.COMMON.BANK_ACCOUNT.NAME}>
        {bankAccountNumber || <Text label="summary.content.common.boolean.dontKnow"/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.bank.account.swift"
                         formName={jumpTo}
                         refName={summaryRefConstants.COMMON.BANK_ACCOUNT.SWIFT}
                         condition={form => !!bankAccountNumber && !bankAccountNumber.toUpperCase().startsWith("SI56")}>
        {bank.bic || <Text label="summary.content.common.boolean.dontKnow"/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.bankAccounts.bank.account.bank.name"
                         formName={jumpTo}
                         refName={summaryRefConstants.COMMON.BANK_ACCOUNT.BANK_NAME}
                         condition={form => !!bankAccountNumber && !bankAccountNumber.toUpperCase().startsWith("SI56")}>
        {bank.name || <Text label="summary.content.common.boolean.dontKnow"/>}
      </Summary.ItemValue>
    </>
  );
}