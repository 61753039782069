import React, {Component} from "react";

import {LightgalleryItem} from "@croz/react-lightgallery";

import close from "images/close.svg";

import styles from "components/icon/PhotosIcon.module.scss";
import {ConfigContext} from "containers/context/ConfigContainer";

export default class PhotosIcon extends Component {
  static contextType = ConfigContext;

  static defaultProps = {
    onPhotoClick: (id) => null,
  };

  render() {
    const {id, type, thumbnailId, onButtonClick, onPhotoClick, group} = this.props;

    return (
      <div className={styles.root}>
        <LightgalleryItem group={group} src={this.context.url.download(id)} isVideo={type === 'video'}>
          <img className={styles.image}
               src={this.context.url.download(thumbnailId)}
               alt="thumbnail"
               onClick={onPhotoClick(id)}/>
        </LightgalleryItem>

        {onButtonClick
          ? <img className={styles.delete}
                 src={close}
                 alt="delete"
                 onClick={onButtonClick(id)}/>
          : null}
      </div>
    );
  }
}