import React, {Component} from "react";

import {FieldArray} from "formik";
import {MobileView} from "react-device-detect";
import Switch, {Case} from "react-switch-case";
import {range} from "underscore";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import FormErrorMessage from "components/form/FormErrorMessage";
import SelectField from "components/form/SelectField";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import UploadField from "components/form/UploadField";
import IcoMoon from "components/icon/IcoMoon";
import Documents from "components/step/Documents";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import fontConstants from "constants/font";
import UploadContainer, {UploadContext} from "containers/context/UploadContainer";
import {LightgalleryProvider} from "@croz/react-lightgallery";

export default class BikeOwnVehicleInfo extends Component {
  render() {
    const {name, participantInfo} = this.props;
    const {receiptChoice} = participantInfo;

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn>
          <Text element="span" label="bikeOwnVehicleInfo.content.subtitle"/>
        </Layout.OneColumn>
        <Layout.OneColumn flex={1}>
          <ToggleButton name={`${name}.receiptChoice`}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
              <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
            </Layout.OneRow>
          </ToggleButton>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={receiptChoice}>
            <Switch condition={receiptChoice}>
              <Case value={booleanTypesConstants.YES}>
                <LightgalleryProvider lightgallerySettings={{download: false, thumbnail: false}}>
                  <ReceiptUpload name={name} values={this.props}/>
                </LightgalleryProvider>
              </Case>
              <Case value={booleanTypesConstants.NO}>
                <BikeDescription name={name}/>
              </Case>
            </Switch>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}

class ReceiptUpload extends Component {
  render() {
    const {name, values} = this.props;
    const {saveId} = values;
    const {photos = []} = values.participantInfo || {};
    const acceptedTypes = ["doc", "docx", "pdf", "jpg", "jpeg", "bmp", "tiff", "png", "xlsx", "xls"].map(x => `.${x}`).join(", ");

    return (
      <UploadContainer width="100%">
        <UploadContext.Consumer>
          {context => (
            <>
              <Layout.OneColumn spacing={10}/>
              <Layout.OneColumn>
                <Text element="span" label="bikeOwnVehicleInfo.content.photos.subtitle"/>
              </Layout.OneColumn>
              <Layout.OneColumn spacing={15}/>
              <Layout.OneColumn width="100%">
                <UploadField name={`${name}.photos`}
                             label="bikeOwnVehicleInfo.content.photos.button.upload"
                             saveId={saveId}
                             icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                             accept={acceptedTypes}/>
              </Layout.OneColumn>
              <Layout.OneColumn spacing={5} width="100%"/>
              <Layout.OneColumn spacing={15}/>
              <MobileView>
                <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
                  <UploadField name={`${name}.photos`}
                               label="bikeOwnVehicleInfo.content.photos.button.takePhoto"
                               saveId={saveId}
                               icon={<IcoMoon icon={fontConstants.ICOMOON.CAMERA}/>}
                               accept={acceptedTypes}/>
                </Layout.OneColumn>
              </MobileView>
              <Layout.OneColumn spacing={5} width="100%"/>
              <Layout.OneColumn width="100%">
                <FieldArray name={`${name}.photos`} render={arrayHelpers => (
                  <Documents arrayHelpers={arrayHelpers}
                          documents={photos}/>
                )}/>
              </Layout.OneColumn>
              <Layout.OneColumn>
                <FormErrorMessage error={context.error} showError={context.error.length > 0}/>
              </Layout.OneColumn>
            </>
          )}
        </UploadContext.Consumer>
      </UploadContainer>
    );
  }
}

function BikeDescription({name}) {
  return (
    <Layout width="100%">
      <Layout.OneColumn>
        <TextField name={`${name}.bikeDescription.description`}
                   placeholder="bikeOwnVehicleInfo.content.bikeDescription.description.placeholder"
                   label="bikeOwnVehicleInfo.content.bikeDescription.description.label"
                   multiLine={true}
                   variant="variant5"
                   maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].bikeDescription.description + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <SelectField name={`${name}.bikeDescription.purchaseYear`}
                     label="bikeOwnVehicleInfo.content.bikeDescription.purchaseYear.label"
                     placeholder="bikeOwnVehicleInfo.content.bikeDescription.purchaseYear.placeholder">
          <SelectField.Option value="" label="bikeOwnVehicleInfo.content.bikeDescription.purchaseYear.label" disabled
                              hidden/>
          {range(new Date().getFullYear(), 1900).map(year => (
            <SelectField.Option key={year} value={year}>{year}</SelectField.Option>
          ))}
        </SelectField>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`${name}.bikeDescription.currentValue`}
                   placeholder="bikeOwnVehicleInfo.content.bikeDescription.currentValue.label"
                   label="bikeOwnVehicleInfo.content.bikeDescription.currentValue.label"
                   currency={true}
                   maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].bikeDescription.currentValue}/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={10}/>
      <Layout.OneColumn>
        <Text label="bikeOwnVehicleInfo.content.bikeDescription.disclaimer"/>
      </Layout.OneColumn>
    </Layout>
  );
}