import React from "react";

import VehicleSvgIcon from "components/icon/VehicleSvgIcon";

const svgProps = {
  width: 59,
  height: 92,
};

const iconStyle = {
  width: 35,
  height: 35,
  x: 12,
  y: 12,
};

const textProps = {
  fill: "#ffffff",
  x: 29,
  y: 75,
  textAnchor: "middle",
  style: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: 30,
    fontWeight: 900,
    userSelect: "none",
  },
};

const space = 3;
const strokeWidth = 2;

export default function VehicleIconSvg({className, value: {type, name, color}, selected, ...props}) {
  return (
    <svg {...props} {...svgProps} className={className}>
      <rect x={space} y={space} width={svgProps.width - space * 2} height={svgProps.height - space * 2} fill={color} rx={10} ry={10} />
      <rect x={0} y={0} width={svgProps.width} height={svgProps.height}
            stroke={"#555"} strokeOpacity={selected ? 1 : 0} strokeDasharray="2" strokeWidth={strokeWidth} fillOpacity={0}/>
      <VehicleSvgIcon type={type} style={iconStyle}/>
      <text {...textProps}>{name}</text>
    </svg>
  )
}
