import React, {Component} from "react";

import axios from "axios";

import Text from "components/common/Text";
import {ConfigContext} from "containers/context/ConfigContainer"
import SplitViewContainer from "containers/view/SplitViewContainer";
import SplitView from "components/view/SplitView";

export default class LoadSummaryContainer extends Component {

  left = () => {
    const {claimId} = this.props;

    return (
      <SplitViewContainer.Left>
        <Text element="h2" label={`summary.sidebar.admin.title`}/>
        <Text element="div" label={`summary.sidebar.admin.subtitle`} parameters={[claimId]}/>
      </SplitViewContainer.Left>
    );
  };

  right = () => {
    const {claimId} = this.props;

    return (
      <SplitViewContainer.Right>
        <ConfigContext.Consumer>
          {(configContext) => (
            <SummaryAdmin configContext={configContext} claimId={claimId}/>
          )}
        </ConfigContext.Consumer>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitView>
        {this.left()}
        {this.right()}
      </SplitView>
    );

  }
}

class SummaryAdmin extends Component {
  static contextType = ConfigContext;

  state = {
    html: "",
  };

  async componentDidMount() {
    const {configContext, claimId} = this.props;

    const response = await axios.get(configContext.url.summaryHTML(claimId));

    this.setState({
      html: response.data.summaryHTML,
    });
  };

  render() {
    return (
      <div dangerouslySetInnerHTML={{__html: this.state.html}}>
      </div>
    );
  }
}