import React from "react";

import Layout from "components/common/Layout";
import Link from "components/common/Link";
import Text from "components/common/Text";
import DateField from "components/form/DateField";
import SexField from "components/form/SexField";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import TaxNumber from "components/step/TaxNumber";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {RegistryContext} from "containers/context/RegistyContainer";
import {capitalize} from "utility/textTransform";
import isUndefinedOrNull, {isSloAddress} from "utility/utilityFunctions";

export default function NaturalPerson(props) {
  return (
    <RegistryContext.Consumer>
      {context => (
        <InnerNaturalPerson {...props} slovenia={context.registry.slovenia}/>
      )}
    </RegistryContext.Consumer>
  );
}

function InnerNaturalPerson({onRemove, showRemove, slovenia, index, naturalPerson}) {
  const address = isUndefinedOrNull(naturalPerson) ? null : naturalPerson.address;
  const addressLabels = {
    country: "responsiblePersonType.content.naturalPerson.address.country.label"
  }

  return (
    <Layout width="100%" spacing={10} maxWidth={450}>
      <Layout.OneColumn>
        <Text element="h5" variant="variant2" label="responsiblePersonType.content.naturalPerson.data.title"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`naturalPersons[${index}].name`}
                   transform={capitalize}
                   placeholder="responsiblePersonType.content.naturalPerson.name.placeholder"
                   label="responsiblePersonType.content.naturalPerson.name.label"
                   maxLength={fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].name + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`naturalPersons[${index}].surname`}
                   transform={capitalize}
                   placeholder="responsiblePersonType.content.naturalPerson.surname.placeholder"
                   label="responsiblePersonType.content.naturalPerson.surname.label"
                   maxLength={fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].surname + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <DateField name={`naturalPersons[${index}].birthDate`}
                   label="responsiblePersonType.content.naturalPerson.birthDate.placeholder"
                   placeholder="responsiblePersonType.content.naturalPerson.birthDate.placeholder"
                   legalAgeField={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <SexField name={`naturalPersons[${index}].sex`}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Address name={`naturalPersons[${index}].address`} addressLabels={addressLabels}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`naturalPersons[${index}].policyNumber`}
                   placeholder="responsiblePersonType.content.naturalPerson.policyNumber.placeholder"
                   label="responsiblePersonType.content.naturalPerson.policyNumber.label"
                   maxLength={fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].policyNumber + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TaxNumber name={`naturalPersons[${index}].taxNumber`}
                   placeholder="responsiblePersonType.content.naturalPerson.taxNumber.placeholder"
                   label="responsiblePersonType.content.naturalPerson.taxNumber.label"
                   isSlovenia={isSloAddress(address, slovenia)}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        {showRemove ? <Link label="responsiblePersonType.content.insuranceOwner.button.remove" onClick={onRemove}/> : null}
      </Layout.OneColumn>
      <Layout.OneColumn spacing={20}/>
    </Layout>
  );
}
