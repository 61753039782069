import React, {Component} from "react";

import * as Yup from "yup";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import EventDescriptionRight from "containers/step/eventDescription/EventDescriptionRight";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  description: getMax(Yup.string().trim().required("eventDescription.validation.description"), fieldLengthConstants[flowConstants.steps.EVENT_DESCRIPTION.NAME].description),
});

export default class EventDescriptionStepContainer extends Component {
  left = ({claimType, fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label={`eventDescription.sidebar.${claimType}.title`} /></div>
        <div><Text element="div" label={`eventDescription.sidebar.${claimType}.subtitle`} /></div>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <EventDescriptionRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
