import React from "react";

import cx from "classnames";

import Text from "components/common/Text";
import Layout from "components/common/Layout";

import styles from "components/form/CheckBox/CheckBox.module.scss"

export default function CheckBox({onClick, labelProps, variant, ...props}) {
  const rootClass = cx({
    [styles.root]: true,
    [styles.checked]: props.checked,
    [styles.variant1]: variant === "variant1",
  });

  const checkmarkClass = cx({
    [styles.checkmark]: true,
    [styles.variant1]: variant === "variant1",
  });

  const layoutStyle = {width: "100%"}

  return (
    <Layout layoutStyle={layoutStyle}>
      <Layout.Row onClick={onClick}>
        <Layout.Column spacing={(variant === "variant1")? 2: 10}>
          <div>
            <input {...props}
                   className={rootClass}
                   type="checkbox"/>
            <span className={checkmarkClass}/>
          </div>
        </Layout.Column>
      {(variant === "variant1")
        ? <Layout.Column flex={1}>
          <Text element="span" variant="variant26" {...labelProps} />
        </Layout.Column>
          : <Layout.Column spacing={10} flex={1}>
          <Text element="span" {...labelProps} />
        </Layout.Column>}
      </Layout.Row>
    </Layout>
  );
}
