import React from "react";

import {isUndefined} from "underscore";

import sketchConstants from "constants/sketch";
import {findImage, svgTransform} from "selectors/sketch";

export default function SketchCrossRoad({name}) {
  if (isUndefined(name)) {
    return null;
  }

  const transformCrossRoad = svgTransform(
    sketchConstants.DIMENSIONS.CANVAS.WIDTH, sketchConstants.DIMENSIONS.CANVAS.HEIGHT,
    sketchConstants.DIMENSIONS.CROSS_ROAD.WIDTH, sketchConstants.DIMENSIONS.CROSS_ROAD.HEIGHT
  );

  return (
    <g transform={transformCrossRoad}
       dangerouslySetInnerHTML={{__html: findImage(name).IMAGE_SKETCH}}/>
  )
}
