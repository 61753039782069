export default {
  ICOMOON: {
    BREAKING_GLASS: "\uE903",
    INJURED_PERSON: "\uE904",
    CALENDAR: "\uE90D",
    CAR_ACCIDENT: "\uE905",
    PENCIL: "\uE90B",
    CAMERA: "\uE90F",
    CAR: "\uE910",
    HOME: "\uE92E",
    KEY_LOSS: "\uE911",
    THEFT: "\uE912",
    HAND_SHAKE: "\uE917",
    MALICIOUS_ACTS: "\uE919",
    ANIMAL: "\uE93A",
    MECHANICAL_DAMAGE: "\uE924",
    FIRE: "\uE927",
    NATURAL_DISASTER: "\uE92A",
    DAMAGE_PARKING_LOT: "\uE937",
    VESSEL: "\uE93E",
    HEAD: "\uE93F",
    THREE_DOTS: "\uE945",
    ANIMAL_SIGN: "\uE94D",
    UPLOAD: "\uE94A",
    REPAIR_SHOP: "\uE924",
  },
  VEHICLE_ICON: {
    SEMI_TRUCK: "\uF100",
    CAR: "\uF101",
    MOTORCYCLE: "\uF102",
    SCOOTER: "\uF103",
    BIKE: "\uF104",
    PEDESTRIAN: "\uF105",
    TRACTOR: "\uF106",
    COMPANY: "\uF104",
  },
  PERSON_ICON: {
    ACCIDENT: "\uF100",
    SLIPPERY_FLOOR: "\uF101",
    MICROBEAM_RADIATION_THERAPY: "\uF102",
    MEDICAL_INSTITUTION: "\uF103",
    CEMETARY: "\uF104",
    BEING_SICK: "\uF105",
    NURSE_CALL: "\uF106",
    MEDICINE_TABLET: "\uF107",
    PILLS: "\uF108",
    HEART_WITH_PULSE: "\uF109",
    WALKING_SILHOUETTE: "\uF10A",
  },
  ASDOM_ICON: {
    FLOOD_WATER: "\uF104",
    FIRE: "\uF100",
    DESTRUCTION: "\uF114",
    BURGLARY: "\uF105",
    NATURAL_DISASTER: "\uF103",
    BREAKDOWN: "\uF117",
    DAMAGE_SOMEONE_ELSE_RESPONSIBLE: "\uF10C",
    VEHICLE_IMPACT: "\uF106",
    EARTHQUAKE: "\uF102",
    OTHER: "\uF112",
    HOUSE: "\uF109",
    SWIMMING_POOL: "\uF118",
    SOFA: "\uF10E",
    FARM: "\uF119",
    WINDOW: "\uF10D",
    WASHING_MACHINE: "\uF11C",
    PERSON: "\uF110",
    EXPAND_ARROW: "\uF113",
  },
  OSEBA_ICON: {
    BOX: "\uF101",
  },
  REPAIR_SERVICE_ICON: {
    GARAGE: "\uF101",
    CAR_SERVICE: "\uF105",
  },
  TOCA_POPLAVA_STEKLO_ICON: {
    HAIL: "\uE900",
    LIGHTS_MIRROR_BREAK: "\uE902",
    FLOOD_WATER: "\uE901",
  },
};
