import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import MapsIntroRight from "containers/step/mapsIntro/MapsIntroRight";
import SplitViewContainer from "containers/view/SplitViewContainer";

// step tbd
export default class MapsIntroStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="mapsIntro.sidebar.title"/>
        <Text element="div" label="mapsIntro.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right showNextStepButton={false} {...props}>
        <MapsIntroRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
