import React, {Component} from "react";

import {Field} from "formik";

import Button from "components/common/Button";

export default class ChoiceButton extends Component {
  onClick = (fieldProps) => () => {
    const {value, onClick} = this.props;
    const {field: {name}, form: {setFieldValue}} = fieldProps;

    setFieldValue(name, value, false);

    if (onClick) {
      onClick(name, value);
    }
  };

  render() {
    const {name} = this.props;

    return (
      <Field
        name={name}
        render={(fieldProps) => (
          <Button {...this.props}
                  onClick={this.onClick(fieldProps)}/>
        )}
      />
    );
  }
}
