import {isString} from "underscore";

import booleanTypesConstants from "constants/booleanTypes";

export function filterMarkers (value, markers) {
  return markers.filter(marker => markerContainsValue(value, marker));
}

function markerContainsValue(value, marker){
  const cityHasValue = marker.city.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  const addressHasValue = marker.address.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  const nameHasValue = marker.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  const hasValue = formatMarkerSuggestionValue(marker).toLowerCase().indexOf(value.toLowerCase()) !== -1;

  return cityHasValue || addressHasValue || nameHasValue || hasValue;
}

export function formatMarkerSuggestionValue(marker) {
  if(isString(marker)){
    return marker;
  }

  return `${marker.name}, ${marker.address}, ${marker.city}`;
}

export function mapSearchStateHelper(focusLocation, location, state) {
  if (location && location !== booleanTypesConstants.DONT_KNOW && location !== state.oldLocation) {
    return {
      ...state,
      oldLocation: location,
      center: {lat: location.x, lng: location.y},
      selectedMarkerId: location.id,
    };
  }

  if (focusLocation && focusLocation !== state.focusLocation) {
    return {
      ...state,
      focusLocation,
      center: focusLocation,
      selectedMarkerId: focusLocation.id,
    };
  }

  return null;
}