import React, {Component} from "react";

import {Field} from "formik";
import {isUndefined} from "underscore";

import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import Banks from "components/step/Banks";
import FadeView from "components/view/FadeView";
import bankAccountMask from "constants/bankAccountMask";
import {isSlovenianBankAccountString} from "containers/step/userIdentification/utilityFunctions";
import {RegistryContext} from "containers/context/RegistyContainer";


export default function BankAccountForm({name, ...props}) {

  return (
    <Field name={name}
           render={(fieldProps) => (
             <InnerBankAccountForm {...fieldProps} name={name} {...props}/>
           )}/>
  );
}

class InnerBankAccountForm extends Component {
  static  contextType = RegistryContext;

  componentDidMount() {
    const {name, form: {setFieldValue}, field: {value}, showIbanPrefix = true} = this.props;
    setTimeout(() => {
      if (isUndefined(value) && showIbanPrefix) {
        setFieldValue(name, this.context.registry.slovenia.ibanPrefix);
      }
    }, 0);
  };

  render() {
    const {field: {value}, name, variant, formName, isRequired = false} = this.props;
    const slovenianBankAccount = isSlovenianBankAccountString(value);

    return (
      <>
        <Layout.OneColumn width="100%" spacing={5}>
          <TextField name={name}
                     label="bankAccounts.content.bankAccount.placeholder"
                     placeholder="bankAccounts.content.bankAccount.placeholder"
                     variant={variant}
                     isRequired={isRequired}
                     mask={bankAccountMask}
                     guide={false}/>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%">
          <FadeView step={slovenianBankAccount}>
            {
              slovenianBankAccount
                ? null
                : <Layout width="100%">
                  <Layout.OneColumn spacing={5}>
                    <Banks name={formName ? `${formName}.bank` : "bank"} variant={variant}/>
                  </Layout.OneColumn>
                </Layout>
            }
          </FadeView>
        </Layout.OneColumn>
      </>
    );
  }
}

