import React from "react";

import Text from "components/common/Text";
import booleanTypesConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";
import SummaryCommonVehicleInfo from "containers/step/summary/car/SummaryCommonVehicleInfo";
import isUndefinedOrNull from "utility/utilityFunctions";

export default function SummaryVehicleInfo({participant, ...props}) {
  const otherParticipantForm = {
    name: flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME,
    index: participant.info.index,
  };

  return (
    <>
      <SummaryCommonVehicleInfo participant={participant} {...props}/>

      <Summary.ItemValue label="summary.content.participantsInfo.otherParticipantInfo.participant"
                         formName={otherParticipantForm}
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_PARTICIPANT}>
        {form =>
          form.otherParticipantsInfoType === booleanTypesConstants.YES
            ? form.participant
            : <Text label="summary.content.common.boolean.dontKnow"/>
        }
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.otherParticipantInfo.injuryType"
                         formName={otherParticipantForm} fieldName="injuryType"
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_INJURY_TYPE}>
        {form =>
          !isUndefinedOrNull(form.injuryType)
            ? <Text label={`summary.content.common.boolean.${form.injuryType}`}/>
            : <Text label="summary.content.common.boolean.dontKnow"/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.participantsInfo.otherParticipantInfo.passengers"
                         formName={otherParticipantForm} fieldName="passengers"
                         refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.OTHER_PARTICIPANTS_INFO_PASSENGERS}>
        {form =>
          !isUndefinedOrNull(form.passengers)
            ? <Text label={`summary.content.common.boolean.${form.passengers}`}/>
            : <Text label="summary.content.common.boolean.dontKnow"/>}
      </Summary.ItemValue>
    </>
  );
}