import React, {Component} from "react";

import {FieldArray} from "formik";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import FadeView from "components/view/FadeView";
import {RegistryContext} from "containers/context/RegistyContainer";
import BeneficiaryPerson from "containers/step/beneficiaryPersons/BeneficiaryPerson";

export default function BeneficiaryPersonsRight(props) {
  return (
    <FieldArray name="beneficiaryPersons" render={arrayHelpers => (
      <RegistryContext.Consumer>
        {context => (
          <BeneficiaryPersons arrayHelpers={arrayHelpers} slovenia={context.registry.slovenia}
                              setFieldValue={props.setFieldValue}/>
        )}
      </RegistryContext.Consumer>
    )}/>
  );
}

class BeneficiaryPersons extends Component {

  add = () => {
    this.props.arrayHelpers.push({});
  };

  render() {
    const {arrayHelpers, setFieldValue} = this.props;
    const beneficiaryPersons = arrayHelpers.form.values.beneficiaryPersons;

    const values =
      beneficiaryPersons.map((beneficiaryPerson, i) => (
        <FadeView.Child childKey={i} key={i}>
          <BeneficiaryPerson index={i} onRemove={() => arrayHelpers.remove(i)}
                             showRemove={beneficiaryPersons.length > 1}
                             slovenia={this.props.slovenia}
                             setFieldValue={setFieldValue}/>
        </FadeView.Child>
      ));

    return (
      <Layout flex={1} spacing={5} width="100%" maxWidth={450} justifyContent="center">
        <Layout.OneColumn>
          <FadeView>
            {values}
          </FadeView>
        </Layout.OneColumn>
        <Layout.OneColumn textAlign="right">
          <Button variant="variant10" label="beneficiaryPersons.button.add" onClick={this.add}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
