import React, {Component} from "react";

import Switch, {Case} from "react-switch-case";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import wasDriverTypesConstants from "constants/wasDriverTypes";
import OwnVehicleInfoWasDriverOtherDriver from "containers/step/ownVehicleInfo/OwnVehicleInfoWasDriverOtherDriver";

export default class OwnVehicleInfoWasDriverRight extends Component {
  render() {
    const {onNextStep} = this.props;

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneRow>
          <NextStepButton component={ToggleButton}
                          onNextStep={onNextStep}
                          isValid={true}
                          condition={value => value !== wasDriverTypesConstants.NO}
                          buttonProps={{name: "wasDriver"}}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={wasDriverTypesConstants.YES} label="button.yes"/>
              <ToggleButton.Choice value={wasDriverTypesConstants.NO} label="button.no"/>
              <ToggleButton.Choice value={wasDriverTypesConstants.NO_DRIVER}
                                   label="ownVehicle.wasDriver.noDriver.button.label"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneRow>
        <Layout.OneRow>
          <Layout.OneColumn maxWidth={450} width="100%" justifyContent="center">
            <OtherDriverInfo {...this.props}/>
          </Layout.OneColumn>
        </Layout.OneRow>
      </Layout>
    );
  }
}

function OtherDriverInfo({values: {wasDriver}, ...props}) {
  return (
    <FadeView step={wasDriver}>
      <Switch condition={wasDriver}>
        <Case value={wasDriverTypesConstants.NO}>
          <OwnVehicleInfoWasDriverOtherDriver claimType={props.step.claimType}/>
        </Case>
        <Case value={wasDriverTypesConstants.YES}>
          {null}
        </Case>
        <Case value={wasDriverTypesConstants.NO_DRIVER}>
          {null}
        </Case>
      </Switch>
    </FadeView>
  );
}


