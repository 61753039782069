import React, {Component} from 'react';

import Button from "components/common/Button";
import Text from "components/common/Text";
import isUndefinedOrNull from "utility/utilityFunctions";

import styles from "components/map/InfoWindowHelper.module.scss";

export default class InfoWindowHelper extends Component {
  state = {
    selected: this.props.selected,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.marker !== this.props.marker) {
      this.setState({
        selected: this.props.selected,
      });
    }
  }

  onClick = () => {
    const {onClick} = this.props;

    this.setState(state => ({
      selected: !state.selected,
    }));

    if (onClick) {
      onClick();
    }
  };

  render() {
    const {marker, name} = this.props;

    return (
      <div className={styles.infoWindow}>
        <div>
          <h2 className={styles.infoName}>{marker.name}</h2>
          <Text className={styles.infoAddress}>{formatAddress(marker)}</Text>
        </div>
        <div>
          <Text element="h5" className={styles.infoTitle} label="infoMarker.contact.title"/>
          <Text className={styles.infoEmail}>{marker.email}</Text>
          <Text className={styles.infoTelephoneNumber}>{marker.phoneNumber}</Text>
          <Text element="a" label={marker.url} componentProps={{href: marker.url, target: '_blank'}} className={styles.infoUrl} />
        </div>
        <div>
          <Text element="h5" className={styles.infoTitle} label="infoMarker.contact.workingHours"/>
          {formatWorkingHours(marker.workingHours).map((workingHours, index) => (
            <Text key={index} isHtml={true} className={styles.infoWorkingHours}>{workingHours}</Text>
          ))}
        </div>
        {marker.note
          ? <div>
            <Text element="h5" className={styles.infoTitle} label="infoMarker.contact.vehicleMarks"/>
            <Text className={styles.infoVehicleMarks}>{marker.note}</Text>
          </div>
          : null}
        <div className={styles.buttonContainer}>
          <Button className={styles.infoButton}
                  label={this.state.selected
                    ? `${name}.content.button.deselect`
                    : `${name}.content.button.select`}
                  onClick={this.onClick}/>
        </div>
      </div>
    );
  }
}

function formatAddress(marker) {
  return `${marker.address}, ${marker.zip}, ${marker.city}`;
}

function formatWorkingHours(workingHours) {
  return !isUndefinedOrNull(workingHours) ? workingHours.split(/<br>/) : [];
}