import React from "react";

import cx from "classnames";
import {prefix} from 'inline-style-prefixer'

import SketchDragSource from "components/sketch/item/SketchDragSource";
import SketchItem from "components/sketch/item/SketchItem";
import {getItemDimension} from "selectors/sketch";

import styles from "components/sketch/item/SketchCanvasItem.module.scss";

export default function SketchCanvasItem({draggable = true, ...props}) {
  if (!draggable) {
    return renderGroup({...props, isDragging: false});
  }

  return (
    <SketchDragSource value={props.item}>
      {({isDragging}) =>
        renderGroup({...props, isDragging})
      }
    </SketchDragSource>
  )
}

function renderGroup({isDragging, item, onClick, selected}) {
  const [scale, flip] = itemScale(item);
  const [innerTranslate, outerTranslate] = itemTranslate(item);
  const rotate = itemRotate(item);

  const outerMostTransform = `${outerTranslate}`;
  const innerTransform = `${rotate} ${flip}`;
  const innerMostTransform = `${innerTranslate} ${scale}`;

  const className = cx({
    [styles.isDragging]: isDragging,
  });

  return (
    <g transform={outerMostTransform} className={className}>
      <g transform={innerTransform} style={prefix({transition: "all 0.1s ease-in-out"})}>
        <g transform={innerMostTransform}>
          <SketchItem item={item} onClick={() => onClick(item)}
                      image={item => item.IMAGE_SKETCH}
                      selected={selected}
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"/>
        </g>
      </g>
    </g>
  );
}

function itemTranslate(item) {
  const {TARGET_WIDTH, TARGET_HEIGHT} = getItemDimension(item);

  return [`translate(-${TARGET_WIDTH / 2}, -${TARGET_HEIGHT / 2})`, `translate(${item.x} ${item.y})`];
}

function itemScale(item) {
  const {SOURCE_WIDTH, SOURCE_HEIGHT, TARGET_WIDTH, TARGET_HEIGHT} = getItemDimension(item);

  const scaleX = TARGET_WIDTH / SOURCE_WIDTH;
  const scaleY = TARGET_HEIGHT / SOURCE_HEIGHT;

  const flippedX = item.flipped ? -1 : 1;

  return [`scale(${scaleX} ${scaleY})`, `scale(${flippedX} 1)`];
}

function itemRotate({rotation = 0}) {
  return `rotate(${rotation})`
}
