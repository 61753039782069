import React, {Component} from "react";

import Layout from "components/common/Layout";
import {NextStepContext} from "containers/context/NextStepContainer";
import VehicleDamageSelection from "containers/step/vehicleDamage/VehicleDamageSelection";

export default function VehicleDamageCurrentRight(props) {
  return (
    <NextStepContext.Consumer>
      {nextStepContext => (
        <InnerVehicleDamageCurrentRight {...props} nextStepContext={nextStepContext}/>
      )}
    </NextStepContext.Consumer>
  )
}

class InnerVehicleDamageCurrentRight extends Component {
  componentWillMount() {
    const {nextStepContext} = this.props;

    nextStepContext.toggleDisabled(true);
  }

  componentDidMount() {
    const {nextStepContext} = this.props;

    nextStepContext.toggleDisabled(false);
  }

  render() {
    return (
      <Layout justifyContent="center">
        <Layout.OneColumn flex={1}>
          <VehicleDamageSelection {...this.props} selectAll={true}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
