import React, {Component} from "react";

import Layout from "components/common/Layout";
import ToggleButton from "components/form/ToggleButton";
import LegalPerson from "components/step/LegalPerson";
import FadeView from "components/view/FadeView";
import flowConstants from "constants/flow";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import NaturalPersons from "containers/step/responsiblePersonType/NaturalPersons";

export default class ResponsiblePersonTypeRight extends Component {

  render() {
    const {values} = this.props;

    return (
      <Layout spacing={10}>
        <Layout.OneColumn flex={1}>
          <ToggleButton name="responsiblePersonChoice">
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={responsiblePersonTypes.NATURAL_PERSON}
                                   label="responsiblePersonType.button.naturalPerson"/>
              <ToggleButton.Choice value={responsiblePersonTypes.LEGAL_PERSON}
                                   label="responsiblePersonType.button.legalPerson"/>
            </Layout.OneRow>
          </ToggleButton>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={values.responsiblePersonChoice}>
            {values.responsiblePersonChoice === responsiblePersonTypes.NATURAL_PERSON
              ? <NaturalPersons responsiblePersonChoice={values.responsiblePersonChoice}/>
              : <LegalPerson responsiblePersonChoice={values.responsiblePersonChoice}
                             stepName={flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME}/>
            }
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
