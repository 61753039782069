import React from "react";

import DatePicker, { registerLocale } from "react-datepicker";

import cx from "classnames";

import sl from "components/form/locale/sl/index";

import "react-datepicker/dist/react-datepicker.css";

import styles from "components/form/CommonDatePicker.module.scss";

registerLocale("sl", sl);

const Picker = function(props) {
  return (
    <DatePicker locale="sl"
                useWeekdaysShort={true}
                {...props}/>
  );
};

const Wrapper = function({children, modal}) {
  const className = cx({
    [styles.wrapper]: true,
    [styles.modal]: modal,
  });

  return (
    <div className={className}>
      {children}
    </div>
  );
};

export {
  Picker,
  Wrapper,
};
