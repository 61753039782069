import React from "react";

import _ from "lodash";

import Text from "components/common/Text";
import summaryRefConstants from "constants/summaryRef";
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes";
import {SummaryParticipantItemValue} from "containers/step/summary/car/SummaryParticipants";
import SummaryRefContainer from "components/step/summary/SummaryRefContainer";
import isUndefinedOrNull from "utility/utilityFunctions";

export default function SummaryCommonVehicleInfo({participant, jumpTo}) {
  switch (participant.participantInfo.inputType) {
    case vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA:
      if (isUndefinedOrNull(participant.participantInfo.vehicleData) || participant.participantInfo.vehicleData.success) {
        return (
          <SummaryRefContainer
            refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.REF}>
            <RegisteredInSlovenia jumpTo={jumpTo} participant={participant}/>
          </SummaryRefContainer>
        );
      } else {
        return (
          <SummaryRefContainer
            refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA_ALTERNATIVE.REF}>
            <AlternativeRegisteredInSlovenia jumpTo={jumpTo} participant={participant}/>
          </SummaryRefContainer>
        );
      }
    case vehicleInfoInputTypesConstants.REGISTERED_ABROAD:
      return (
        <SummaryRefContainer
          refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_ABROAD.REF}>
          <RegisteredAbroad jumpTo={jumpTo} participant={participant}/>
        </SummaryRefContainer>
      );
    case vehicleInfoInputTypesConstants.COMPANY_VEHICLE:
      return (
        <SummaryRefContainer
          refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.COMPANY_VEHICLE.REF}>
          <CompanyVehicle jumpTo={jumpTo} participant={participant}/>
        </SummaryRefContainer>
      );
    case vehicleInfoInputTypesConstants.NOT_REGISTERED:
      return (
        <SummaryRefContainer
          refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.NOT_REGISTERED.REF}>
          <SummaryParticipantItemValue label="vehicleInfo.vehicle.vehicleDescription"
                                       jumpTo={jumpTo} participant={participant}>
            {form => _.get(form, "participant.participantInfo.notRegistered.vehicleDescription") || ""}
          </SummaryParticipantItemValue>
        </SummaryRefContainer>
      );
    default:
      return null;
  }
}

function RegisteredInSlovenia({jumpTo, participant}) {
  return (
    <>
      <SummaryParticipantItemValue label="vehicleInfo.vehicle.registrationNumber"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.REGISTRATION_NUMBER}>
        {form => _.get(form, "participant.participantInfo.vehicleData.registrationNumber") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.manufacturer"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.MANUFACTURER}>
        {form => _.get(form, "participant.participantInfo.vehicleData.manufacturer") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.model"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.MODEL}>
        {form => _.get(form, "participant.participantInfo.vehicleData.model") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.insuranceCompany"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.INSURANCE_COMPANY}>
        {form => _.get(form, "participant.participantInfo.vehicleData.insuranceCompany") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.policyNumber"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.POLICY_NUMBER}>
        {form => _.get(form, "participant.participantInfo.vehicleData.policyNumber") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>
    </>
  );
}

function AlternativeRegisteredInSlovenia({jumpTo, participant}) {
  return (
    <>
      <SummaryParticipantItemValue label="vehicleInfo.vehicle.registrationNumber"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA_ALTERNATIVE.REGISTRATION_NUMBER}>
        {form => _.get(form, "participant.participantInfo.inSlovenia.registrationNumber") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.vehicleMark"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA_ALTERNATIVE.VEHICLE_MARK}>
        {form => _.get(form, "participant.participantInfo.inSlovenia.vehicleMark") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.vehicleType"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_IN_SLOVENIA_ALTERNATIVE.VEHICLE_TYPE}>
        {form => _.get(form, "participant.participantInfo.inSlovenia.vehicleType") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>
    </>
  );
}

function RegisteredAbroad({jumpTo, participant}) {
  return (
    <>
      <SummaryParticipantItemValue label="vehicleInfo.vehicle.country"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_ABROAD.COUNTRY}>
        {form => _.get(form, "participant.participantInfo.abroad.countryName") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.registrationNumber"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_ABROAD.REGISTRATION_NUMBER}>
        {form => _.get(form, "participant.participantInfo.abroad.registrationNumber") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.vehicleMark"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_ABROAD.VEHICLE_MARK}>
        {form => _.get(form, "participant.participantInfo.abroad.vehicleMark") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.vehicleType"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_ABROAD.VEHICLE_TYPE}>
        {form => _.get(form, "participant.participantInfo.abroad.vehicleType") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="vehicleInfo.vehicle.insuranceCompany"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.REGISTERED_ABROAD.INSURANCE_COMPANY}>
        {form => _.get(form, "participant.participantInfo.abroad.insuranceCompany") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>
    </>
  );
}


function CompanyVehicle({jumpTo, participant}) {
  return (
    <>
      <SummaryParticipantItemValue label="summary.content.participantsInfo.vehicle.vehicleChassisNumber"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.COMPANY_VEHICLE.VEHICLE_CHASSIS_NUMBER}>
        {form => _.get(form, "participant.participantInfo.company.vehicleChassisNumber") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>

      <SummaryParticipantItemValue label="summary.content.participantsInfo.vehicle.vehicleMark"
                                   jumpTo={jumpTo} participant={participant}
                                   refName={summaryRefConstants.PARTICIPANTS.PARTICIPANT.COMMON_VEHICLE_INFO.COMPANY_VEHICLE.VEHICLE_MARK}>
        {form => _.get(form, "participant.participantInfo.company.vehicleMark") || <Text label="summary.content.common.boolean.dontKnow"/>}
      </SummaryParticipantItemValue>
    </>
  );
}
