import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import bankAccountTypeConstants from "constants/bankAccountTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import userIdentificationTypeConstants from "constants/userIdentificationType";
import {RegistryContext} from "containers/context/RegistyContainer";
import Header from "containers/step/Header";
import BankAccountsRight from "containers/step/bankAccounts/BankAccountsRight";
import isSlovenianBankAccount from "containers/step/userIdentification/utilityFunctions";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";
import {isSloAddress} from "utility/utilityFunctions";

const bankValidationSchema = Yup.object().shape({
  name: getMax(Yup.string().nullable().trim().required("validation.bankAccount.name"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].bankName),
  bic: getMax(Yup.string().nullable().trim().required("validation.bankAccount.bic"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].swift),
});

const validationSchema = (slovenia) => Yup.object().shape({
  bankAccountType: Yup.string().required("validation.required"),
  bankAccountMojAs: Yup.string().when(["bankAccountType", "userIdentificationType"], {
    is: (bankAccountType, userIdentificationType) => userIdentificationType !== userIdentificationTypeConstants.MANUAL && bankAccountType === bankAccountTypeConstants.OWN,
    then: Yup.string().trim().required("validation.required"),
  }),
  bankAccountNoMojAs: Yup.string().when(["bankAccountType", "userIdentificationType"], {
    is: (bankAccountType, userIdentificationType) => userIdentificationType === userIdentificationTypeConstants.MANUAL && bankAccountType === bankAccountTypeConstants.OWN,
    then: Yup.string().trim().required("validation.required").validateBankAccount("validation.bankAccount.format"),
  }),
  bank: Yup.object().when(["bankAccountNoMojAs", "bankAccountType", "userIdentificationType"], {
    is: (bankAccountNoMojAs, bankAccountType, userIdentificationType) => !isSlovenianBankAccount({bankAccount: bankAccountNoMojAs}) && userIdentificationType === userIdentificationTypeConstants.MANUAL && bankAccountType === bankAccountTypeConstants.OWN,
    then: bankValidationSchema
  }),
  otherPerson: Yup.object()
    .when("bankAccountType", {
      is: bankAccountType => bankAccountType === bankAccountTypeConstants.OTHER_PERSON,
      then: otherPersonValidationSchema(slovenia)
    })
    .when("bankAccountType", {
      is: bankAccountType => bankAccountType === bankAccountTypeConstants.OTHER_LEGAL_PERSON,
      then: otherLegalPersonValidationSchema(slovenia)
    })
});

const otherPersonValidationSchema = slovenia => Yup.object().shape({
  name: getMax(Yup.string().trim().required("validation.name"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].name),
  surname: getMax(Yup.string().trim().required("validation.surname"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].surname),
  birthDate: Yup.mixed().required("validation.required"),
  address: Yup.object().shape({
    countryName: Yup.string().trim().required("validation.address.country"),
    address: Yup.object().shape({
      streetNumber: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].streetNumber),
      streetName: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].streetName),
    }),
    city: Yup.object().shape({
      zip: getMax(Yup.string().trim().required("validation.address.zip"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].zip),
      city: getMax(Yup.string().trim().required("validation.address.city"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].city),
    }),
  }),
  telephoneNumber: getMax(Yup.string().trim().validatePhoneNumber("validation.telephoneNumber.notMandatory.format"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].telephoneNumber),
  email: getMax(Yup.string().email("validation.email"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].email),
  taxNumber: Yup.string().when("address", {
    is: address => isSloAddress(address, slovenia),
    then: getMax(Yup.string().trim().required("validation.taxNumber").validateSloTaxNumber("validation.taxNumber.format"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].taxNumberSlo),
    otherwise: getMax(Yup.string().trim().required("validation.taxNumber"), fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].taxNumber),
  }),
  bankAccount: Yup.string().trim().required("validation.bankAccount").validateBankAccount("validation.bankAccount.format"),
  bank: Yup.object().when("bankAccount", {
    is: bankAccount => !isSlovenianBankAccount({bankAccount}),
    then: bankValidationSchema,
  }),
});

const otherLegalPersonValidationSchema = slovenia => Yup.object().shape({
  name: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].name)
});


//step 34
export default class BankAccountsStepContainer extends Component {
  left = (props) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader {...props}/>
        <Text element="h2" label={`bankAccounts.sidebar.title`}/>
        <Text element="div" label={`bankAccounts.sidebar.subtitle`} isHtml={true}/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <BankAccountsRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <RegistryContext.Consumer>
        {context => (
          <SplitViewContainer left={this.left} right={this.right}
                              validationSchema={validationSchema(context.registry.slovenia)}/>
        )}
      </RegistryContext.Consumer>
    );
  }
}
