import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import ClaimTypeRight from "containers/step/claimType/ClaimTypeRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import claimTypeConstants from "constants/claimTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Yup, {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  choiceType: Yup.string().trim().required("validation.required"),
  otherChoice: Yup.string().when("choiceType", {
    is: claimTypeConstants.GENERAL,
    then: getMax(Yup.string().trim().required("validation.required"), fieldLengthConstants[flowConstants.steps.CLAIM_TYPE.NAME].otherChoice),
  })
});

// step 2
export default class ClaimTypeStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="claimType.sidebar.title" />
        <Text element="div" label="claimType.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <ClaimTypeRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
