import flowConstants from "constants/flow";

export default {
  [flowConstants.steps.CLAIM_TYPE.NAME]: {
    otherChoice: 200,
  },
  [flowConstants.steps.ACCIDENT_LOCATION.NAME]: {
    description: 400,
  },
  [flowConstants.steps.ABLE_TO_DRIVE.NAME]: {
    description: 400,
  },
  [flowConstants.steps.EVENT_DESCRIPTION.NAME]: {
    description: 1500,
  },
  [flowConstants.steps.PARTICIPANTS_INFO.NAME]: {
    registrationNumber: 50,
    vehicleChassisNumber: 50,
    vehicleType: 50,
    vehicleMark: 50,
    vehicleDescription: 1500,
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    insuranceCompany: 100,
    bikeDescription: {
      description: 1500,
      purchaseYear: 50,
      currentValue: 16,
    }
  },
  [flowConstants.steps.DRAWING.NAME]: {
    text: 70,
  },
  [flowConstants.steps.OWN_VEHICLE_INFO_WAS_DRIVER.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    telephoneNumber: 50,
    licenceNumber: 20,
  },
  [flowConstants.steps.OWN_VEHICLE_INFO_REPORTED_POLICE.NAME]: {
    reportedPoliceStation: 30,
  },
  [flowConstants.steps.OWN_VEHICLE_INFO_PASSENGERS.NAME]: {
    name: 50,
    surname: 50,
  },
  [flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
  },
  [flowConstants.steps.APPLICANT_DATA.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    telephoneNumber: 50,
    licenceNumber: 20,
  },
  [flowConstants.steps.USER_IDENTIFICATION.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    email: 50,
    telephoneNumber: 50,
    taxNumber: 20,
    taxNumberSlo: 8,
    policyNumber: 50,
    licenceNumber: 20,
  },
  [flowConstants.steps.USER_LOGIN.NAME]: {
    taxNumber: 8,
    policyNumber: 50,
  },
  [flowConstants.steps.BANK_ACCOUNTS.NAME]: {
    bankName: 200,
    swift: 50,
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    email: 50,
    telephoneNumber: 50,
    taxNumber: 20,
    taxNumberSlo: 8,
  },
  [flowConstants.steps.PHONE_NUMBER.NAME]: {
    telephoneNumber: 50,
    message: 1500,
  },
  [flowConstants.homeSteps.HOME_BURGLARY_WHO.NAME]: {
    description: 1500,
  },
  [flowConstants.homeSteps.HOME_BURGLARY_PROPERTY_TYPE.NAME]: {
    description: 1500,
  },
  [flowConstants.homeSteps.HOME_BURGLARY_VIOLENT.NAME]: {
    description: 1500,
  },
  [flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME]: {
    description: 1500,
    damageEstimate: 16,
    purchasePrice: 16,
  },
  [flowConstants.homeSteps.HOME_REPORTED_POLICE.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    email: 50,
    telephoneNumber: 50,
    policeStation: 50,
  },
  [flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    policyNumber: 50,
    taxNumber: 20,
    taxNumberSlo: 8,
  },
  vehicleInfoLogic: {
    notRegistered: {
      description: 30,
    },
  },
  address: {
    streetNumber: 10,
    city: 100,
    zip: 20,
    streetName: 100,
  },
  [flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    policyNumber: 50,
    taxNumber: 20,
    taxNumberSlo: 8,
  },
  [flowConstants.personSteps.INJURED_PERSON_WORKING_STATUS.NAME]:{
    description: 1500,
  },
  [flowConstants.personSteps.SUFFERED_MEDICAL_COSTS.NAME]: {
    description: 1500
  },
  [flowConstants.personSteps.MEDICAL_OPERATION_NEEDED.NAME]: {
    description: 1500,
  },
  [flowConstants.personSteps.MEDICAL_PROBLEM_DESCRIPTION.NAME]: {
    description: 1500,
  },
  [flowConstants.personSteps.PAST_MEDICAL_CONDITIONS.NAME]: {
    description: 1500,
  },
  [flowConstants.personSteps.SICKNESS_DESCRIPTION.NAME]: {
    description: 1500,
  },
  [flowConstants.personSteps.PERSON_REPORTED_POLICE.NAME]: {
    policeStation: 50,
  },
  [flowConstants.personSteps.CAUSE_OF_DEATH.NAME]: {
    description: 1500,
  },
  [flowConstants.personSteps.WRITING_REPORT.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    taxNumber: 20,
    taxNumberSlo: 8,
  },
  [flowConstants.personSteps.WITNESSES_PRESENT.NAME]: {
    name: 50,
    surname: 50,
    email: 50,
    telephoneNumber: 50,
  },
  [flowConstants.personSteps.PERSON_INSURANCE.NAME]: {
    name: 50,
  },
  [flowConstants.personSteps.RESPONSIBLE_PERSON_RELATION.NAME]: {
    description: 1500,
  },
  [flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME]: {
    description: 1500,
    amount: 16,
  },
  [flowConstants.personSteps.PLACE_OF_DEATH.NAME]: {
    description: 1500,
  },
  [flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    policyNumber: 50,
    taxNumber: 20,
    taxNumberSlo: 8,
  },
  [flowConstants.personSteps.ACCIDENT_VEHICLE_INFO.NAME]: {
    registrationNumber: 50,
    seatType: 50,
  },
  [flowConstants.personSteps.REFERRAL_NUMBERS.NAME]: {
    referralNumber: 13,
    card: 20,
  },
  [flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME]: {
    taxNumber: 20,
    taxNumberSlo: 8,
  },
  [flowConstants.personSteps.BENEFICIARY_PERSONS.NAME]: {
    name: 50,
    surname: 50,
    city: 100,
    zip: 20,
    streetName: 100,
    streetNumber: 10,
    email: 50,
    telephoneNumber: 50,
    taxNumber: 20,
    taxNumberSlo: 8,
    policyNumber: 50,
  },
}
