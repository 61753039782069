import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import placeOfDeathTypes from "constants/placeOfDeathTypes";
import Header from "containers/step/Header";
import PlaceOfDeathRight from "containers/step/placeOfDeath/PlaceOfDeathRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";


const validationSchema = Yup.object().shape({
  placeOfDeath: Yup.string().required(),
  description: Yup.string()
    .when("placeOfDeath", {
      is: placeOfDeathTypes.OTHER,
      then: getMax(Yup.string().trim(),
        fieldLengthConstants[flowConstants.personSteps.PLACE_OF_DEATH.NAME].description),
    }),
});

export default class PlaceOfDeathStepContainer extends Component {

  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="placeOfDeath.sidebar.title"/>
      </SplitViewContainer.Left>
    );
  };


  right = (props) => {
    const {values, onNextStep} = props;
    return (
      <SplitViewContainer.Right {...props} showNextStepButton={values.placeOfDeath === placeOfDeathTypes.OTHER}>
        <PlaceOfDeathRight {...props}/>
      </SplitViewContainer.Right>);
  }

  render() {
    return (<SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>)
  }
}
