import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import DateField from "components/form/DateField";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import wasDriverTypeConstants from "constants/wasDriverTypes";
import SexField from "components/form/SexField";
import {leaveNumbersAndLetters} from "utility/textTransform";

export default function ApplicantDataRight(props) {
  const {values: {wasDriver}} = props;

  return (
    <Layout maxWidth={450} spacing={5} width="100%" justifyContent="center">
      {wasDriver === wasDriverTypeConstants.YES
        ? <DriverApplicantData/>
        : <GeneralApplicantData/>
      }
    </Layout>
  )
}

function GeneralApplicantData() {
  return (
    <Layout maxWidth={450} spacing={5} width="100%" justifyContent="center">
      <Layout.OneColumn>
        <TextField name="general.name"
                   placeholder="applicantData.content.name.placeholder"
                   label="applicantData.content.name.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].name + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="general.surname"
                   placeholder="applicantData.content.surname.placeholder"
                   label="applicantData.content.surname.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].surname + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
    </Layout>
  );
}

function DriverApplicantData() {
  return (
    <Layout maxWidth={450} spacing={5} width="100%" justifyContent="center">
      <Layout.OneColumn>
        <Text element="span" variant="variant2" label="applicantData.content.general.title"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="driver.name"
                   placeholder="applicantData.content.name.placeholder"
                   label="applicantData.content.name.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].name + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="driver.surname"
                   placeholder="applicantData.content.surname.placeholder"
                   label="applicantData.content.surname.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].surname + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <DateField name="driver.birthDate"
                   isRequired={true}
                   placeholder="applicantData.content.birthDate.placeholder"
                   label="applicantData.content.birthDate.label"
                   legalAgeField={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <SexField name="driver.sex" isRequired={true} />
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Address name="driver.address"
                 isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="driver.telephoneNumber"
                   placeholder="applicantData.content.telephoneNumber.placeholder"
                   label="applicantData.content.telephoneNumber.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].telephoneNumber + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="driver.licenceNumber"
                   placeholder="applicantData.content.licenceNumber.placeholder"
                   transform={leaveNumbersAndLetters}
                   label="applicantData.content.licenceNumber.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].licenceNumber + 1}
                   isRequired={true}/>
      </Layout.OneColumn>
    </Layout>
  );
}