import React from "react";

import Button from "components/common/Button";
import SketchDragSource from "components/sketch/item/SketchDragSource";
import SketchItem from "components/sketch/item/SketchItem";

export default function SketchNavigationItem({buttonClassName, className, item, ...props}) {
  return (
    <SketchDragSource value={item}>
      {() => (
        <div>
          <Button className={buttonClassName} variant="variant4"
                  icon={<SketchItem className={className}
                                    item={item}
                                    image={item => item.IMAGE_NAVIGATION}/>}/>
        </div>
      )}
    </SketchDragSource>
  );
}
