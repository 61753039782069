import React, {Component} from "react";

import * as dateFns from "date-fns/esm";
import {Field} from "formik";

import {Picker, Wrapper} from "components/form/CommonDatePicker";

import styles from "components/form/DateRangePicker.module.scss";

import "react-datepicker/dist/react-datepicker.css";

export default class DateRangePicker extends Component {
  state = {
    click: 0,
    fromFocus: dateFns.startOfMonth(dateFns.subMonths(new Date(), 1)),
    toFocus: dateFns.startOfMonth(new Date()),
  };

  handleChange = (from, to) => (date) => {
    if (this.state.click % 2 === 0) {
      from.form.setFieldValue(from.field.name, date);
      to.form.setFieldValue(to.field.name, null);

      this.incrementClick();
    } else if (dateFns.isAfter(date, from.field.value)) {
      to.form.setFieldValue(to.field.name, date);

      this.incrementClick();
    } else {
      from.form.setFieldValue(from.field.name, date);
      to.form.setFieldValue(to.field.name, null);
    }
  };

  handleMonthChangeFrom = (date) => {
    const startOfMonth = dateFns.startOfMonth(date);

    if (!dateFns.isBefore(startOfMonth, this.state.toFocus)) {
      this.setState({
        fromFocus: startOfMonth,
        toFocus: dateFns.addMonths(startOfMonth, 1),
      });
    } else {
      this.setState({
        fromFocus: startOfMonth,
      });
    }
  };

  handleMonthChangeTo = (date) => {
    const startOfMonth = dateFns.startOfMonth(date);

    if (!dateFns.isAfter(startOfMonth, this.state.fromFocus)) {
      this.setState({
        fromFocus: dateFns.subMonths(startOfMonth, 1),
        toFocus: startOfMonth,
      });
    } else {
      this.setState({
        toFocus: startOfMonth,
      });
    }
  };

  incrementClick = () => {
    this.setState(state => ({
      click: state.click + 1,
    }));
  };

  render() {
    const {nameFrom, nameTo} = this.props;

    const commonDatePickerProps = {
      inline: true,
      fixedHeight: true,
    };

    const datePickerProps =
      this.state.click % 2 === 0
        ? {}
        : {selectsEnd: true};

    return (
      <Field
        name={nameFrom}
        render={(fieldPropsFrom) => (
          <Field
            name={nameTo}
            render={(fieldPropsTo) => (
              <Wrapper>
                <div className={styles.column}>
                  {/*<Text element="span" className={styles.header} label="date.range.from"/>*/}

                  <Picker
                    {...commonDatePickerProps}
                    {...datePickerProps}
                    openToDate={this.state.fromFocus}
                    selected={dateFns.isSameMonth(this.state.fromFocus, fieldPropsFrom.field.value) ? fieldPropsFrom.field.value : null}
                    startDate={fieldPropsFrom.field.value}
                    endDate={fieldPropsTo.field.value}
                    onMonthChange={this.handleMonthChangeFrom}
                    onChange={this.handleChange(fieldPropsFrom, fieldPropsTo)}
                    maxDate={dateFns.lastDayOfMonth(dateFns.subMonths(new Date(), 1))}
                  />
                </div>

                <div className={styles.column}>
                  {/*<Text element="span" className={styles.header} label="date.range.to"/>*/}

                  <Picker
                    {...commonDatePickerProps}
                    {...datePickerProps}
                    openToDate={this.state.toFocus}
                    selected={dateFns.isSameMonth(this.state.toFocus, fieldPropsTo.field.value) ? fieldPropsTo.field.value : null}
                    startDate={fieldPropsFrom.field.value}
                    endDate={fieldPropsTo.field.value}
                    onMonthChange={this.handleMonthChangeTo}
                    onChange={this.handleChange(fieldPropsFrom, fieldPropsTo)}
                    maxDate={new Date()}
                  />
                </div>
              </Wrapper>
            )}
          />
        )}
      />
    );
  }
}
