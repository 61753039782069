import React from "react";

import {isEmpty, isUndefined} from "underscore";

import Text from "components/common/Text";
import PersonSvg from "components/svg/PersonSvg";
import booleanTypesConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";
import causeOfDeathTypes from "constants/causeOfDeathTypes";
import medicalCareTypes from "constants/medicalCareTypes";
import placeOfDeathTypes from "constants/placeOfDeathTypes";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import SummaryNaturalPersons from "containers/step/summary/person/SummaryNaturalPersons";
import Summary from "containers/step/summary/Summary";
import summaryRefConstants from "constants/summaryRef";
import {formatCountryAndTaxNumber} from "utility/summary";

import styles from "containers/step/summary/SummaryInfo.module.scss";

export default function SummaryInfoPerson({claimType, medicalCareType}) {
  return (
    <Summary title="summary.content.info.person.title"
             onePart={true}
             refName={summaryRefConstants.PERSON_INFO.REF}>
      <Summary.ItemValue label="summary.content.medicalCareType.label"
                         refName={summaryRefConstants.PERSON_INFO.MEDICAL_CARE_TYPE}
                         formName={{name: flowConstants.personSteps.MEDICAL_CARE_TYPE.NAME}}>
        {form => form.medicalCareType === medicalCareTypes.OTHER
          ? <Text label={`summary.content.medicalCareType.${form.medicalCareType}`}/>
          : <Text label={`summary.content.medicalCareType.${form.medicalCareType}.${form.medicalCareTypeDescription}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.sufferedMedicalCosts.label"
                         refName={summaryRefConstants.PERSON_INFO.SUFFERED_MEDICAL_COSTS}
                         formName={{name: flowConstants.personSteps.SUFFERED_MEDICAL_COSTS.NAME}}>
        {form => form.description}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.medicalOperationNeeded.label"
                         refName={summaryRefConstants.PERSON_INFO.MEDICAL_OPERATION_NEEDED}
                         formName={{name: flowConstants.personSteps.MEDICAL_OPERATION_NEEDED.NAME}}>
        {form => form.description}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.medicalProblemDescription.label"
                         refName={summaryRefConstants.PERSON_INFO.MEDICAL_PROBLEM_DESCRIPTION}
                         formName={{name: flowConstants.personSteps.MEDICAL_PROBLEM_DESCRIPTION.NAME}}>
        {form => form.description}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.pastMedicalConditions.label"
                         refName={summaryRefConstants.PERSON_INFO.PAST_MEDICAL_CONDITIONS}
                         formName={{name: flowConstants.personSteps.PAST_MEDICAL_CONDITIONS.NAME}}
                         condition={(form) => form.pastMedicalConditions !== booleanTypesConstants.YES || isEmpty(form.description)}>
        {form => <Text label={`summary.content.common.boolean.${form.pastMedicalConditions}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.pastMedicalConditionsDescription.label"
                         refName={summaryRefConstants.PERSON_INFO.PAST_MEDICAL_CONDITIONS}
                         formName={{name: flowConstants.personSteps.PAST_MEDICAL_CONDITIONS.NAME}}
                         condition={(form) => form.pastMedicalConditions === booleanTypesConstants.YES && !isEmpty(form.description)}>
        {form => form.description}
      </Summary.ItemValue>
      <Summary.ItemValue
        label={`summary.content.accidentDate.${claimType}.${medicalCareType.medicalCareTypeDescription || medicalCareType.medicalCareType}.label`}
        refName={summaryRefConstants.PERSON_INFO.ACCIDENT_DATE}
        formName={{name: flowConstants.steps.ACCIDENT_DATE.NAME}}>
        {form => form.date}
      </Summary.ItemValue>
      <Summary.ItemValue
        label={`summary.content.accidentTime.${claimType}.${medicalCareType.medicalCareTypeDescription}.label`}
        refName={summaryRefConstants.PERSON_INFO.ACCIDENT_TIME}
        formName={{name: flowConstants.steps.ACCIDENT_TIME.NAME}}>
        {form => (
          form.choice === booleanTypesConstants.DONT_KNOW
            ? <Text label="summary.content.common.boolean.dontKnow"/>
            : form.time
        )}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.sicknessDescription.label"
                         refName={summaryRefConstants.PERSON_INFO.SICKNESS_DESCRIPTION}
                         formName={{name: flowConstants.personSteps.SICKNESS_DESCRIPTION.NAME}}>
        {form => form.description}
      </Summary.ItemValue>
      <Summary.ItemValue label={`summary.content.accidentLocation.${claimType}.label`}
                         refName={summaryRefConstants.PERSON_INFO.ACCIDENT_LOCATION}
                         formName={{name: flowConstants.steps.ACCIDENT_LOCATION.NAME}}>
        {form => isEmpty(form.selectedLocation) ?
          <Text label="summary.content.common.boolean.dontKnow"/> : form.selectedLocation}
      </Summary.ItemValue>
      <Summary.ItemValue label={`summary.content.accidentLocation.description.${claimType}.label`}
                         refName={summaryRefConstants.PERSON_INFO.ACCIDENT_DESCRIPTION}
                         formName={{name: flowConstants.steps.ACCIDENT_LOCATION.NAME}}>
        {form => isEmpty(form.locationDescription) ?
          <Text label="summary.content.common.boolean.dontKnow"/> : form.locationDescription}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.placeOfDeath.label"
                         refName={summaryRefConstants.PERSON_INFO.PLACE_OF_DEATH}
                         formName={{name: flowConstants.personSteps.PLACE_OF_DEATH.NAME}}>
        {form => form.placeOfDeath === placeOfDeathTypes.OTHER && !isEmpty(form.description) ? form.description :
          <Text label={`summary.content.placeOfDeath.${form.placeOfDeath}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.personDamage.label"
                         refName={summaryRefConstants.PERSON_INFO.PERSON_DAMAGE}
                         formName={{name: flowConstants.personSteps.PERSON_DAMAGE.NAME}}>
        {form => <SummaryPersonDamage value={form.personDamage}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.eventDescription.label"
                         refName={summaryRefConstants.PERSON_INFO.EVENT_DESCRIPTION}
                         formName={{name: flowConstants.steps.EVENT_DESCRIPTION.NAME}}>
        {form => form.description}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.accidentOccurrenceType.label"
                         refName={summaryRefConstants.PERSON_INFO.ACCIDENT_OCCURRENCE_TYPE}
                         formName={{name: flowConstants.personSteps.ACCIDENT_OCCURRENCE_TYPE.NAME}}>
        {form => <Text label={`summary.content.accidentOccurrenceType.${form.accidentOccurrenceType}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.causeOfDeath.label"
                         refName={summaryRefConstants.PERSON_INFO.CAUSE_OF_DEATH}
                         formName={{name: flowConstants.personSteps.CAUSE_OF_DEATH.NAME}}>
        {form => form.causeOfDeath === causeOfDeathTypes.OTHER && !isEmpty(form.description) ? form.description :
          <Text label={`summary.content.causeOfDeath.${form.causeOfDeath}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.personReportedPolice.label"
                         refName={summaryRefConstants.PERSON_INFO.PERSON_REPORTED_POLICE}
                         formName={{name: flowConstants.personSteps.PERSON_REPORTED_POLICE.NAME}}>
        {form => <Text label={`summary.content.personReportedPolice.${form.reportedToPolice}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.personReportedPolice.policeStation.label"
                         refName={summaryRefConstants.PERSON_INFO.PERSON_REPORTED_POLICE_POLICE_STATION}
                         formName={{name: flowConstants.personSteps.PERSON_REPORTED_POLICE.NAME}}
                         condition={form => form.reportedToPolice === booleanTypesConstants.YES}>
        {form => form.policeStation}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.witnessesPresent.label"
                         refName={summaryRefConstants.PERSON_INFO.WITNESSES_PRESENTS}
                         formName={{name: flowConstants.personSteps.WITNESSES_PRESENT.NAME}}>
        {form => <Text label={`summary.content.witnessesPresent.${form.witnessesPresent}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.hadOperation.label"
                         refName={summaryRefConstants.PERSON_INFO.HAD_OPERATION}
                         formName={{name: flowConstants.personSteps.HAD_OPERATION.NAME}}>
        {form => !isUndefined(form.hadOperation) ?
          <Text label={`summary.content.hadOperation.${form.hadOperation}`}/> : null}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.injuredPersonWorkingStatus.label"
                         refName={summaryRefConstants.PERSON_INFO.INJURED_PERSON_WORKING_STATUS}
                         formName={{name: flowConstants.personSteps.INJURED_PERSON_WORKING_STATUS.NAME}}>
        {form => form.description}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.personInsurance.label"
                         refName={summaryRefConstants.PERSON_INFO.INJURED_PERSON_WORKING_STATUS}
                         formName={{name: flowConstants.personSteps.PERSON_INSURANCE.NAME}}>
        {form => !isUndefined(form.personInsurance) ?
          <Text label={`summary.content.common.boolean.${form.personInsurance}`}/> : null}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.personInsurance.InsuranceOwners.label"
                         refName={summaryRefConstants.PERSON_INFO.INJURED_PERSON_WORKING_STATUS}
                         formName={{name: flowConstants.personSteps.PERSON_INSURANCE.NAME}}
                         condition={form => !isEmpty(form.insuranceOwners) && form.personInsurance === booleanTypesConstants.YES}>
        {form => form.insuranceOwners.map((insuranceOwner, index) => (index ? ", " : "") + insuranceOwner)}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.personResponsible.label"
                         refName={summaryRefConstants.PERSON_INFO.PERSON_RESPONSIBLE}
                         formName={{name: flowConstants.personSteps.PERSON_RESPONSIBLE.NAME}}>
        {form => !isUndefined(form.isOtherPersonFault) ?
          <Text label={`summary.content.common.boolean.${form.isOtherPersonFault}`}/> : null}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.claimCompensationVehicle.label"
                         refName={summaryRefConstants.PERSON_INFO.CLAIM_COMPENSATION_VEHICLE}
                         formName={{name: flowConstants.personSteps.CLAIM_COMPENSATION_VEHICLE.NAME}}>
        {form => !isUndefined(form.claimCompensationVehicle) ?
          <Text label={`summary.content.common.boolean.${form.claimCompensationVehicle}`}/> : null}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.claimCompensationPerson.label"
                         refName={summaryRefConstants.PERSON_INFO.CLAIM_COMPENSATION_PERSON}
                         formName={{name: flowConstants.personSteps.CLAIM_COMPENSATION_PERSON.NAME}}>
        {form => !isUndefined(form.claimCompensationPerson) ?
          <Text label={`summary.content.common.boolean.${form.claimCompensationPerson}`}/> : null}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.responsiblePersonType.label"
                         refName={summaryRefConstants.PERSON_INFO.RESPONSIBLE_PERSON_TYPE.REF}
                         formName={{name: flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME}}>
        {form => <Text label={`summary.content.responsiblePersonType.${form.responsiblePersonChoice}`}/>}
      </Summary.ItemValue>
      <Summary.Items
        formName={{name: flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME}}
        condition={form => form.responsiblePersonChoice === responsiblePersonTypes.NATURAL_PERSON}>
        {form => <SummaryNaturalPersons {...form} />}
      </Summary.Items>
      <Summary.ItemValue label="summary.content.responsiblePersonType.responsiblePerson.label"
                         refName={summaryRefConstants.PERSON_INFO.RESPONSIBLE_PERSON_TYPE.LEGAL_PERSON}
                         formName={{name: flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME}}
                         condition={form => form.responsiblePersonChoice === responsiblePersonTypes.LEGAL_PERSON}>
        {form => form.responsiblePerson}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.responsiblePersonRelation.label"
                         refName={summaryRefConstants.PERSON_INFO.RESPONSIBLE_PERSON_RELATION}
                         formName={{name: flowConstants.personSteps.RESPONSIBLE_PERSON_RELATION.NAME}}>
        {form => !isUndefined(form.responsiblePersonRelation) ?
          form.responsiblePersonRelation === booleanTypesConstants.YES && !isEmpty(form.description) ?
            form.description :
            <Text label={`summary.content.common.boolean.${form.responsiblePersonRelation}`}/> : null}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.sufferedEventType.label"
                         refName={summaryRefConstants.PERSON_INFO.SUFFERED_EVENT_TYPE}
                         formName={{name: flowConstants.personSteps.SUFFERED_EVENT_TYPE.NAME}}>
        {form => !isEmpty(form.sufferedEventTypes) ? form.sufferedEventTypes.map((sufferedEventType, index) =>
          <div key={index}>
            <Text label={`summary.content.sufferedEventType.${sufferedEventType}`}/>
          </div>) : <Text label="summary.content.common.boolean.unknown"/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.damageDescription.description.label"
                         refName={summaryRefConstants.PERSON_INFO.DAMAGE_DESCRIPTION}
                         formName={{name: flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME}}>
        {form => form.description}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.damageDescription.amount.label"
                         refName={summaryRefConstants.PERSON_INFO.DAMAGE_DESCRIPTION_AMOUNT}
                         formName={{name: flowConstants.personSteps.DAMAGE_DESCRIPTION.NAME}}>
        {form => form.amount}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.damagesClaimFiled.label"
                         refName={summaryRefConstants.PERSON_INFO.DAMAGES_CLAIM_FILED}
                         formName={{name: flowConstants.personSteps.DAMAGES_CLAIM_FILED.NAME}}>
        {form => <Text label={`summary.content.common.boolean.${form.isDamagesClaimFiled}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label={`summary.content.accidentInvolved.${medicalCareType.medicalCareTypeDescription}.label`}
                         refName={summaryRefConstants.PERSON_INFO.ACCIDENT_INVOLVED}
                         formName={{name: flowConstants.personSteps.ACCIDENT_INVOLVED.NAME}}>
        {form => <Text label={`summary.content.accidentInvolved.${form.involvementType}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.participantsNumber.multipleParticipants.label"
                         refName={summaryRefConstants.PERSON_INFO.PARTICIPANTS_NUMBER.MULTIPLE_PARTICIPANTS}
                         formName={{name: flowConstants.steps.PARTICIPANTS_NUMBER.NAME}}
                         condition={form => !isUndefined(form.multipleParticipants)}>
        {form => (
          <Text label={`summary.content.common.boolean.${form.multipleParticipants}`}/>
        )}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.participantsNumber.count.label"
                         refName={summaryRefConstants.PERSON_INFO.PARTICIPANTS_NUMBER.LENGTH}
                         formName={{name: flowConstants.steps.PARTICIPANTS_NUMBER.NAME}}
                         condition={form => form.multipleParticipants === booleanTypesConstants.YES}>
        {form => form.participants.length}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.accidentVehicleInfo.registrationNumber.label"
                         refName={summaryRefConstants.PERSON_INFO.PARTICIPANTS_NUMBER.LENGTH}
                         formName={{name: flowConstants.personSteps.ACCIDENT_VEHICLE_INFO.NAME}}>
        {form => form.registrationNumber}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.accidentVehicleInfo.seatType.label"
                         refName={summaryRefConstants.PERSON_INFO.PARTICIPANTS_NUMBER.LENGTH}
                         formName={{name: flowConstants.personSteps.ACCIDENT_VEHICLE_INFO.NAME}}>
        {form => form.seatType}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.referralNumbers.label"
                         refName={summaryRefConstants.PERSON_INFO.REFERRAL_NUMBERS}
                         formName={{name: flowConstants.personSteps.REFERRAL_NUMBERS.NAME}}>
        {form => form.referralNumbers.map((referralNumber, index) => (index ? ", " : "") + referralNumber)}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.referralNumbers.code.label"
                         refName={summaryRefConstants.PERSON_INFO.REFERRAL_NUMBERS_CARD}
                         formName={{name: flowConstants.personSteps.REFERRAL_NUMBERS.NAME}}>
        {form => form.card}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.lifeInsuranceClaim.label"
                         refName={summaryRefConstants.PERSON_INFO.LIFE_INSURANCE_CLAIM}
                         formName={{name: flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME}}>
        {form => <Text label={`summary.content.common.boolean.${form.lifeInsuranceClaim}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.lifeInsuranceClaim.isZDA.label"
                         refName={summaryRefConstants.PERSON_INFO.LIFE_INSURANCE_CLAIM_ZDA}
                         formName={{name: flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME}}
                         condition={form => form.lifeInsuranceClaim === booleanTypesConstants.YES}>
        {form => <Text label={`summary.content.lifeInsuranceClaim.isZDA.${form.isZDA}`}/>}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.lifeInsuranceClaim.isSlovenia.label"
                         refName={summaryRefConstants.PERSON_INFO.LIFE_INSURANCE_CLAIM_SLOVENIA}
                         formName={{name: flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME}}
                         condition={form => form.lifeInsuranceClaim === booleanTypesConstants.YES}>
        {form => <Text label={`summary.content.lifeInsuranceClaim.isSlovenia.${form.isSlovenia}`}/>}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.lifeInsuranceClaim.taxInfos.label"
                         refName={summaryRefConstants.PERSON_INFO.LIFE_INSURANCE_CLAIM_TAX}
                         formName={{name: flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME}}
                         condition={form => form.lifeInsuranceClaim === booleanTypesConstants.YES}>
        {form => form.taxInfos.map((taxInfo, index) =>
          <div>{formatCountryAndTaxNumber(taxInfo.country, taxInfo.taxNumber)}</div>)}
      </Summary.ItemValue>
    </Summary>
  );
}

function SummaryPersonDamage(props) {
  return (
    <div className={styles.damage}>
      <PersonSvg {...props} />
    </div>
  );
}
