import React from "react";

import Layout from "components/common/Layout";
import VehicleInfoHeader from "components/step/VehicleInfoHeader";
import VehicleInfoInputContainer from "components/step/VehicleInfoInputContainer";

export default function HomeVehicleInfoRight({values: {info}, step, onNextStep}) {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <VehicleInfoHeader info={info}
                           headerLabel="homeVehicleInfo.vehicleInfo.title"
                           contentLabel="homeVehicleInfo.vehicleInfo.subtitle"/>
      </Layout.OneColumn>
      <Layout.OneColumn width="100%">
        <VehicleInfoInputContainer name="vehicleInfo" info={info} step={step} onNextStep={onNextStep}/>
      </Layout.OneColumn>
    </Layout>
  );
}
