import React, {Component} from "react";

import {Field} from "formik";

import vehicleTypeConstants from "constants/vehicleTypes";

import cx from "classnames";

import styles from "components/form/SvgField.module.scss";

export default class SvgField extends Component {
  render() {
    const {name} = this.props;

    return (
      <Field
        name={name}
        render={(fieldProps) => {
          return <SvgFieldInner {...this.props} fieldProps={fieldProps}/>;
        }}
      />
    );
  }
}

class SvgFieldInner extends Component {
  static defaultProps ={
    disabled: false,
  };

  handleChange = (value) => {
    const {name, fieldProps} = this.props;

    fieldProps.form.setFieldValue(name, value);
  };

  render() {
    const {component: SvgComponent, fieldProps, disabled, vehicleType} = this.props;

    const className = cx({
      [styles.root]: true,
      [styles.car]: vehicleType === vehicleTypeConstants.CAR,
      [styles.semiTruck]: vehicleType === vehicleTypeConstants.SEMI_TRUCK,
      [styles.motorcycle]: vehicleType === vehicleTypeConstants.MOTORCYCLE,
      [styles.bike]: vehicleType === vehicleTypeConstants.BIKE,
    });

    return (
      <div className={className}>
        <SvgComponent value={fieldProps.field.value || []} onClick={disabled ? null : this.handleChange}/>
      </div>
    );
  }
}
