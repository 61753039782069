import React, {Component} from "react";
import ReactDOM from "react-dom";

import cx from "classnames";

import styles from "components/view/ToggleView.module.scss";

const measureElement = element => {
  const DOMNode = ReactDOM.findDOMNode(element);
  return {
    width: DOMNode.offsetWidth,
    height: DOMNode.offsetHeight,
  };
};

export default class ToggleView extends Component {
  state = {
    width: 0,
    height: 0,
  };

  contentRef = React.createRef();

  componentDidMount() {
    this.setState(measureElement(this.contentRef.current));
  }

  render() {
    const {visible, children} = this.props;

    const className = cx({
      [styles.root]: true,
      [styles.invisible]: !visible,
    });

    const style = visible ? { maxHeight: this.state.height } : {};

    return (
      <div className={className} style={style}>
        <div ref={this.contentRef}>
          {children}
        </div>
      </div>
    );
  }
}
