import React, {Component} from "react";

import styles from "components/icon/PersonFontIcon.module.scss";

export default class PersonFontIcon extends Component {
  render() {
    const {icon} = this.props;

    return (
      <div className={styles.root}>
        {icon}
      </div>
    );
  }
}
