import React from "react";

import {FieldArray} from "formik";
import {MobileView} from "react-device-detect";

import Layout from "components/common/Layout";
import ConditionalField from "components/form/ConditionalField";
import FormErrorMessage from "components/form/FormErrorMessage";
import UploadField from "components/form/UploadField";
import IcoMoon from "components/icon/IcoMoon";
import Documents from "components/step/Documents";
import Photos from "components/step/Photos";
import fontConstants from "constants/font";
import {UploadContext} from "containers/context/UploadContainer"

export default function HomeDocumentsRight({values, saveId}) {
  const {receipts = [], police = [], other = [], photos = []} = values;
  const acceptedTypes = ["doc", "docx", "pdf", "jpg", "jpeg", "bmp", "tiff", "png", "xlsx", "xls"].map(x => `.${x}`).join(", ");

  return (
    <Layout spacing={10} width="100%" maxWidth={450} justifyContent="center">
      <ConditionalField name="photos">
        <Layout.OneColumn>
          <UploadField name="photos"
                       label="documents.button.photosUpload"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept="image/*, video/mp4, video/quicktime"/>
        </Layout.OneColumn>
        <MobileView>
          <Layout.OneColumn spacing={10} width="100%" maxWidth={450} justifyContent="center">
            <UploadField name="photos"
                         label="documents.button.takePhoto"
                         saveId={saveId}
                         icon={<IcoMoon icon={fontConstants.ICOMOON.CAMERA}/>}
                         accept="image/*, video/mp4, video/quicktime"/>
          </Layout.OneColumn>
        </MobileView>
        <Layout.OneColumn>
          <FieldArray name="photos" render={arrayHelpers => (
            <Photos arrayHelpers={arrayHelpers}
                    photos={photos}
                    group="homePhotosGroup"/>
          )}/>
        </Layout.OneColumn>
      </ConditionalField>
      <ConditionalField name="documents">
        <Layout.OneColumn>
          <UploadField name="receipts"
                       label="homeDocuments.button.uploadReceipts"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="receipts" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={receipts}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="police"
                       label="homeDocuments.button.uploadPolice"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="police" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={police}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="other"
                       label="homeDocuments.button.uploadOther"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="other" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={other}/>
          )}/>
        </Layout.OneColumn>
      </ConditionalField>
      <UploadContext.Consumer>
        {context => (<FormErrorMessage error={context.error} showError={context.error.length > 0}/>)}
      </UploadContext.Consumer>
      <Layout.OneColumn spacing={10}></Layout.OneColumn>
    </Layout>
  );
}