import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import ParticipantsNumberPicker from "containers/step/participantsNumber/ParticipantsNumberPicker";

export default class ParticipantsNumberRight extends Component {
  state = {
    nextEnabled: true,
  };

  handleChange = (value, callback) => {
    if (value !== booleanTypesConstants.YES && this.state.nextEnabled) {
    const {onNextStep} = this.props;

      this.setState({
        nextEnabled: false,
      });

      onNextStep();
    } else {
      callback();
    }
  };

  render() {
    const {values, onNextStep} = this.props;

    return (
      <Layout spacing={10}>
        <Layout.OneColumn flex={1}>
          <NextStepButton component={ToggleButton}
                          isValid={true}
                          onNextStep={onNextStep}
                          condition={value => value !== booleanTypesConstants.YES}
                          buttonProps={{name: "multipleParticipants"}}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
              <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
              <ToggleButton.Choice value={booleanTypesConstants.DONT_KNOW} label="button.dontKnow"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>

        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={values.multipleParticipants}>
            {values.multipleParticipants === booleanTypesConstants.YES ? <ParticipantsNumberPicker {...this.props} /> : null}
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
