import React from "react";

import {isEmpty} from "underscore";

import Text from "components/common/Text";
import SummaryRefContainer from "components/step/summary/SummaryRefContainer";
import SummaryTable from "components/step/summary/SummaryTable";
import flowConstants from "constants/flow";
import homeDamagedGoodsInfoTypeConstants from "constants/homeDamagedGoodsInfoTypes";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

import styles from "containers/step/summary/home/SummaryDamagedGoodsInfo.module.scss";

export default function SummaryDamagedGoodsInfo() {
  return (
    <Summary title="summary.content.homeDamagedGoodsInfo.title"
             onePart={false}
             refName={summaryRefConstants.HOME_DAMAGED_GOODS_INFO.REF}>

      <Summary.ItemValue label="summary.content.homeDamagedGoodsInfo.description.label"
                         refName={summaryRefConstants.HOME_DAMAGED_GOODS_INFO.DESCRIPTION}
                         formName={{name: flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME}}>
        {form => form.description}
      </Summary.ItemValue>

      <Summary.ItemValue label="summary.content.homeDamagedGoodsInfo.damageEstimate.label"
                         refName={summaryRefConstants.HOME_DAMAGED_GOODS_INFO.DAMAGE_ESTIMATE}
                         formName={{name: flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME}}
                         condition={form => form.homeDamagedGoodsInfoType === homeDamagedGoodsInfoTypeConstants.ESTIMATE}>
        {form => form.damageEstimate}
      </Summary.ItemValue>

      <Summary.Items formName={{name: flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME}}
                     condition={form => form.homeDamagedGoodsInfoType === homeDamagedGoodsInfoTypeConstants.LIST}>
        {form =>
          <SummaryRefContainer refName={summaryRefConstants.HOME_DAMAGED_GOODS_INFO.DAMAGED_GOODS_INFO.REF}>
            {form.damagedGoods.map((damagedGood, key) =>
              <SummaryDamagedGoodInfo key={key} damagedGood={damagedGood} index={key}/>)}
          </SummaryRefContainer>}
      </Summary.Items>
    </Summary>
  );
}

function SummaryDamagedGoodInfo({damagedGood, index}) {
  return (
    <SummaryTable.Part
      refName={summaryRefConstants.HOME_DAMAGED_GOODS_INFO.DAMAGED_GOODS_INFO.DAMAGED_GOOD_INFO.REF(index)}>
      <SummaryTable.HeaderContent>
        <Text element="span"
              className={styles.header}
              label="summary.content.homeDamagedGoodsInfo.damagedGood.label"
              parameters={[index + 1]}/>
      </SummaryTable.HeaderContent>
      <Summary.ItemValue label="summary.content.homeDamagedGoodsInfo.damagedGood.name.label"
                         refName={summaryRefConstants.HOME_DAMAGED_GOODS_INFO.DAMAGED_GOODS_INFO.DAMAGED_GOOD_INFO.NAME}
                         formName={{name: flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME}}>
        {form => isEmpty(damagedGood.name) ?
          <Text label="summary.content.common.boolean.dontKnow"/> : damagedGood.name}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.homeDamagedGoodsInfo.damagedGood.purchaseYear.label"
                         refName={summaryRefConstants.HOME_DAMAGED_GOODS_INFO.DAMAGED_GOODS_INFO.DAMAGED_GOOD_INFO.PURCHASE_YEAR}
                         formName={{name: flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME}}>
        {form => isEmpty(damagedGood.purchaseYear) ?
          <Text label="summary.content.common.boolean.dontKnow"/> : damagedGood.purchaseYear}
      </Summary.ItemValue>
      <Summary.ItemValue label="summary.content.homeDamagedGoodsInfo.damagedGood.purchasePrice.label"
                         refName={summaryRefConstants.HOME_DAMAGED_GOODS_INFO.DAMAGED_GOODS_INFO.DAMAGED_GOOD_INFO.PURCHASE_PRICE}
                         formName={{name: flowConstants.homeSteps.HOME_DAMAGED_GOODS_INFO.NAME}}>
        {form => isEmpty(damagedGood.purchasePrice) ?
          <Text label="summary.content.common.boolean.dontKnow"/> : damagedGood.purchasePrice}
      </Summary.ItemValue>
    </SummaryTable.Part>
  );
}
