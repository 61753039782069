import React, {Component} from "react";

import Text from "components/common/Text";
import SummaryHeader from "components/common/SummaryHeader";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import {RegistryContext} from "containers/context/RegistyContainer";
import Header from "containers/step/Header";
import ResponsiblePersonTypeRight from "containers/step/responsiblePersonType/ResponsiblePersonTypeRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";
import {isSloAddress} from "utility/utilityFunctions";

const taxNumberValidationSchema = (slovenia) => Yup.string().when("address", {
  is: address => isSloAddress(address, slovenia),
  then: getMax(Yup.string().validateSloTaxNumber("validation.taxNumber.format"), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].taxNumberSlo),
  otherwise: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].taxNumber),
});

const addressValidationSchema = Yup.object().shape({
  address: Yup.object().shape({
    streetNumber: getMax(Yup.string().typeError("validation.address.streetNo"), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].streetNumber),
    streetName: getMax(Yup.string().typeError("validation.address.streetNo"), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].streetName),
  }),
  city: Yup.object().shape({
    zip: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].zip),
    city: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].city),
  }),
});

const legalPersonValidationSchema = Yup.object().shape({
  name: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].name),
  address: addressValidationSchema,
  policyNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].policyNumber),
});

const naturalPersonValidationSchema = (slovenia) => Yup.object().shape({
  name: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].name),
  surname: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].surname),
  birthDate: Yup.mixed(),
  address: addressValidationSchema,
  policyNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME].policyNumber),
  taxNumber: taxNumberValidationSchema(slovenia),
});

const validationSchema = (slovenia) => Yup.object().shape({
  legalPerson: Yup.object().when("responsiblePersonChoice", {
    is: responsiblePersonTypes.LEGAL_PERSON,
    then: legalPersonValidationSchema,
  }),
  naturalPersons: Yup.array().when("responsiblePersonChoice", {
    is: responsiblePersonTypes.NATURAL_PERSON,
    then: Yup.array().of(naturalPersonValidationSchema(slovenia))
  })
});

export default class ResponsiblePersonTypeStepContainer extends Component {

  left = ({fromSummary, step}) => {

    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="responsiblePersonType.sidebar.title"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <ResponsiblePersonTypeRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <RegistryContext.Consumer>
        {context => <SplitViewContainer left={this.left} right={this.right}
                                        validationSchema={validationSchema(context.registry.slovenia)}/>}
      </RegistryContext.Consumer>
    );
  }
}
