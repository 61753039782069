import React, {Component} from "react";

import Yup, {getMax} from "selectors/yup";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import booleanTypesConstants from "constants/booleanTypes"
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {RegistryContext} from "containers/context/RegistyContainer";
import Header from "containers/step/Header";
import LifeInsuranceClaimRight from "containers/step/lifeInsuranceClaim/LifeInsuranceClaimRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {isSloAddress} from "utility/utilityFunctions";

const taxNumberValidationSchema = (slovenia) => Yup.string().when("address", {
  is: address => isSloAddress(address, slovenia),
  then: getMax(Yup.string().validateSloTaxNumber("validation.taxNumber.format"), fieldLengthConstants[flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME].taxNumberSlo),
  otherwise: getMax(Yup.string(), fieldLengthConstants[flowConstants.personSteps.LIFE_INSURANCE_CLAIM.NAME].taxNumber),
});

const arrayObjectValidationSchema = (slovenia) => Yup.object().shape({
  taxNumber: taxNumberValidationSchema(slovenia),
});

const validationSchema = (slovenia) => Yup.object().shape({
  lifeInsuranceClaim: Yup.string(),
  isZDA: Yup.string().when("lifeInsuranceClaim", {
    is: booleanTypesConstants.YES,
    then: Yup.string().required("validation.required")
  }),
  taxInfos: Yup.array().min(1).of(arrayObjectValidationSchema(slovenia)),
});

export default class LifeInsuranceClaimStepContainer extends Component {

  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="lifeInsuranceClaim.sidebar.title"/>
        <Text element="div" label="lifeInsuranceClaim.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <LifeInsuranceClaimRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <RegistryContext.Consumer>
        {context => <SplitViewContainer left={this.left} right={this.right}
                                        validationSchema={validationSchema(context.registry.slovenia)}/>}
      </RegistryContext.Consumer>
    );
  }
}
