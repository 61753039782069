import React, {Component} from "react";

import * as Yup from "yup";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import accidentDateTypesConstants from "constants/accidentDateTypes";
import claimTypesConstants from "constants/claimTypes";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import AccidentDateRight from "containers/step/accidentDate/AccidentDateRight";

const validationSchema = Yup.object().shape({
  accidentDateFrom:
    Yup.mixed()
      .when("accidentDateType", {
        is: accidentDateTypesConstants.RANGE,
        then: Yup.date().required("validation.required")
      }),
  accidentDateTo:
    Yup.mixed()
      .when("accidentDateType", {
        is: accidentDateTypesConstants.RANGE,
        then: Yup.date().required("validation.required")
      }),
  accidentDate:
    Yup.mixed()
      .when("accidentDateType", {
        is: accidentDateTypesConstants.CALENDAR,
        then: Yup.date().required("validation.required")
      }),
});

// step 6
export default class AccidentDateStepContainer extends Component {
  left = ({claimType, step, fromSummary}) => {
    const label = claimType !== claimTypesConstants.PERSON ? `${claimType}` : `${claimType}.${step.medicalCareTypeDescription || step.medicalCareType}`;

    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label={`accidentDate.sidebar.${label}.title`}/></div>
        <div><Text element="div" label={`accidentDate.sidebar.${label}.subtitle`}/></div>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <AccidentDateRight {...props} />
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
