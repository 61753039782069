import React, {Component} from "react";

import cx from "classnames";
import {isArray, isFunction, mapObject} from "underscore";

import {ConfigContext} from "containers/context/ConfigContainer";
import flowConstants from "constants/flow";
import Translation from "components/common/Translation";

import styles from "components/common/Text.module.scss";

export default class Text extends Component {
  static contextType = ConfigContext;
  renderTranslation = ({isTranslated, value}) => {
    const {element, className, isHtml = false, variant, componentProps = {}, ...props} = this.props;

    const company = this.context.company === flowConstants.companies.GENERALI ? '' : this.context.company;
    const isWiz = company === flowConstants.companies.WIZ;

    const resultClassName = cx({
      [styles.missing]: !isTranslated,
      [styles.root]: true,
      [className]: true,
      [styles.variant1]: variant === "variant1",
      [styles.variant2]: variant === "variant2",
      [styles.variant3]: variant === "variant3",
      [styles.variant4]: variant === "variant4",
      [styles.variant5]: variant === "variant5",
      [styles.variant6]: variant === "variant6",
      [styles.variant7]: variant === "variant7",
      [styles.variant8]: variant === "variant8",
      [styles.variant9]: variant === "variant9",
      [styles.variant10]: variant === "variant10",
      [styles.variant11]: variant === "variant11",
      [styles.variant12]: variant === "variant12",
      [styles.variant13]: variant === "variant13",
      [styles.variant14]: variant === "variant14",
      [styles.variant15]: variant === "variant15",
      [styles.variant16]: variant === "variant16",
      [styles.variant17]: variant === "variant17",
      [styles.variant18]: variant === "variant18",
      [styles.variant19]: variant === "variant19",
      [styles.variant20]: variant === "variant20",
      [styles.variant21]: variant === "variant21",
      [styles.variant22]: variant === "variant22",
      [styles.variant23]: variant === "variant23",
      [styles.variant24]: variant === "variant24",
      [styles.variant25]: variant === "variant25",
      [styles.variant26]: variant === "variant26",
      [styles.variant27]: variant === "variant27",
      [styles.variant28]: variant === "variant28",
      [styles.variant29]: variant === "variant29",
      [styles.variant30]: variant === "variant30",
      [styles.variant31]: variant === "variant31",
      [styles.variant32]: variant === "variant32",
      [styles.variant33]: variant === "variant33",
      [styles.variant34]: variant === "variant34",
      [styles.variant35]: variant === "variant35",
      [styles.variant36]: variant === "variant36",
      [styles.wiz]: isWiz,
      [styles.checkboxInverted]: variant === "checkboxInverted",
    });

    const finalComponentProps = mapObject(componentProps, val => isFunction(val) ? val(value) : val);

    const resultElement = element ? React.createElement(element) : (isTranslated ? <></> : <span/>);

    let additionalProps = {};
    if (isHtml && isArray(value)) {
      const valueHtml = value.map((child, index) => {
        if (React.isValidElement(child)) {
          return child;
        }

        const childProps = {key: index, dangerouslySetInnerHTML: {__html: child}};
        return element ? React.createElement(element, childProps) : (isTranslated ? <></> : <span {...childProps}/>);
      });
      additionalProps = {children: valueHtml};
    } else if (isHtml) {
      additionalProps = {dangerouslySetInnerHTML: {__html: value}};
    } else {
      additionalProps = {children: value};
    }

    return React.cloneElement(resultElement, {...props, ...additionalProps, ...finalComponentProps, className: resultClassName});
  };

  render() {
    const { className, label, children, variant, parameters} = this.props;

    if (label) {
      return (
        <Translation label={label} render={this.renderTranslation} parameters={parameters}/>
      );
    }

    const resultClassName = cx({
      [styles.root]: true,
      [className]: true,
      [styles.variant1]: variant === "variant1",
      [styles.variant2]: variant === "variant2",
      [styles.variant3]: variant === "variant3",
      [styles.variant4]: variant === "variant4",
      [styles.variant5]: variant === "variant5",
      [styles.variant6]: variant === "variant6",
      [styles.variant7]: variant === "variant7",
      [styles.variant8]: variant === "variant8",
      [styles.variant9]: variant === "variant9",
      [styles.variant10]: variant === "variant10",
      [styles.variant11]: variant === "variant11",
      [styles.variant12]: variant === "variant12",
      [styles.variant13]: variant === "variant13",
      [styles.variant14]: variant === "variant14",
      [styles.variant15]: variant === "variant15",
      [styles.variant16]: variant === "variant16",
      [styles.variant17]: variant === "variant17",
      [styles.variant18]: variant === "variant18",
      [styles.variant19]: variant === "variant19",
      [styles.variant20]: variant === "variant20",
      [styles.variant21]: variant === "variant21",
      [styles.variant22]: variant === "variant22",
      [styles.variant23]: variant === "variant23",
      [styles.variant24]: variant === "variant24",
      [styles.variant25]: variant === "variant25",
      [styles.variant26]: variant === "variant26",
      [styles.variant27]: variant === "variant27",
      [styles.variant28]: variant === "variant28",
      [styles.variant29]: variant === "variant29",
      [styles.variant30]: variant === "variant30",
      [styles.variant31]: variant === "variant31",
      [styles.variant32]: variant === "variant32",
      [styles.variant33]: variant === "variant33",
      [styles.variant34]: variant === "variant34",
      [styles.variant35]: variant === "variant35",
      [styles.variant36]: variant === "variant36",
    });

    return (
      <span className={resultClassName}>
        {children}
      </span>
    );
  }
}
