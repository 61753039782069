import React, {Component} from "react";

import {connect} from "react-redux";

import {loadScreenConfiguration} from "actions/flow";
import Loading from "components/common/Loading";
import {ConfigContext} from "containers/context/ConfigContainer";

export const ScreenConfigurationContext = React.createContext();

class ScreenConfigurationContainer extends Component {
  static contextType = ConfigContext;

  componentDidMount() {
    const {loadScreenConfiguration} = this.props;

    loadScreenConfiguration(this.context.url.screenConfiguration);
  }

  render() {
    const {configuration, children} = this.props;

    if (!configuration.loaded) {
      return <Loading />;
    }

    return (
      <ScreenConfigurationContext.Provider value={configuration}>
        {children}
      </ScreenConfigurationContext.Provider>
    );
  }
}

const mapStateToProps = (state) => ({
  configuration: state.flow.configuration,
});

const mapDispatchToProps = (dispatch) => ({
  loadScreenConfiguration: (url) => dispatch(loadScreenConfiguration(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenConfigurationContainer);
