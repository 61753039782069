import React, {Component} from "react";

import Autosuggest from "react-autosuggest";
import {Field} from "formik";
import {isEmpty, isUndefined} from "underscore";
import _ from "lodash";

import CompositeField from "components/form/CompositeField";
import {InnerTextField} from "components/form/TextField";
import isUndefinedOrNull from "utility/utilityFunctions";

import styles from "components/form/AutoCompleteField.module.scss";

export default function AutoCompleteField(props) {
  const {name} = props;
  return (
    <Field
      name={name}
      render={(fieldProps) => (
        <CompositeField {...props}>
          {context => (
            <InnerAutoCompleteField {...fieldProps.field} form={fieldProps.form} {...props} context={context}/>
          )}
        </CompositeField>
      )}
    />
  );
}

export class InnerAutoCompleteField extends Component {
  static defaultProps = {
    getSuggestionValue: value => value,
  };

  state = {
    suggestions: [],
    search: undefined,
  };

  renderInputComponent = ({ref, onBlur, ...inputProps}) => {
    const {variant, maxLength, context} = this.props;

    return (
      <InnerTextField {...inputProps}
                      handleBlur={onBlur}
                      inputRef={ref}
                      variant={variant}
                      maxLength={maxLength}
                      context={context}/>
    );
  };

  setFieldValue(suggestion) {
    const {form, name, onSuggestionSelected, getSuggestionValue} = this.props;

    form.setFieldValue(name, getSuggestionValue(suggestion));

    if (onSuggestionSelected) {
      onSuggestionSelected(suggestion);
    }
  }

  onSuggestionSelected = (e, {suggestion}) => {
    const {context, name} = this.props;
    this.setFieldValue(suggestion);
    context.label.handleBlur(suggestion, name);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionsFetchRequested = async ({value}) => {
    const {fetchSuggestions} = this.props;

    const suggestions = await fetchSuggestions(value);
    this.setState({
      suggestions: suggestions || [],
    });
  };

  renderSuggestion = (suggestion) => {
    return (
      <div>
        {this.renderSuggestionValue(suggestion)}
      </div>
    );
  };

  renderSuggestionValue = (suggestion) => {
    const {renderSuggestionValue, getSuggestionValue} = this.props;

    if (!suggestion) {
      return "";
    }

    return renderSuggestionValue ? renderSuggestionValue(suggestion) : getSuggestionValue(suggestion);
  };

  renderValue = (suggestion) => {
    const {renderValue} = this.props;

    if (!suggestion) {
      return "";
    }

    return renderValue ? renderValue(suggestion) : suggestion;
  };

  onBlur = (event, meta) => {
    const {onSuggestionAborted, context, form, name, value} = this.props;
    const {search} = this.state;

    if (isUndefinedOrNull(meta.highlightedSuggestion) && (isUndefinedOrNull(value) || (!isEmpty(search) && this.renderValue(value) !== search))) {
      if (!isUndefinedOrNull(onSuggestionAborted)) {
        this.setFieldValue(onSuggestionAborted(search));
      }

      context.label.handleBlur(null, name);
    }

    form.setTouched(_.set(_.cloneDeep(form.touched), name, true));

    this.setState({
      search: undefined,
    });
  };

  onChange = (e, {newValue, method}) => {
    const {typed} = this.state;

    let newSearch = undefined, newTyped = typed;

    if (method === "down" || method === "up" || method === "enter") {
      newSearch = newValue === typed ? typed : this.renderSuggestionValue(newValue);
    } else {
      newSearch = newValue;
      newTyped = newValue;

      if (newValue === "") {
        this.setFieldValue(null);
      }
    }

    this.setState({
      search: newSearch,
      typed: newTyped,
    });
  };

  render() {
    const {placeholder, isRequired, getSuggestionValue, disabled = false, transform} = this.props;
    const shouldTransform = !isUndefined(transform);
    const search = shouldTransform && !isUndefined(this.state.search) ? transform(this.state.search) : this.state.search;
    const value = shouldTransform && !isUndefined(this.props.value) ? transform(this.props.value) : this.props.value;

    const inputProps = {
      placeholder,
      isRequired,
      disabled,
      onChange: this.onChange,
      onBlur: this.onBlur,
      value: (search || this.renderValue(value)) || "",
      autocomplete: "new-password",
    };

    return (
      <Autosuggest
        selectOnTab={true}
        inputProps={inputProps}
        getSuggestionValue={getSuggestionValue}
        suggestions={this.state.suggestions}
        focusInputOnSuggestionClick={false}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
        renderInputComponent={this.renderInputComponent}
        theme={styles}/>
    );
  }
}
