import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import booleanTypeConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import PastMedicalConditionsRight from "containers/step/pastMedicalConditions/PastMedicalConditionsRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup"

const validationSchema = Yup.object().shape({
  description: Yup.string().when("pastMedicalConditions", {
    is: booleanTypeConstants.YES,
    then: getMax(Yup.string().trim(), fieldLengthConstants[flowConstants.personSteps.PAST_MEDICAL_CONDITIONS.NAME].description),
  }),
});

export default class PastMedicalConditionsStepContainer extends Component {

  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <div><Text element="h2" label="pastMedicalConditions.sidebar.title"/></div>
        <div><Text element="div" label="pastMedicalConditions.sidebar.subtitle"/></div>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <PastMedicalConditionsRight {...props}/>
      </SplitViewContainer.Right>
    );
  };


  render() {
    return (<SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>)
  }
}
