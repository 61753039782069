import React, {Component} from "react";

import Layout from "components/common/Layout";
import Link from "components/common/Link";
import Country from "components/step/Country";
import TaxNumber from "components/step/TaxNumber";
import {RegistryContext} from "containers/context/RegistyContainer";
import isUndefinedOrNull, {isSloAddress} from "utility/utilityFunctions";

export default function TaxInfo(props) {
  return (
    <RegistryContext.Consumer>
      {context => (
        <InnerTaxInfo {...props} slovenia={context.registry.slovenia}/>
      )}
    </RegistryContext.Consumer>
  );
}

class InnerTaxInfo extends Component {
  setCountry = (value) => {
    const {index, setFieldValue} = this.props;
    setFieldValue(`taxInfos[${index}].address.country`, value);
  }

  render() {
    const {index, onRemove, showRemove, slovenia, taxInfo} = this.props;

    const address = isUndefinedOrNull(taxInfo) ? null : taxInfo.address;

    return (
      <Layout width="100%" spacing={10} maxWidth={450}>
        <Layout.OneColumn>
          <Country name={`taxInfos[${index}].addressName`} setCountry={this.setCountry} />
          <TaxNumber name={`taxInfos[${index}].taxNumber`}
                     placeholder="lifeInsuranceClaim.content.taxNumber.placeholder"
                     label="lifeInsuranceClaim.content.taxNumber.label"
                     isSlovenia={isSloAddress(address, slovenia)}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          {showRemove ? <Link label="lifeInsuranceClaim.content.taxInfo.button.remove" onClick={onRemove}/> : null}
        </Layout.OneColumn>
        <Layout.OneColumn spacing={20}/>
      </Layout>
    );
  }
}
