import React, {Component} from "react";
import {ConfigContext} from "containers/context/ConfigContainer"
import axios from "axios";
import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Text from "components/common/Text";
import styles from "containers/Login.module.scss";
import isUndefinedOrNull from "utility/utilityFunctions";

export default class ErrorBoundary extends Component {
    static contextType = ConfigContext;
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        try {
            const logData  = {
                "error" : isUndefinedOrNull(error) ? "" : error.message,
                "errorInfo" : isUndefinedOrNull(error) ? "" : (isUndefinedOrNull(error.stack) ? "" : error.stack.replace(/(?:\r\n|\r|\n)/g, '<br>'))
            }
            axios.post(this.context.url.log, logData);
        }
        catch (e) {
            // do nothing
        }
    }

    redirectToBeginning = async () => {
        document.body.onbeforeunload = "return false";
        window.location.replace(window.location.origin);
    };

    render() {

        if (this.state.hasError) {
            return (
                <div className={styles.root}>
                    <div className={styles.inner}>
                        <Layout width="100%" maxWidth={450} spacing={5}>
                            <Layout.OneColumn>
                                <Text element="p" label="error.title" variant="variant36"/>
                            </Layout.OneColumn>
                            <Layout.OneColumn spacing={10}/>
                            <Layout.OneColumn>
                                <Text element="div" label="error.subtitle"/>
                            </Layout.OneColumn>
                            <Layout.OneColumn spacing={20}/>
                            <Layout.OneColumn textAlign="right">
                                <Button label="error.button" onClick={this.redirectToBeginning}/>
                            </Layout.OneColumn>
                        </Layout>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}