import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "constants/booleanTypes";
import personResponsibleTypes from "constants/personResponsibleTypes";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";

export default class PersonResponsibleStepContainer extends Component {

  left = ({fromSummary, step}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label={`personResponsible.sidebar.${step.personResponsibleType}.title`}/>
        <Text element="div" label={`personResponsible.sidebar.${step.personResponsibleType}.subtitle`}/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    const {onNextStep, isValid, step} = props;
    const {personResponsibleType} = step;

    return (
      <SplitViewContainer.Right {...props}>
        <Layout>
          <Layout.OneColumn spacing={30}></Layout.OneColumn>
          <NextStepButton component={ToggleButton}
                          buttonProps={{name: "isOtherPersonFault"}}
                          onNextStep={onNextStep}
                          isValid={isValid}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={booleanTypesConstants.YES}
                                   label="button.yes"/>
              <ToggleButton.Choice value={booleanTypesConstants.NO}
                                   label="button.no"/>
              {personResponsibleType === personResponsibleTypes.INJURY ?
                <ToggleButton.Choice value={booleanTypesConstants.DONT_KNOW}
                                     label="button.dontKnow"/> : null}
            </Layout.OneRow>
          </NextStepButton>
        </Layout>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
