import React, { useContext } from "react";

import flowConstants from "constants/flow";
import { ConfigContext } from "../../context/ConfigContainer";
import Button from "components/common/Button";
import Text from "components/common/Text";
import { I18nContext } from "containers/context/I18nContainer";
import IntroStepRight from "containers/step/intro/IntroStepRight";
import SplitViewContainer from "../../../containers/view/SplitViewContainer";

import { isIE } from "react-device-detect";

import styles from "containers/step/intro/IntroStepContainer.module.scss";
import IEScreen from "../../IEScreen";

const introImageGenerali = require("images/intro.svg");
const introImageWiz = require("images/intro-wiz.svg");

// step 1
export default function IntroStepContainer() {
  const config = useContext(ConfigContext);

  const left = (props, company) => {
    if (isIE) {
      return <IEScreen />;
    }
    return (
      <SplitViewContainer.Left>
        <Text element="h2" label={`intro.sidebar.title.${company}`} className={styles.introTitle} />
        <Text element="div" label={`intro.sidebar.subtitle.${company}`} variant="variant23" isHtml={true} />
        <Button onClick={props.onNextStep} label="button.next" variant="variant1" />
      </SplitViewContainer.Left>
    );
  };

  const right = (props, company) => {
    const isWiz = company === flowConstants.companies.WIZ;

    const introImage = company === flowConstants.companies.WIZ ? introImageWiz : introImageGenerali;

    const bgColor = company === flowConstants.companies.WIZ ? "#27bdbe" : "#c21b17";

    return (
      <SplitViewContainer.Right background={introImage} showNavigation={false}>
        <I18nContext.Consumer>{(i18nContext) => <IntroStepRight i18nContext={i18nContext} company={company} />}</I18nContext.Consumer>
      </SplitViewContainer.Right>
    );
  };

  const companyName = config.company;
  const company = companyName === flowConstants.companies.GENERALI ? "" : config.company;

  return (
    <div className={styles[company]}>
      <SplitViewContainer left={left} right={right} reverseFlex={true} company={companyName} />
    </div>
  );
}
