import React, {Component} from "react";

import axios from "axios";
import {findIndex} from "underscore";

import DocumentsIcon from "components/icon/DocumentsIcon";
import {ConfigContext} from "containers/context/ConfigContainer";

export default class Documents extends Component {
  static contextType = ConfigContext;

  onRemoveDocument = (id) => () => {
    const {arrayHelpers, documents} = this.props;

    const index = findIndex(documents, document => document.id === id);

    axios.post(this.context.url.delete, {params: [id]});

    arrayHelpers.remove(index);
  };

  onDownloadDocument = (id) => () => {
    window.open(this.context.url.downloadFile(id));
  };

  render() {
    const {documents = []} = this.props;

    return (
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
        {documents.map((file, i) => (
            <DocumentsIcon key={i}
                           {...file}
                           onDocumentClick={this.onDownloadDocument}
                           onButtonClick={this.onRemoveDocument}/>
          )
        )}
      </div>
    );
  }
}