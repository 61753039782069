import React, {Component} from "react";

import {find, isEqual} from "underscore";

import flowConstants from "constants/flow";
import SlideView from "components/view/SlideView";

import styles from "components/view/StepView.module.scss";

class StepView extends Component {
  render() {
    const {direction, children, ...props} = this.props;

    const child = find(
      React.Children.toArray(children),
      (child) => isEqual(props.step, {...props.step, ...child.props.step}),
    );

    if (!child) {
      return null;
    }

    return (
      <div className={styles.root}>
        <SlideView step={{...props.step, ...child.props.step}}
                   direction={direction === flowConstants.direction.FORWARD ? "topBottom" : "bottomTop"}>
          {React.cloneElement(child, props)}
        </SlideView>
      </div>
    );
  }
}

function StepViewSingle({component, children, ...props}) {
  const child = component ? React.createElement(component, props) : React.Children.map(children, x => React.cloneElement(x, props), this);
  return <div className={styles.single}>{child}</div>
}

StepView.Single = StepViewSingle;

export default StepView;
