import React from "react";

import cx from "classnames";

import styles from "components/common/Box.module.scss";

export default function Box({icon, children, variant}) {

  const className = cx({
    [styles.root]: true,
    [styles.variant1]: variant === "variant1",
  });

  return (
    <div className={className}>
      <div className={styles.icon}>{icon}</div>
      {children}
    </div>
  );
}
