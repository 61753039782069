import {find, isEmpty, negate} from "underscore";
import _ from "lodash";
import * as dateFns from "date-fns";

import sexTypeConstants from "constants/sex";
import summaryConstants from "constants/summary";
import isUndefinedOrNull from "utility/utilityFunctions";

export default function filterParticipantsInfo(participantsNumber, participantsInfo, accidentInvolvedForm) {
  const result = [];

  participantsInfo
    .filter(negate(isUndefinedOrNull))
    .filter(participantInfo => {
      const isOwnVehicle = participantInfo.info.isOwnVehicle;
      const inNumber = find(
        participantsNumber,
        participantNumber => participantNumber.index === participantInfo.info.index,
      );

      return isOwnVehicle || inNumber;
    })
    .forEach(participantInfo => {
      const inNumber = find(
        participantsNumber,
        participantNumber => participantNumber.index === participantInfo.info.index,
      );

      result[participantInfo.info.index] = {
        ...participantInfo,
        info: accidentInvolvedForm && participantInfo.info.index === 0
          ? {
            color: "#d36845",
            index: 0,
            isOwnVehicle: true,
            isVehicle: true,
            name: "A",
            type: accidentInvolvedForm.involvementType
          }
          : inNumber,
      };
    });

  return result;
}

export function formatParticipantsData(participantInfo) {
  const birthDateRaw = _.at(participantInfo, "birthDate");

  const dontKnowString = "Ni znano";
  const maleString = "Moški, ";
  const femaleString = "Ženski, ";

  const nameAndSurname = formatParticipantNameAndSurname(participantInfo);
  const sex = participantInfo.sex === sexTypeConstants.MALE ? maleString : femaleString;
  let birthDate = birthDateRaw[0] ? dateFns.format(new Date(birthDateRaw[0]), summaryConstants.DATE_FORMAT) : "";
  const address = formatParticipantAddress(participantInfo);

  if (!isEmpty(birthDate) && !isEmpty(address)) {
    birthDate = `${birthDate}, `;
  } else {
    birthDate = `${dontKnowString}, `;
  }

  return nameAndSurname + sex + birthDate + address;
}

export function formatParticipantNameAndSurname (participantInfo) {
  const name = _.at(participantInfo, "name");
  const surname = _.at(participantInfo, "surname");

  const hasName = name.length > 0 && !isEmpty(name[0]);
  const hasSurname = surname.length > 0 && !isEmpty(surname[0]);

  const dontKnowString = "Ni znano";

  let nameAndSurname = "";

  //nameAndSurname
  if (hasName) {
    nameAndSurname = `${name[0]}`;
  } else {
    nameAndSurname = dontKnowString;
  }

  if (hasSurname) {
    if (isEmpty(nameAndSurname)) {
      nameAndSurname = `${surname[0]}`;
    } else {
      nameAndSurname = `${nameAndSurname} ${surname[0]}`;
    }
  } else {
    nameAndSurname = `${nameAndSurname} ${dontKnowString}`;
  }

  return `${nameAndSurname}, `;
}

export function formatParticipantAddress(participantInfo) {
  const street = _.at(participantInfo, "address.address.streetName");
  const streetNo = _.at(participantInfo, "address.address.streetNumber");
  const city = _.at(participantInfo, "address.city.city");
  const zip = _.at(participantInfo, "address.city.zip");
  const countryName = _.at(participantInfo, "address.country.name");

  const hasStreet = street.length > 0 && !isEmpty(street[0]);
  const hasStreetNo = streetNo.length > 0 && !isEmpty(streetNo[0]);
  const hasCity = city.length > 0 && !isEmpty(city[0]);
  const hasZip = zip.length > 0 && !isEmpty(zip[0]);
  const hasCountry = countryName.length > 0 && !isEmpty(countryName[0]);

  const dontKnowString = "Ni znano";

  let streetAndNumber = "";
  let cityAndZip = "";
  let country = "";


  //streetAndNumber
  if (hasStreet) {
    streetAndNumber = street[0];
  } else {
    streetAndNumber = dontKnowString;
  }

  if (hasStreetNo) {
    if (isEmpty(streetAndNumber)) {
      streetAndNumber = streetNo[0];
    } else {
      streetAndNumber = `${streetAndNumber} ${streetNo[0]}`;
    }
  } else {
    streetAndNumber = `${streetAndNumber} ${dontKnowString}`;
  }

  //cityAndZip
  if (hasCity) {
    cityAndZip = city[0];
  } else {
    cityAndZip = dontKnowString;
  }

  if (hasZip) {
    if (isEmpty(cityAndZip)) {
      cityAndZip = zip[0];
    } else {
      cityAndZip = `${cityAndZip} ${zip[0]}`;
    }
  } else {
    cityAndZip = `${cityAndZip} ${dontKnowString}`;
  }


  //country
  if (hasCountry) {
    country = countryName[0]
  } else {
    country = dontKnowString;
  }


  if (!isEmpty(cityAndZip) && !isEmpty(country)) {
    cityAndZip = `${cityAndZip}, `
  }

  if (!isEmpty(streetAndNumber) && (!isEmpty(cityAndZip) || !isEmpty(country))) {
    streetAndNumber = `${streetAndNumber}, `;
  }

  return streetAndNumber + cityAndZip + country;
}
