import React from "react";

import DocumentsIcon from "components/icon/DocumentsIcon";
import SummaryTable from "components/step/summary/SummaryTable";
import booleanTypes from "constants/booleanTypes";
import claimTypesConstants from "constants/claimTypes";
import flowConstants from "constants/flow";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryDocuments({claimType}) {
  return (
    <Summary title={`summary.content.documents.${claimType}.title`} onePart={true}
             refName={summaryRefConstants.DOCUMENTS}>
      <Summary.Items condition={form => form.choice !== booleanTypes.DONT_KNOW}
                     formName={{
                       name: claimType === claimTypesConstants.CAR
                         ? flowConstants.steps.DOCUMENTS.NAME
                         : claimType === claimTypesConstants.HOME ? flowConstants.homeSteps.HOME_DOCUMENTS.NAME
                           : flowConstants.personSteps.PERSON_DOCUMENTS.NAME
                     }}>
        {form => (
          <SummaryTable.ItemContent jumpTo={form.jumpTo}>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
              {form.documents.map((document, index) =>
                <DocumentsIcon key={index} id={document.id} type={document.type} name={document.name}/>
              )}
            </div>
          </SummaryTable.ItemContent>
        )}
      </Summary.Items>
    </Summary>
  );
}
