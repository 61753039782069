import React, { Component } from "react";

import {GoogleApiWrapper} from "google-maps-react";

import {ConfigContext} from "containers/context/ConfigContainer";

export const MapContext = React.createContext({});

export default class MapContainer extends Component {
  static contextType = ConfigContext;

  render() {
    const {children} = this.props;

    return (
      <WrappedMapProvider apiKey={this.context.keys.googleMap}>
        {children}
      </WrappedMapProvider>
    );
  }
}

function MapProvider({google, children}) {
  return(
    <MapContext.Provider value={google}>
      {children}
    </MapContext.Provider>
  );
}

const WrappedMapProvider = GoogleApiWrapper(props => ({
  apiKey: props.apiKey,
  language: "sl",
}))(MapProvider);
