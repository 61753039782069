import React from "react";

import _ from "lodash";

import Text from "components/common/Text";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";
import vehicleInfoInputTypeConstants from "constants/vehicleInfoInputTypes";
import SummaryRefContainer from "components/step/summary/SummaryRefContainer";
import SummaryTable from "components/step/summary/SummaryTable";
import VehicleIconLabel from "components/step/VehicleIconLabel";
import isUndefinedOrNull from "utility/utilityFunctions";

export default function SummaryVehicleInfo(props) {
  return (
    <SummaryTable.Part refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REF}>
      {props.vehicleInfo.inputType === vehicleInfoInputTypeConstants.UNKNOWN || isUndefinedOrNull(props.vehicleInfo.inputType)
        ? null
        : <>
          <SummaryTable.HeaderContent>
            <VehicleIconLabel info={props.info} label="vehicleInfo.label.homeVehicleInfo"/>
          </SummaryTable.HeaderContent>
          <SummaryTable.HeaderText label={`participantsInfo.vehicleInfo.inputType.${props.vehicleInfo.inputType}`}/>
        </>
      }
      <SummaryRefContainer refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.REF}>
        <Summary.ItemValue label="summary.content.vehicleInfo.registrationNumber"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.REGISTRATION_NUMBER}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_IN_SLOVENIA}>
          {form => _.get(form, "vehicleInfo.vehicleData.registrationNumber") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.manufacturer"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.MANUFACTURER}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_IN_SLOVENIA}>
          {form => _.get(form, "vehicleInfo.vehicleData.manufacturer") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.model"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.MODEL}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_IN_SLOVENIA}>
          {form => _.get(form, "vehicleInfo.vehicleData.model") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.insuranceCompany"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.INSURANCE_COMPANY}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_IN_SLOVENIA}>
          {form => _.get(form, "vehicleInfo.vehicleData.insuranceCompany") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.policyNumber"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA.POLICY_NUMBER}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_IN_SLOVENIA}>
          {form => _.get(form, "vehicleInfo.vehicleData.policyNumber") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

      </SummaryRefContainer>

      <SummaryRefContainer refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA_ALTERNATIVE.REF}>
        <Summary.ItemValue label="summary.content.vehicleInfo.registrationNumber"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA_ALTERNATIVE.REGISTRATION_NUMBER}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_IN_SLOVENIA && !isUndefinedOrNull(form.vehicleInfo.vehicleData) && !form.vehicleInfo.vehicleData.success}>
          {form => _.get(form, "vehicleInfo.inSlovenia.registrationNumber") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.vehicleMark"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA_ALTERNATIVE.VEHICLE_MARK}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_IN_SLOVENIA && !isUndefinedOrNull(form.vehicleInfo.vehicleData) && !form.vehicleInfo.vehicleData.success}>
          {form => _.get(form, "vehicleInfo.inSlovenia.vehicleMark") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.vehicleType"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_IN_SLOVENIA_ALTERNATIVE.VEHICLE_TYPE}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_IN_SLOVENIA && !isUndefinedOrNull(form.vehicleInfo.vehicleData) && !form.vehicleInfo.vehicleData.success}>
          {form => _.get(form, "vehicleInfo.inSlovenia.vehicleType") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>
      </SummaryRefContainer>

      <SummaryRefContainer refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_ABROAD.REF}>
        <Summary.ItemValue label="summary.content.vehicleInfo.country"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_ABROAD.COUNTRY}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_ABROAD}>
          {form => _.get(form, "vehicleInfo.abroad.countryName") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.registrationNumber"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_ABROAD.REGISTRATION_NUMBER}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_ABROAD}>
          {form => _.get(form, "vehicleInfo.abroad.registrationNumber") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.vehicleMark"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_ABROAD.VEHICLE_MARK}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_ABROAD}>
          {form => _.get(form, "vehicleInfo.abroad.vehicleMark") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.vehicleType"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_ABROAD.VEHICLE_TYPE}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_ABROAD}>
          {form => _.get(form, "vehicleInfo.abroad.vehicleType") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.insuranceCompany"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.REGISTERED_ABROAD.INSURANCE_COMPANY}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.REGISTERED_ABROAD}>
          {form => _.get(form, "vehicleInfo.abroad.insuranceCompany") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>
      </SummaryRefContainer>

      <SummaryRefContainer refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.COMPANY_VEHICLE.REF}>
        <Summary.ItemValue label="summary.content.vehicleInfo.vehicleChassisNumber"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.COMPANY_VEHICLE.VEHICLE_CHASSIS_NUMBER}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.COMPANY_VEHICLE}>
          {form => _.get(form, "vehicleInfo.company.vehicleChassisNumber") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>

        <Summary.ItemValue label="summary.content.vehicleInfo.vehicleMark"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.COMPANY_VEHICLE.VEHICLE_MARK}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.COMPANY_VEHICLE}>
          {form => _.get(form, "vehicleInfo.company.vehicleMark") ||
            <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>
      </SummaryRefContainer>

      <SummaryRefContainer refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.NOT_REGISTERED.REF}>
        <Summary.ItemValue label="summary.content.vehicleInfo.vehicleDescription"
                           refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.NOT_REGISTERED.DESCRIPTION}
                           formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                           condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.NOT_REGISTERED}>
          {form => _.get(form, "vehicleInfo.notRegistered.vehicleDescription") || <Text label="summary.content.common.boolean.dontKnow"/>}
        </Summary.ItemValue>
      </SummaryRefContainer>

      <Summary.ItemValue label="summary.content.vehicleInfo.unknown"
                         refName={summaryRefConstants.HOME_INFO.HOME_VEHICLE_INFO.UNKNOWN}
                         formName={{name: flowConstants.homeSteps.HOME_VEHICLE_INFO.NAME}}
                         condition={form => form.vehicleInfo.inputType === vehicleInfoInputTypeConstants.UNKNOWN || isUndefinedOrNull(form.vehicleInfo.inputType)}>
        {() => <Text label="summary.content.common.unknownVehicle"/>}
      </Summary.ItemValue>
    </SummaryTable.Part>
  );
}