import React, {Component} from "react";

import Layout from "components/common/Layout";
import LinkField from "components/form/LinkField";
import SvgField from "components/form/SvgField";
import vehicleDamageConstants from "constants/vehicleDamage";

export default class VehicleDamageSelection extends Component {
  onSelectAll = value => {
    const {setFieldValue, values} = this.props;

    setFieldValue(vehicleDamageConstants.FIELD_MAPPING[values.vehicleType], value ? vehicleDamageConstants.COUNT_PARTS_MAPPING[values.vehicleType] : []);
  };

  render() {
    const {selectAll = false, values} = this.props;

    return (
      <Layout justifyContent="center">
        <Layout.OneColumn width="100%" maxWidth={350}>
          <SvgField name={vehicleDamageConstants.FIELD_MAPPING[values.vehicleType]}
                    component={vehicleDamageConstants.COMPONENT_MAPPING[values.vehicleType]}
                    vehicleType={values.vehicleType}/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={30}/>
        {selectAll
        ? <Layout.RowChildren spacing={10}>
            <LinkField name={`${vehicleDamageConstants.FIELD_MAPPING[values.vehicleType]}SelectAll`} onClick={this.onSelectAll}>
              <LinkField.Item value={false}
                              label="vehicleDamageCurrent.content.selectAll"/>
              <LinkField.Item value={true}
                              label="vehicleDamageCurrent.content.selectAll"/>
            </LinkField>
          </Layout.RowChildren>
        : null}
      </Layout>
    );
  }
}