import React, {Component} from "react";

export const UploadContext = React.createContext({});

export default class UploadContainer extends Component {

  startUpload = () => {
    this.setState(state => ({
      uploads: state.uploads + 1,
      uploading: state.uploads + 1> 0,
      error: "",
    }));
  };

  finishUpload = (message) => {
    this.setState(state => ({
      uploads: state.uploads - 1,
      uploading: state.uploads - 1 > 0,
      error: message,
    }));
  };

  state = {
    uploading: false,
    uploads: 0,
    error: "",
    startUpload: this.startUpload,
    finishUpload: this.finishUpload,
  };

  render() {
    const {children} = this.props;

    return (
      <UploadContext.Provider value={this.state}>
        {children}
      </UploadContext.Provider>
    );
  }
}
