import React from "react";

import {isUndefined} from "underscore";

import Text from "components/common/Text";
import ExclamationMark from "components/icon/ExclamationMark";

import styles from "components/form/ErrorMessage.module.scss";

export default function ErrorMessage({error, showError = true}) {
  if (isUndefined(error) || !showError) {
    return null;
  }

  return (
    <div className={styles.root}>
      <ExclamationMark/>
      <Text label={error} />
    </div>
  );
}
