import {createReducer} from "redux-act";
import _ from "lodash";

import {
  closeSaveDialog,
  jumpFromSummaryStep,
  jumpStep,
  loadClaimSuccess,
  loadScreenConfigurationSuccess,
  nextStep,
  openSaveDialog,
  closeSummaryDialog,
  previousStep,
  reloadStep,
  removeParticipants,
  resetAfter,
  setDirty,
  skipParticipant,
  storeMail,
  storeSaveId,
  storeUserId,
  storeSaveVersion,
  storeElectronicSignatureEnabled,
  storeCompany,
  openLogOut,
  closeLogOut,
  storeSubmitId,
  updateFormState,
  storeCounterUser,
  startSubmitClaim,
  removeDamagedGood,
  loadUserInfoSuccess,
  startLoadingUserData,
  storeUserData,
  storeUserDataMobileApp,
  storeCascoData,
  startLoadingCascoData,
} from "actions/flow";
import flowConstants from "constants/flow";
import {
  calculateFormState,
  getNextStep,
  getPreviousStep,
  getStoreKey,
  getSummaryActiveStepsSnapshot,
  prepareFormState,
  removeSkippedParticipants,
  addSkipParticipant,
  removeSelectedGood,
  storeLoadedUserData,
  storeLoadedUserDataMobileApp,
  storeLoadedCascoData,
} from "selectors/flow";
import {getSteps} from "selectors/flow/getSteps";

export const initialState = {
  direction: flowConstants.direction.FORWARD,
  configuration: {
    loaded: false,
    data: [],
  },
  step: {
    name: flowConstants.steps.INTRO.NAME,
  },
  forms: {},
  finishedSteps: [],
  activeStepsSnapshot: [],
  fromSummaryActiveStepsSnapshot: [],
  isDirty: false,
  jumpedFromSummary: false,
  save: {},
  submit: {},
  dirtySteps: [],
  mobileUserId: window.asSkodeConfig ? window.asSkodeConfig.mobileAppUserId : null
};

const commonJumpChange = (state, nextStep) => ({
  isDirty: false,
  jumpedFromSummary: false,

  // reset summary data after reaching summary
  inSummary: nextStep.name === flowConstants.steps.SUMMARY.NAME ? false : state.inSummary,
  fromSummaryActiveStepsSnapshot: nextStep.name === flowConstants.steps.SUMMARY.NAME ? [] : state.fromSummaryActiveStepsSnapshot,
});

export default createReducer(
  {
    [nextStep]: (state) => {
      const nextStep = getNextStep(prepareFormState(state));

      const result = {
        ...state,
        ...commonJumpChange(state, nextStep),
        step: nextStep,
        dirtySteps: state.dirtySteps.filter(form => form.name !== state.step.name),
        direction: flowConstants.direction.FORWARD,
        ...calculateFormState(state, nextStep, true),
      };

      return {
        ...result,
        activeStepsSnapshot: getSteps(result),
      };
    },
    [previousStep]: (state) => {
      const previousStep = getPreviousStep(prepareFormState(state));

      let newDirtySteps = [...(state.dirtySteps || [])];

      if (state.isDirty) {
        newDirtySteps = [...newDirtySteps, state.step];
      }

      const result = {
        ...state,
        ...commonJumpChange(state, previousStep),
        step: previousStep,
        dirtySteps: [...newDirtySteps],
        direction: flowConstants.direction.BACKWARD,
        ...calculateFormState(state, previousStep),
      };

      return {
        ...result,
        activeStepsSnapshot: getSteps(result),
      };
    },
    [reloadStep]: (state) => {
      const result = {
        ...state,
        ...calculateFormState(state, state.step),
      };

      return {
        ...result,
        activeStepsSnapshot: getSteps(result),
      };
    },
    [jumpStep]: (state, payload) => {
      const result = {
        ...state,
        ...commonJumpChange(state, payload),
        step: payload,
        ...calculateFormState(state, payload),
      };

      return {
        ...result,
        activeStepsSnapshot: getSteps(result),
      };
    },
    [jumpFromSummaryStep]: (state, payload) => {
      const {step, refName} = payload;
      const result = {
        ...state,
        ...commonJumpChange(state, step),
        step,
        ...calculateFormState(state, step),
        inSummary: true,
        jumpedFromSummary: true,
        fromSummaryActiveStepsSnapshot: getSummaryActiveStepsSnapshot(state, step),
      };

      return {
        ...result,
        activeStepsSnapshot: getSteps(result),
        jumpFromSummaryRef: refName,
      };
    },
    [resetAfter]: (state) => ({
      ...state,
    }),
    [setDirty]: (state) => ({
      ...state,
      isDirty: true,
    }),
    [updateFormState]: (state, payload) => ({
      ...state,
      forms: _.set(_.cloneDeep(state.forms), getStoreKey(payload.step), payload.values),
    }),
    [loadClaimSuccess]: (state, payload) => {
      const intermediateState = {
        ...state,
        claimLoaded: true,
        ...payload,
      };

      return {
        ...intermediateState,
        ...commonJumpChange(intermediateState, intermediateState.step),
        ...calculateFormState(intermediateState, intermediateState.step),
      };
    },
    [loadUserInfoSuccess]: (state, payload) => {
      return {
        ...state,
        forms: {
          ...state.forms,
          [flowConstants.steps.USER_IDENTIFICATION.NAME]: {
            ...payload
          }
        },
        userLoaded: true,
      };
    },
    [loadScreenConfigurationSuccess]: (state, payload) => ({
      ...state,
      configuration: {
        loaded: true,
        data: payload,
      },
    }),
    [openSaveDialog]: (state) => ({
      ...state,
      saveDialogVisible: true,
    }),
    [closeSaveDialog]: (state) => ({
      ...state,
      saveDialogVisible: false,
    }),
    [closeSummaryDialog]: (state) => ({
      ...state,
      inSummary: false,
    }),
    [storeMail]: (state, payload) => ({
      ...state,
      save: {
        ...state.save,
        mail: payload,
      },
    }),
    [storeSaveId]: (state, payload) => ({
      ...state,
      save: {
        ...state.save,
        id: payload,
      },
    }),
    [storeUserId]: (state, payload) => ({
      ...state,
      usid: payload,
    }),
    [storeSaveVersion]: (state, payload) => ({
      ...state,
      save: {
        ...state.save,
        version: payload,
      },
    }),
    [storeElectronicSignatureEnabled]: (state, payload) => ({
      ...state,
      electronicSignatureEnabled: payload,
    }),
    [storeCompany]: (state, payload) => ({
      ...state,
      company: payload,
    }),
    [startSubmitClaim]: (state) => ({
      ...state,
      submittingClaim: true,
    }),
    [storeSubmitId]: (state, payload) => ({
      ...state,
      submittingClaim: false,
      submit: {
        ...state.submit,
        submitId: payload,
      },
    }),
    [startLoadingUserData]: (state) => ({
      ...state,
      loadingUserData: true,
    }),
    [startLoadingCascoData()]: (state) => ({
      ...state,
      loadingCascoData: true,
    }),
    [storeUserData]: (state, userData) => ({
      ...storeLoadedUserData(state, userData),
    }),
    [storeUserDataMobileApp]: (state, userData) => ({
      ...storeLoadedUserDataMobileApp(state, userData),
    }),
    [storeCascoData]: (state, hasCasco) => ({
      ...storeLoadedCascoData(state, hasCasco),
    }),
    [removeParticipants]: (state, index) => ({
      ...removeSkippedParticipants(state),
    }),
    [skipParticipant]: (state, index) => ({
      ...addSkipParticipant(state, index),
    }),
    [openLogOut]: (state) => ({
      ...state,
      logOutVisible: true,
    }),
    [closeLogOut]: (state) => ({
      ...state,
      logOutVisible: false,
    }),
    [storeCounterUser]: (state, counterUser) => ({
      ...state,
      counterUser,
    }),
    [removeDamagedGood]: (state, damagedGood) => ({
      ...removeSelectedGood(state, damagedGood),
    })
  },
  initialState
);
