import React, {Component} from "react";
import {connect} from "react-redux";

import _ from "lodash";
import * as Yup from "yup";

import {submitClaim} from "actions/flow";
import Text from "components/common/Text";
import flowConstants from "constants/flow";
import fieldLengthConstants from "constants/fieldLength";
import {ConfigContext} from "containers/context/ConfigContainer";
import Header from "containers/step/Header";
import PhoneNumberRight from "containers/step/phoneNumber/PhoneNumberRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {getMax} from "selectors/yup";

const validationSchema = Yup.object().shape({
  telephoneNumber: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PHONE_NUMBER.NAME].telephoneNumber),
  message: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.PHONE_NUMBER.NAME].message),
});

class PhoneNumberStepContainer extends Component {

  left = ({claimType}) => {
    return (
      <SplitViewContainer.Left>
        <Header showSave={false}/>
        <Text element="h2" label={`phoneNumber.sidebar.${claimType}.title`}/>
        <Text element="div" label={`phoneNumber.sidebar.${claimType}.subtitle`} isHtml={true}/>
      </SplitViewContainer.Left>
    );
  };

  right(props) {
    return (
      <SplitViewContainer.Right {...props} showBack={false}>
        <PhoneNumberRight {...props}/>
      </SplitViewContainer.Right>
    );
  }

  handleSubmit = (url) => (values) => {
    this.props.submitClaim(url, this.props.step, values, this.props.electronicSignatureEnabled && this.props.loggedInFromCounter);
  };

  render() {
    return (
      <ConfigContext.Consumer>
        {context =>
          <SplitViewContainer left={this.left}
                              right={this.right}
                              validationSchema={validationSchema}
                              onSubmit={this.handleSubmit(context.url.submitClaim)}/>
        }
      </ConfigContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  electronicSignatureEnabled: state.flow.electronicSignatureEnabled || false,
  loggedInFromCounter: _.get(state, "flow.counterUser.authenticated") || false,
});
const mapDispatchToProps = (dispatch) => ({
  submitClaim: (url, step, values, shouldWaitBeforeNextStep) => dispatch(submitClaim(url, step, values, shouldWaitBeforeNextStep)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberStepContainer);
