import React from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import causeOfDeathTypes from "constants/causeOfDeathTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default function CauseOfDeathRight(props) {
  const {values, onNextStep} = props;

  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <NextStepButton component={ToggleButton}
                        onNextStep={onNextStep}
                        isValid={true}
                        condition={value => value !== causeOfDeathTypes.OTHER}
                        buttonProps={{name: "causeOfDeath"}}>
          <Layout.OneRow spacing={10} flex={1}>
            <ToggleButton.Choice value={causeOfDeathTypes.CAR_ACCIDENT}
                                 label="causeOfDeath.content.button.carAccident.label"/>
            <ToggleButton.Choice value={causeOfDeathTypes.OTHER_ACCIDENT}
                                 label="causeOfDeath.content.button.otherAccident.label"/>
            <ToggleButton.Choice value={causeOfDeathTypes.SICK}
                                 label="causeOfDeath.content.button.sick.label"/>
            <ToggleButton.Choice value={causeOfDeathTypes.OTHER}
                                 label="causeOfDeath.content.button.other.label"/>
          </Layout.OneRow>
        </NextStepButton>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <FadeView step={values.causeOfDeath}>
          <Switch condition={values.causeOfDeath}>
            <Case value={causeOfDeathTypes.OTHER}>
              <Layout width="100%" maxWidth={450} justifyContent="center">
                <Layout.OneColumn>
                  <Layout.OneColumn spacing={20}/>
                  <Text element="h5" variant="variant2" label="causeOfDeath.content.title"/>
                  <Layout.OneColumn spacing={10}/>
                  <TextField name="description"
                             label="causeOfDeath.content.subtitle"
                             placeholder="causeOfDeath.content.causeOfDeathDescription.placeholder"
                             multiLine={true}
                             variant="variant5"
                             maxLength={fieldLengthConstants[flowConstants.personSteps.CAUSE_OF_DEATH.NAME].description + 1}/>
                </Layout.OneColumn>
              </Layout>
            </Case>
            <Default>
              {null}
            </Default>
          </Switch>
        </FadeView>
      </Layout.OneColumn>
    </Layout>
  );
}
