import React, {Component} from "react";

import {Formik} from "formik";

import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Button from "components/common/Button";
import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import BankAccountForm from "components/form/BankAccountForm";
import isSlovenianBankAccount from "containers/step/userIdentification/utilityFunctions"
import Yup, {getMax} from "selectors/yup";

const bankAccountValidationSchema = Yup.object().shape({
  bankAccount: Yup.string().required("validation.bankAccount").validateBankAccount("validation.bankAccount.format"),
  bank: Yup.object().when("bankAccount", {
    is: bankAccount => !isSlovenianBankAccount({bankAccount}),
    then: Yup.object().shape({
      name: getMax(Yup.string().nullable().trim().required("validation.bankAccount.name"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].bankName),
      bic: getMax(Yup.string().nullable().trim().required("validation.bankAccount.bic"), fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].swift),
    }),
  }),
});

export default class AddBankAccountDialog extends Component {
  render() {
    const {onSubmit, initialValues} = this.props;

    return (
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={bankAccountValidationSchema}>
        {(formProps) => (
          <InnerAddBankAccountDialog {...formProps}/>
        )}
      </Formik>
    );
  }
}

export class InnerAddBankAccountDialog extends Component {
  componentDidMount() {
    const {validateForm, values} = this.props;
    validateForm(values);
  }

  render() {
    const {isValid, submitForm, name = "bankAccount", formName, showConfirmation = true} = this.props;

    return (
      <Layout spacing={5} flex={1}>
        <Layout.OneColumn spacing={25}/>
        <Layout.OneColumn>
          <Text element="div" label="bankAccounts.content.bank.account.modal.header" variant="variant24"/>
          <Layout.OneColumn spacing={10}/>
          <Text element="div" label="bankAccounts.content.bank.account.modal.description" variant="variant25"/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={15}/>
        <BankAccountForm name={name} isRequired={true} formName={formName}/>
        <Layout.OneColumn spacing={20}/>
        {showConfirmation
          ? <Layout.OneColumn textAlign="right">
            <NextStepButton component={Button}
                            isValid={isValid}
                            onNextStep={submitForm}
                            buttonProps={{
                              label: "bankAccounts.content.button.confirm",
                              isDisabled: !isValid,
                            }}/>
          </Layout.OneColumn>
          : null
        }
      </Layout>
    );
  }
}