import React, {Component} from "react";

import {CSSTransition, TransitionGroup} from "react-transition-group";
import {isObject} from "underscore";

import {getStoreKey} from "selectors/flow";

import cx from "classnames";

import styles from "components/view/SlideView.module.scss";

export default class SlideView extends Component {
  render() {
    const {step, direction, children, fromSummary} = this.props;

    const groupClassName = cx({
      [styles[`${direction}`]]: true,
      [styles.fromSummary]: fromSummary,
    });

    const classNames = {
      enter: styles.enter,
      enterActive: styles.enterActive,
      exit: styles.exit,
      exitActive: styles.exitActive,
    };

    const key = isObject(step) ? getStoreKey(step) : step;

    return (
      <TransitionGroup className={groupClassName}>
        <CSSTransition key={key} classNames={classNames} timeout={300}>
          <div>
            {children}
          </div>
        </CSSTransition>
      </TransitionGroup>
    );
  }
}
