import React, {Component} from "react"

import Link from "components/common/Link";
import fileTypesConstants from "constants/fileTypes"

import pdfSvg from "images/documents/pdf.svg";
import imgSvg from "images/documents/img.svg";
import xlsSvg from "images/documents/xls.svg";
import docSvg from "images/documents/doc.svg";
import videoSvg from "images/documents/video.svg";
import close from "images/close.svg";

import styles from "components/icon/DocumentsIcon.module.scss";

const iconMapping = {
  [fileTypesConstants.EXCEL]: xlsSvg,
  [fileTypesConstants.PDF]: pdfSvg,
  [fileTypesConstants.PHOTO]: imgSvg,
  [fileTypesConstants.WORD]: docSvg,
  [fileTypesConstants.VIDEO]: videoSvg,
};

export default class DocumentsIcon extends Component {
  static defaultProps = {
    onDocumentClick: (id) => null,
  };

  render() {
    const {id, onButtonClick, onDocumentClick, type, name} = this.props;

    return (
      <div className={styles.root}>

        <Link onClick={onDocumentClick(id)} variant="variant5">
          <img className={styles.icon}
              src={iconMapping[type]}
              alt="icon"
              onClick={onDocumentClick(id)}/>
          <p>{name}</p>
        </Link>
        {onButtonClick
          ? <img className={styles.delete}
                 src={close}
                 alt="delete"
                 onClick={onButtonClick(id)}/>
          : null}
      </div>
    );
  }
}