import React, {Component} from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import DateField from "components/form/DateField";
import SexField from "components/form/SexField";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import TaxNumber from "components/step/TaxNumber";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import {RegistryContext} from "containers/context/RegistyContainer";
import {capitalize} from "utility/textTransform";
import isUndefinedOrNull, {isSloAddress} from "utility/utilityFunctions";


export default function NaturalPerson(props) {
  return (
    <RegistryContext.Consumer>
      {context => (
        <InnerNaturalPerson {...props} slovenia={context.registry.slovenia}/>
      )}
    </RegistryContext.Consumer>
  );
}

class InnerNaturalPerson extends Component {
  render() {
    const {responsiblePersonChoice, values, slovenia} = this.props;
    const address = isUndefinedOrNull(values.legalPerson) ? null : values.legalPerson.address;

    if (responsiblePersonChoice !== responsiblePersonTypes.NATURAL_PERSON) {
      return null;
    }

    return (
      <Layout width="100%" spacing={10} maxWidth={450}>
        <Layout.OneColumn spacing={20}/>
        <Layout.OneColumn>
          <Text element="h5" variant="variant2" label="homeResponsiblePerson.content.naturalPerson.data.title"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name="naturalPerson.name"
                     transform={capitalize}
                     isRequired={true}
                     placeholder="homeResponsiblePerson.content.naturalPerson.name.placeholder"
                     label="homeResponsiblePerson.content.naturalPerson.name.label"
                     maxLength={fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].name + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name="naturalPerson.surname"
                     transform={capitalize}
                     isRequired={true}
                     placeholder="homeResponsiblePerson.content.naturalPerson.surname.placeholder"
                     label="homeResponsiblePerson.content.naturalPerson.surname.label"
                     maxLength={fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].surname + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <DateField name="naturalPerson.birthDate"
                     label="homeResponsiblePerson.content.naturalPerson.birthDate.placeholder"
                     placeholder="homeResponsiblePerson.content.naturalPerson.birthDate.placeholder"
                     legalAgeField={true}
                     isRequired={true}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <SexField name="naturalPerson.sex" />
        </Layout.OneColumn>
        <Layout.OneColumn>
          <Address name="naturalPerson.address" isRequired={true}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name="naturalPerson.policyNumber"
                     placeholder="homeResponsiblePerson.content.naturalPerson.policyNumber.placeholder"
                     label="homeResponsiblePerson.content.naturalPerson.policyNumber.label"
                     maxLength={fieldLengthConstants[flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME].policyNumber + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TaxNumber name="naturalPerson.taxNumber"
                     placeholder="homeResponsiblePerson.content.naturalPerson.taxNumber.placeholder"
                     label="homeResponsiblePerson.content.naturalPerson.taxNumber.label"
                     isRequired={true}
                     isSlovenia={isSloAddress(address, slovenia)}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}