import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import VehicleInfoContainer from "components/step/VehicleInfoContainer";
import wasDriverTypesConstants from "constants/wasDriverTypes";
import Header from "containers/step/Header";
import OwnVehicleInfoDriverHurtRight from "containers/step/ownVehicleInfo/OwnVehicleInfoDriverHurtRight";
import SplitViewContainer from "containers/view/SplitViewContainer";

// step 20
export default class OwnVehicleInfoDriverHurtStepContainer extends Component {
  left = ({step, values, fromSummary}) => {
    const wasDriver =
      values.wasDriver === wasDriverTypesConstants.YES ? "wasDriver" : "wasNotDriver";

    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label={`ownVehicle.driverHurt.content.title.${wasDriver}`}/>
        <Text element="div" label="ownVehicle.driverHurt.content.subtitle"/>
        <VehicleInfoContainer {...step.participant}/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right showNextStepButton={false} {...props}>
        <OwnVehicleInfoDriverHurtRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
