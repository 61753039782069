import React from "react";
import Switch, {Case, Default} from "react-switch-case";

import flowConstants from "constants/flow";
import HelpInformationStepContainer from "containers/step/helpInformation/HelpInformationStepContainer";
import ThankYouStepContainer from "containers/step/thankYou/ThankYouStepContainer";
import DocumentsStepContainer from "containers/step/documents/DocumentsStepContainer";
import AccidentDateStepContainer from "containers/step/accidentDate/AccidentDateStepContainer";
import AccidentTimeStepContainer from "containers/step/accidentTime/AccidentTimeStepContainer";
import AccidentLocationStepContainer from "containers/step/accidentLocation/AccidentLocationStepContainer";
import EventDescriptionStepContainer from "containers/step/eventDescription/EventDescriptionStepContainer";
import PhoneNumberStepContainer from "containers/step/phoneNumber/PhoneNumberStepContainer";
import InsuranceOwnerStepContainer from "containers/step/insuranceOwner/InsuranceOwnerStepContainer";
import UserIdentificationStepContainer from "containers/step/userIdentification/UserIdentificationStepContainer";
import BankAccountsStepContainer from "containers/step/bankAccounts/BankAccountsStepContainer";
import SummaryStepContainer from "containers/step/summary/SummaryStepContainer";
import UserIdentificationTypeStepContainer from "./step/userIdentificationType/UserIdentificationTypeStepContainer";
import ApplicantDataStepContainer from "./step/applicantData/ApplicantDataStepContainer";
import UserLoginStepContainer from "./step/userLogin/UserLoginStepContainer";

export default function StateRouterGeneralContainer({step}) {
  return (
    <Switch condition={step.name}>

      <Case value={flowConstants.steps.HELP_INFORMATION.NAME}>{/* step 3 */}
        <HelpInformationStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_DATE.NAME}>{/* step 6 */}
        <AccidentDateStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_TIME.NAME}>{/* step 5 */}
        <AccidentTimeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.ACCIDENT_LOCATION.NAME}>{/* step 6 */}
        <AccidentLocationStepContainer/>
      </Case>
      <Case value={flowConstants.steps.EVENT_DESCRIPTION.NAME}>{/* step 7 */}
        <EventDescriptionStepContainer/>
      </Case>
      <Case value={flowConstants.generalSteps.GENERAL_DOCUMENTS.NAME}>{/* step 9 */}
        <DocumentsStepContainer/>
      </Case>
      <Case value={flowConstants.steps.INSURANCE_OWNER.NAME}>{/* step 33 */}
        <InsuranceOwnerStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_IDENTIFICATION_TYPE.NAME}>{/* step 33 */}
        <UserIdentificationTypeStepContainer/>
      </Case>
      <Case value={flowConstants.steps.APPLICANT_DATA.NAME}>{/* step 33 */}
        <ApplicantDataStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_LOGIN.NAME}>{/* step 33 */}
        <UserLoginStepContainer/>
      </Case>
      <Case value={flowConstants.steps.USER_IDENTIFICATION.NAME}>{/* step 10 */}
        <UserIdentificationStepContainer/>
      </Case>
      <Case value={flowConstants.steps.BANK_ACCOUNTS.NAME}>{/* step 11 */}
        <BankAccountsStepContainer/>
      </Case>
      <Case value={flowConstants.steps.SUMMARY.NAME}>{/* step 12 */}
        <SummaryStepContainer/>
      </Case>
      <Case value={flowConstants.steps.PHONE_NUMBER.NAME}>{/* step 13 */}
        <PhoneNumberStepContainer/>
      </Case>
      <Case value={flowConstants.steps.THANK_YOU.NAME}>{/* step 14 */}
        <ThankYouStepContainer/>
      </Case>
      <Default>
        <div>
          Unknown step: {step.name}.
        </div>
      </Default>
    </Switch>
  );
}