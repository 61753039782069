import React from "react";

import cx from "classnames";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import VehicleIcon from "components/step/VehicleIcon";
import {VehicleInfoContext} from "components/step/VehicleInfoLogic";

import styles from "components/step/VehicleInfo.module.scss";

function VehicleInfo({variant, name, type, color, showIcon = true, choice, children}) {
  const className = cx({
    [styles.root]: true,
    [styles.variant2]: variant === "variant2",
    [styles.variant3]: variant === "variant3",
    [styles.variant4]: variant === "variant4",
  });

  return (
    <div className={className}>
      <div className={styles.icon}>
        <VehicleInfoIcon showIcon={showIcon} name={name} type={type} color={color}/>
      </div>
      <ul className={styles.list}>
        <VehicleInfoContext.Provider value={{component: VehicleInfoLine, choice}}>
          {children}
        </VehicleInfoContext.Provider>
      </ul>
    </div>
  );
}

function VehicleInfoIcon({showIcon, ...props}) {
  return showIcon ? <VehicleIcon {...props}/> : null;
}

function VehicleInfoLine({label, children}) {
  return (
    <li className={styles.line}>
      <Layout.OneRow spacing={8} width="50%">
        <div className={styles.column}><Text label={label}/>:</div>
        <div className={styles.column}>
          <strong className={styles.lineValue}>
            {children}
          </strong>
        </div>
      </Layout.OneRow>
    </li>
  );
}

VehicleInfo.Line = VehicleInfoLine;

export default VehicleInfo;
