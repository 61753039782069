import React, {Component} from "react";

import claimTypesConstants from "../../../constants/claimTypes";
import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import userIdentificationTypeConstants from "constants/userIdentificationType";
import {ConfigContext} from "../../context/ConfigContainer";
import {getLoggedInUserData, storeClaim, storeUserId} from "actions/flow";
import {connect} from "react-redux";
import {formatDate} from "utility/utilityFunctions";
import _ from "lodash";

class UserIdentificationTypeRight extends Component {

  static contextType = ConfigContext;

  handleSubmit = (url) => () => {
    const {values, onNextStep} = this.props;
    if (values.userIdentificationType === userIdentificationTypeConstants.GENERALI) {
      this.props.storeClaim(url);
    }
    else {
      onNextStep();
    }
  };

  componentDidMount() {

    const {accidentDateForm, claimType, userId} = this.props;

    if (!userId) {
      return;
    }

    this.props.storeUserId(null);

    document.getElementById('logoutFrame').src = this.context.url.userLogout;
    document.getElementById('logoutFrameRecono').src = this.context.url.userLogoutRecono;

    const userData = {
      userId: userId,
      type: claimType,
      accidentDate: formatDate(accidentDateForm)
    }

    this.props.getLoggedInUserData(this.context.url.getLoggedInUser, userData);

  }

  render() {

    const {loadingUserData} = this.props;

    const isSsoEnabled = this.context.ssoEnabled;

    return (
      <Layout spacing={10}>
        <Layout.OneColumn flex={1}>
          <NextStepButton component={ToggleButton}
                          onNextStep={this.handleSubmit(this.context.url.saveClaim)}
                          isValid={true}
                          buttonProps={{
                            name: "userIdentificationType",
                          }}>

            {

              isSsoEnabled
                ?
                <Layout spacing={10} width="100%" textAlign="center">
                  <Layout.OneColumn>
                    <ToggleButton.Choice value={userIdentificationTypeConstants.INSURANCE_INFO}
                                         condition={!loadingUserData}
                                         label="userIdentificationType.content.button.insuranceInfo.label"/>
                  </Layout.OneColumn>
                  <Layout.OneColumn>
                    <ToggleButton.Choice value={userIdentificationTypeConstants.MANUAL}
                                         condition={!loadingUserData}
                                         label="userIdentificationType.content.button.manual.label"/>
                  </Layout.OneColumn>
                  <Layout.OneColumn>
                    <ToggleButton.Choice value={userIdentificationTypeConstants.GENERALI}
                                         condition={!loadingUserData}
                                         label="userIdentificationType.content.button.generali.label"/>
                  </Layout.OneColumn>
                </Layout>
                :
                <Layout spacing={10} width="100%" textAlign="center">
                  <Layout.OneColumn>
                    <ToggleButton.Choice value={userIdentificationTypeConstants.INSURANCE_INFO}
                                         condition={!loadingUserData}
                                         label="userIdentificationType.content.button.insuranceInfo.label"/>
                  </Layout.OneColumn>
                  <Layout.OneColumn>
                    <ToggleButton.Choice value={userIdentificationTypeConstants.MANUAL}
                                         condition={!loadingUserData}
                                         label="userIdentificationType.content.button.manual.label"/>
                  </Layout.OneColumn>
                </Layout>
            }
          </NextStepButton>
        </Layout.OneColumn>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.flow.usid,
  claimType: _.at(state.flow.forms, "claimType.choiceType")[0],
  accidentDateForm: _.at(state.flow.forms, "accidentDate")[0]
});

const mapDispatchToProps = (dispatch) => ({
  storeClaim: (url) => dispatch(storeClaim(url, false, true)),
  storeUserId: (userId) => dispatch(storeUserId(userId)),
  getLoggedInUserData: (url, userData) => dispatch(getLoggedInUserData(url, userData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserIdentificationTypeRight);