import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default class PersonReportedPoliceRight extends Component {
  render() {
    const {values, onNextStep} = this.props;

    return (
      <Layout spacing={10}>
        <Layout.OneColumn flex={1}>
          <NextStepButton component={ToggleButton}
                          condition={value => value !== booleanTypesConstants.YES}
                          isValid={true}
                          onNextStep={onNextStep}
                          buttonProps={{
                            name: "reportedToPolice",
                          }}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
              <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
              <ToggleButton.Choice value={booleanTypesConstants.DONT_KNOW} label="button.dontKnow"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={values.reportedToPolice}>
            <Switch condition={values.reportedToPolice}>
              <Case value={booleanTypesConstants.YES}>
                <TextField name="policeStation"
                           placeholder="personReportedPolice.content.policeStation.placeholder"
                           label="personReportedPolice.content.policeStation.label"
                           maxLength={fieldLengthConstants[flowConstants.personSteps.PERSON_REPORTED_POLICE.NAME].policeStation + 1}/>
              </Case>
              <Default>
                {null}
              </Default>
            </Switch>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
