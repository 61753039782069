import React, {Component} from "react";

import {FieldArray} from "formik";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Witness from "components/step/Witness";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";

export default function Witnesses({screenName, witnessesPresent}) {
  if (witnessesPresent !== booleanTypesConstants.YES) {
    return null;
  }

  return (
    <FieldArray name="witnesses" render={arrayHelpers => (
      <WitnessesData arrayHelpers={arrayHelpers} screenName={screenName}/>
    )}/>
  );
}

class WitnessesData extends Component {
  add = () => {
    this.props.arrayHelpers.push({});
  };

  render() {
    const {arrayHelpers, screenName} = this.props;
    const witnesses = arrayHelpers.form.values.witnesses;

    const values =
      witnesses.map((witness, i) => (
        <FadeView.Child childKey={i} key={i}>
          <Witness index={i} onRemove={() => arrayHelpers.remove(i)} showRemove={witnesses.length > 1} screenName={screenName}/>
        </FadeView.Child>
      ));

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn>
          <FadeView>
            {values}
          </FadeView>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={20}></Layout.OneColumn>
        <Layout.OneColumn textAlign="right">
          <Button variant="variant10" label={`${screenName}.content.button.add`} onClick={this.add}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
