import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import ConditionalField from "components/form/ConditionalField";
import DateField from "components/form/DateField";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import Address from "components/step/Address";
import booleanTypesConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {capitalize} from "utility/textTransform";
import SexField from "components/form/SexField";

export default function OtherParticipantsInfo() {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
      <Layout.OneColumn>
        <Text element="h4" variant="variant1" label="otherParticipantsInfo.title"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="otherParticipantsInfo.name"
                   transform={capitalize}
                   placeholder="otherParticipantsInfo.name.placeholder"
                   label="otherParticipantsInfo.name.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME].name + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="otherParticipantsInfo.surname"
                   transform={capitalize}
                   placeholder="otherParticipantsInfo.surname.placeholder"
                   label="otherParticipantsInfo.surname.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME].surname + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <DateField name="otherParticipantsInfo.birthDate"
                   label="otherParticipantsInfo.birthDate.label"
                   placeholder="otherParticipantsInfo.birthDate.placeholder"
                   legalAgeField={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <SexField name="otherParticipantsInfo.sex" />
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Address name="otherParticipantsInfo.address"/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={20}></Layout.OneColumn>
      <ConditionalField name="injuryType">
        <Layout.OneColumn>
          <Text element="span" variant="variant2" label="otherParticipantsInfo.injury.title"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <ToggleButton name="otherParticipantsInfo.injuryType">
            <Layout.OneRow flex={1} spacing={10}>
              <ToggleButton.Choice label="button.yes"
                                   value={booleanTypesConstants.YES}
                                   variant="variant11"/>
              <ToggleButton.Choice label="button.no"
                                   value={booleanTypesConstants.NO}
                                   variant="variant11"/>
              <ToggleButton.Choice label="button.dontKnow"
                                   value={booleanTypesConstants.DONT_KNOW}
                                   variant="variant11"/>
            </Layout.OneRow>
          </ToggleButton>
        </Layout.OneColumn>
      </ConditionalField>
      <Layout.OneColumn spacing={20}></Layout.OneColumn>
      <ConditionalField name="passengers">
        <Layout.OneColumn>
          <Text element="span" variant="variant2" label="otherParticipantsInfo.passengers.title"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <ToggleButton name="otherParticipantsInfo.passengers">
            <Layout.OneRow flex={1} spacing={10}>
              <ToggleButton.Choice label="button.yes"
                                   value={booleanTypesConstants.YES}
                                   variant="variant11"/>
              <ToggleButton.Choice label="button.no"
                                   value={booleanTypesConstants.NO}
                                   variant="variant11"/>
              <ToggleButton.Choice label="button.dontKnow"
                                   value={booleanTypesConstants.DONT_KNOW}
                                   variant="variant11"/>
            </Layout.OneRow>
          </ToggleButton>
        </Layout.OneColumn>
      </ConditionalField>
    </Layout>
  );
}
