import React from "react";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import BankAccountForm from "components/form/BankAccountForm";
import DateField from "components/form/DateField";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import TaxNumber from "components/step/TaxNumber";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {RegistryContext} from "containers/context/RegistyContainer";
import {capitalize} from "utility/textTransform";
import {isSloAddress} from "utility/utilityFunctions";
import _ from "lodash";

export default function BankAccountOtherPerson(props) {
  return (
    <RegistryContext.Consumer>
      {context => (
        <InnerBankAccountOtherPerson slovenia={context.registry.slovenia} {...props} name="otherPerson"/>
      )}
    </RegistryContext.Consumer>
  );
}

function InnerBankAccountOtherPerson(props) {
  const {slovenia, name} = props;

  const address = _.get(props, "values.otherPerson.address", {});

  return (
    <Layout spacing={5} width="100%" maxWidth={440} justifyContent="center">
      <Layout.OneColumn spacing={25}/>
      <Layout.OneColumn>
        <Text element="div" label="bankAccounts.content.other.person.title" variant="variant24"/>
        <Layout.OneColumn spacing={10}/>
        <Text element="div" label="bankAccounts.content.other.person.subtitle" variant="variant25"/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={30}/>
      <Layout.OneColumn>
        <Text element="div" label="bankAccounts.content.other.person.data" variant="variant2"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`${name}.name`}
                   transform={capitalize}
                   label="bankAccounts.content.name.label"
                   placeholder="bankAccounts.content.name.placeholder"
                   isRequired={true}
                   maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].name + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`${name}.surname`}
                   transform={capitalize}
                   label="bankAccounts.content.surname.label"
                   placeholder="bankAccounts.content.surname.placeholder"
                   isRequired={true}
                   maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].surname + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <DateField name={`${name}.birthDate`}
                   label="bankAccounts.content.birthDate.label"
                   placeholder="bankAccounts.content.birthDate.placeholder"
                   modal={true}
                   legalAgeField={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Address name={`${name}.address`}
                 isRequired={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`${name}.telephoneNumber`}
                   label="bankAccounts.content.telephoneNumber.label"
                   placeholder="bankAccounts.content.telephoneNumber.placeholder"
                   isRequired={false}
                   maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].telephoneNumber + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name={`${name}.email`}
                   label="bankAccounts.content.email.label"
                   placeholder="bankAccounts.content.email.placeholder"
                   isRequired={false}
                   maxLength={fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].email + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TaxNumber name={`${name}.taxNumber`}
                   placeholder="bankAccounts.content.taxNumber.placeholder"
                   label="bankAccounts.content.taxNumber.label"
                   isRequired={true}
                   isSlovenia={isSloAddress(address, slovenia)}
                   maxLength={isSloAddress(address, slovenia) ? fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].taxNumberSlo + 1 : fieldLengthConstants[flowConstants.steps.BANK_ACCOUNTS.NAME].taxNumber + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={20}/>
      <Layout.OneColumn>
        <Text element="div" label="bankAccounts.content.other.person.bank.account.data" variant="variant2"/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={10}/>
      <Layout.OneColumn spacing={0}>
        <BankAccountForm name={`${name}.bankAccount`} formName={name} isRequired={true}/>
      </Layout.OneColumn>
    </Layout>
  );
}