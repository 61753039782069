import React from "react";

import { Formik } from "formik";
import { withCookies } from "react-cookie";
import Switch, { Case, Default } from "react-switch-case";

import { ConfigContext } from "containers/context/ConfigContainer";

import cx from "classnames";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import CheckBoxField from "components/form/CheckBox/CheckBoxField";
import ChoiceButton from "components/form/ChoiceButton";
import booleanTypeConstants from "constants/booleanTypes";
import cookieTypeConstants from "constants/cookie";
import dataLayerConstants from "constants/dataLayer";

import styles from "components/step/CookieDisclaimer.module.scss";

const initialState = () => ({
  simple: booleanTypeConstants.YES,
  requiredCookies: true,
  advertsCookies: false,
  functionalCookies: false,
  analyticsCookies: false,
});

function CookieDisclaimer({ cookies, initialValues, ...props }) {
  const getVisible = () => {
    return cookies.get(cookieTypeConstants.DISCLAIMER_VISIBLE) !== "false";
  };

  const onSubmit = (values) => {
    const expires = new Date(9999, 11, 31);

    cookies.set(cookieTypeConstants.DISCLAIMER_VISIBLE, false, { path: "/", expires: expires });

    window.dataLayer = window.dataLayer || [];

    if (values.saveAll === booleanTypeConstants.YES) {
      cookies.set(cookieTypeConstants.REQUIRED, true, { path: "/", expires: expires });
      cookies.set(cookieTypeConstants.ADVERTS, true, { path: "/", expires: expires });
      cookies.set(cookieTypeConstants.FUNCTIONAL, true, { path: "/", expires: expires });
      cookies.set(cookieTypeConstants.ANALYTICS, true, { path: "/", expires: expires });

      if (window.gdpr) {
        window.gdpr.add(dataLayerConstants.COOKIES.REQUIRED);
        window.gdpr.add(dataLayerConstants.COOKIES.ADVERTS);
        window.gdpr.add(dataLayerConstants.COOKIES.FUNCTIONAL);
        window.gdpr.add(dataLayerConstants.COOKIES.ANALYTICS);
      }
    } else if (values.saveAll === booleanTypeConstants.NO) {
      if (values.requiredCookies) {
        cookies.set(cookieTypeConstants.REQUIRED, true, { path: "/", expires: expires });
        if (window.gdpr) {
          window.gdpr.add(dataLayerConstants.COOKIES.REQUIRED);
        }
      } else if (window.gdpr) {
        window.gdpr.remove(dataLayerConstants.COOKIES.REQUIRED);
      }

      if (values.advertsCookies) {
        cookies.set(cookieTypeConstants.ADVERTS, true, { path: "/", expires: expires });
        if (window.gdpr) {
          window.gdpr.add(dataLayerConstants.COOKIES.ADVERTS);
        }
      } else if (window.gdpr) {
        window.gdpr.remove(dataLayerConstants.COOKIES.ADVERTS);
      }

      if (values.functionalCookies) {
        cookies.set(cookieTypeConstants.FUNCTIONAL, true, { path: "/", expires: expires });
        if (window.gdpr) {
          window.gdpr.add(dataLayerConstants.COOKIES.FUNCTIONAL);
        }
      } else if (window.gdpr) {
        window.gdpr.remove(dataLayerConstants.COOKIES.FUNCTIONAL);
      }

      if (values.analyticsCookies) {
        cookies.set(cookieTypeConstants.ANALYTICS, true, { path: "/", expires: expires });
        if (window.gdpr) {
          window.gdpr.add(dataLayerConstants.COOKIES.ANALYTICS);
        }
      } else if (window.gdpr) {
        window.gdpr.remove(dataLayerConstants.COOKIES.ANALYTICS);
      }
    }

    if (window.gdpr) {
      window.dataLayer.push(window.getGdprData());
    }

    window.dataLayer.push({ event: dataLayerConstants.EVENT.COOKIE_BUTTON });

    setVisible(false);
  };

  const [visible, setVisible] = React.useState(getVisible());

  if (visible) {
    return (
      <Formik initialValues={initialValues || initialState()} onSubmit={onSubmit}>
        {(formProps) => <InnerCookieDisclaimer formProps={formProps} {...props} />}
      </Formik>
    );
  } else {
    return null;
  }
}

function InnerCookieDisclaimer({ formProps: { values, submitForm } }) {
  return (
    <Switch condition={values.simple}>
      <Case value={booleanTypeConstants.YES}>
        <SimpleCookies submitForm={submitForm} />
      </Case>
      <Case value={booleanTypeConstants.NO}>
        <DetailedCookies submitForm={submitForm} />
      </Case>
      <Default>{null}</Default>
    </Switch>
  );
}

function SimpleCookies({ submitForm }) {
  const config = React.useContext(ConfigContext);

  const cookiesClass = cx({
    [styles.gCD]: true,
    [styles.gCDWiz]: config.company === "wiz",
  });

  return (
    <div className={cookiesClass}>
      <div className={styles.gCDContainer}>
        <div className={styles.left}>
          <Text element="h1" className={styles.gCDHeading} label="cookieDisclaimer.simple.title" />
          <div className={styles.gCDText}>
            <Text element="div" variant="variant7" label="cookieDisclaimer.simple.subtitle" isHtml={true} />
          </div>
        </div>
        <div className={styles.right}>
          <ChoiceButton name="saveAll" value={booleanTypeConstants.YES} label="cookieDisclaimer.simple.button.saveAll.label" onClick={submitForm} variant="variant20" />
          <ChoiceButton name="simple" label="cookieDisclaimer.simple.button.details.label" value={booleanTypeConstants.NO} className={styles.buttonLink} />
        </div>
      </div>
    </div>
  );
}

function DetailedCookies({ submitForm }) {
  const config = React.useContext(ConfigContext);

  const cookiesClass = cx({
    [styles.gCS]: true,
    [styles.gCSWiz]: config.company === "wiz",
  });
  return (
    <div className={cookiesClass}>
      <Text element="h1" className={styles.gCSHeading} label="cookieDisclaimer.details.title" />
      <Text element="div" variant="variant18" label="cookieDisclaimer.details.subtitle" isHtml={true} />
      <div className={styles.gCSSettings}>
        <CheckBoxField name="requiredCookies" label="cookieDisclaimer.details.required.checkbox.label" variant="checkboxInverted" isDisabled={true} />
        <CheckBoxField name="advertsCookies" label="cookieDisclaimer.details.adverts.checkbox.label" variant="checkboxInverted" />
        <CheckBoxField name="functionalCookies" label="cookieDisclaimer.details.functional.checkbox.label" variant="checkboxInverted" />
        <CheckBoxField name="analyticsCookies" label="cookieDisclaimer.details.analytics.checkbox.label" variant="checkboxInverted" />
      </div>
      <Layout>
        <Layout.OneColumn width="100%" textAlign="center">
          <ChoiceButton name="saveAll" value={booleanTypeConstants.YES} label="cookieDisclaimer.details.button.saveAll.label" onClick={submitForm} variant="variant20" />
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" textAlign="center">
          <ChoiceButton name="saveAll" value={booleanTypeConstants.NO} label="cookieDisclaimer.details.button.save.label" onClick={submitForm} className={styles.buttonLink} />
        </Layout.OneColumn>
      </Layout>
    </div>
  );
}

export default withCookies(CookieDisclaimer);
