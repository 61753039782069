import React from "react";

import cx from "classnames";

import {CSSTransition, TransitionGroup} from "react-transition-group";
import {isObject} from "underscore";

import styles from "components/view/FadeView.module.scss";

function FadeView({className, step, children}) {
  const groupChildren =
    step
      ? (
        <FadeViewChild childKey={isObject(step) ? JSON.stringify(step) : step}>
          <div>
            {children}
          </div>
        </FadeViewChild>
      )
      : children;

  return (
    <TransitionGroup className={cx(styles.root, className)}>
      {groupChildren}
    </TransitionGroup>
  );
}

function FadeViewChild({childKey, children, ...props}) {
  const classNames = {
    enter: styles.enter,
    enterActive: styles.enterActive,
    exit: styles.exit,
    exitActive: styles.exitActive,
  };

  return (
    <CSSTransition {...props} key={childKey} classNames={classNames} timeout={200}>
      {children}
    </CSSTransition>
  );
}

FadeView.Child = FadeViewChild;

export default FadeView;
