import React from "react";

import Text from "components/common/Text";
import SummaryTable from "components/step/summary/SummaryTable";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryNaturalPersons({responsiblePerson}) {
  return (
    responsiblePerson.map((naturalPerson, key) => <SummaryTable.Part
      refName={summaryRefConstants.PERSON_INFO.RESPONSIBLE_PERSON_TYPE.REF}>
      <Summary.ItemValue
        label={<Text label="summary.content.responsiblePersonType.responsiblePerson.label" parameters={[key + 1]}/>}
        refName={summaryRefConstants.PERSON_INFO.RESPONSIBLE_PERSON_TYPE.NATURAL_PERSON(key)}
        formName={{name: flowConstants.personSteps.RESPONSIBLE_PERSON_TYPE.NAME}}>
        {naturalPerson}
      </Summary.ItemValue>
    </SummaryTable.Part>)
  );
}
