import React from "react";
import Text from "../components/common/Text";

import SplitViewContainer from "./view/SplitViewContainer";

export default function IEScreen() {
  return (
    <SplitViewContainer.Left>
      <Text element="h2" label={`intro.sidebar.title.ie`} />
      <Text element="div" label={`intro.sidebar.subtitle.ie`} variant="variant23" isHtml={true} />
    </SplitViewContainer.Left>
  );
}
