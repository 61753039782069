import React, {Component} from "react";

import SvgText from "components/common/SvgText";
import sketchConstants from "constants/sketch";

const svgProps = {
  width: sketchConstants.TEXT_DIMENSION.SOURCE_WIDTH,
  height: sketchConstants.TEXT_DIMENSION.SOURCE_HEIGHT,
};

const rectProps = {
  x: 0, y: 0, fill: "white",
  width: sketchConstants.TEXT_DIMENSION.SOURCE_WIDTH - 15,
  height: sketchConstants.TEXT_DIMENSION.SOURCE_HEIGHT - 20,
};

const textGroupProps = {
  x: 5, y: 5,
  width: sketchConstants.TEXT_DIMENSION.SOURCE_WIDTH - 20,
  height: sketchConstants.TEXT_DIMENSION.SOURCE_HEIGHT - 25,
};

const textProps = {
  width: sketchConstants.TEXT_DIMENSION.SOURCE_WIDTH - 20,
  style: {
    fill: "#000",
    fontSize: sketchConstants.TEXT_SIZE.SMALL,
    userSelect: "none",
  },
};

export default class SketchText extends Component {
  svgRef = React.createRef();

  render() {
    const {selected, value: {text}, ...props} = this.props;

    return (
      <svg ref={this.svgRef} {...svgProps} {...props}>
        <defs>
          <filter id="filter" y="-100%" x="-100%" height="300%" width="300%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
            <feOffset dx="2" dy="2" result="offsetblur"/>
            <feFlood floodColor="#aaaaaa" floodOpacity="1"/>
            <feComposite in2="offsetblur" operator="in"/>
            <feMerge>
              <feMergeNode in="offsetBlur"/>
              <feMergeNode in="SourceGraphic"/>
            </feMerge>
          </filter>
        </defs>

        <rect {...rectProps} filter="url(#filter)"/>
        <rect {...rectProps} stroke={"#555"} strokeOpacity={selected ? 1 : 0} strokeDasharray="2" strokeWidth={2}/>
        <g {...textGroupProps}>
          <SvgText {...textProps}>{text}</SvgText>
        </g>
      </svg>
    );
  }
}
