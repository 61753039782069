import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import WitnessesChoice from "containers/step/homeReportedPolice/WitnessesChoice";

export default class HomeReportedPoliceRight extends Component {
  render() {
    const {values} = this.props;

    return (
      <Layout spacing={10}>
        <Layout.OneColumn flex={1}>
          <ToggleButton name="reportedToPolice">
            <Layout spacing={10}>
              <Layout.OneColumn flex={1}>
                <Layout.OneRow spacing={10} flex={1}>
                  <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
                  <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
                </Layout.OneRow>
              </Layout.OneColumn>
            </Layout>
          </ToggleButton>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={values.reportedToPolice}>
            <Switch condition={values.reportedToPolice}>
              <Case value={booleanTypesConstants.YES}>
                <TextField name="policeStation"
                           isRequired={true}
                           placeholder="homeReportedPolice.content.policeStation.placeholder"
                           label="homeReportedPolice.content.policeStation.label"
                           maxLength={fieldLengthConstants[flowConstants.homeSteps.HOME_REPORTED_POLICE.NAME].policeStation + 1}/>
              </Case>
              <Case value={booleanTypesConstants.NO}>
                <WitnessesChoice reportedToPolice={values.reportedToPolice} {...this.props}/>
              </Case>
              <Default>
                {null}
              </Default>
            </Switch>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}