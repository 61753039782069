import React, {Component} from "react";

import axios from "axios";
import {findIndex} from "underscore";

import PhotosIcon from "components/icon/PhotosIcon";
import fileTypeConstants from "constants/fileTypes"
import {ConfigContext} from "containers/context/ConfigContainer";

export default class Photos extends Component {
  static contextType = ConfigContext;

  onRemovePhoto = (id) => () => {
    const {arrayHelpers, photos} = this.props;

    const index = findIndex(photos, photo => photo.id === id);

    axios.post(this.context.url.delete, {params: [id]});

    arrayHelpers.remove(index);
  };

  render() {
    const {photos = [], group} = this.props;

    return (
      photos
        .filter(file => file.type === fileTypeConstants.PHOTO || file.type === fileTypeConstants.VIDEO)
        .map((file, i) => (
            <PhotosIcon key={i}
                        group={group}
                        {...file}
                        onButtonClick={this.onRemovePhoto}/>
          )
        )
    );
  }
}