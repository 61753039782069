import React, {Component} from "react";

import {FieldArray} from "formik";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Link from "components/common/Link";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import VehicleFontIcon from "components/icon/VehicleFontIcon";
import FadeView from "components/view/FadeView";
import vehicleTypesConstants from "constants/vehicleTypes";

export default function ParticipantsNumberPicker({values, ...props}) {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <FieldArray name="participants" render={arrayHelpers => (
          <ParticipantsNumberPickerField {...props}
                                         arrayHelpers={arrayHelpers}
                                         count={values.count || 0}
                                         participants={values.participants || []}/>
        )}/>
      </Layout.OneColumn>
    </Layout>
  );
}

class ParticipantsNumberPickerField extends Component {
  add = () => {
    const {arrayHelpers, setFieldValue, count} = this.props;

    arrayHelpers.push({type: vehicleTypesConstants.CAR, index: count});
    setFieldValue("count", count + 1);
  };

  render() {
    const {arrayHelpers, participants} = this.props;

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn>
          <FadeView>
            {participants.map((value, i) => (
              <Participant key={i} index={i}
                           showRemove={participants.length > 1}
                           onRemove={() => arrayHelpers.remove(i)}/>
            ))}
          </FadeView>
        </Layout.OneColumn>

        <Layout.OneColumn spacing={5}></Layout.OneColumn>
        <Layout.OneColumn textAlign="right">
          <Button variant="variant10" label="participantsNumber.content.add" onClick={this.add}/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={15}></Layout.OneColumn>
      </Layout>
    );
  }
}

class Participant extends Component {
  render() {
    const {index, showRemove = true, onRemove, ...props} = this.props;

    const remove = showRemove
      ? <Link label="participantsNumber.content.remove" variant="variant1" onClick={onRemove}/>
      : <div>&nbsp;</div>;

    return (
      <FadeView.Child {...props} childKey={index}>
        <ToggleButton name={`participants[${index}].type`}>
          <Layout spacing={10}>
            <Layout.OneColumn spacing={20}></Layout.OneColumn>
            <Layout.Row>
              <Layout.Column>
                <Text variant="variant1"
                      label="participantsNumber.content.participant.title"
                      parameters={[index + 1]}
                      element="span"/>
              </Layout.Column>
              <Layout.Fill/>
              <Layout.Column>
                {remove}
              </Layout.Column>
            </Layout.Row>
            <Layout.OneColumn spacing={5}></Layout.OneColumn>
            <Layout.RowChildren>
              <Text label="participantsNumber.content.chooseParticipant"/>
            </Layout.RowChildren>
            <Layout.OneColumn spacing={10}></Layout.OneColumn>
            <Layout.RowChildren justifyContent="center">
              <ToggleButton.Choice value={vehicleTypesConstants.CAR}
                                   icon={<VehicleFontIcon type={vehicleTypesConstants.CAR}/>}/>
              <ToggleButton.Choice value={vehicleTypesConstants.SEMI_TRUCK}
                                   icon={<VehicleFontIcon type={vehicleTypesConstants.SEMI_TRUCK}/>}/>
              <ToggleButton.Choice value={vehicleTypesConstants.MOTORCYCLE}
                                   icon={<VehicleFontIcon type={vehicleTypesConstants.MOTORCYCLE}/>}/>
              <ToggleButton.Choice value={vehicleTypesConstants.BIKE}
                                   icon={<VehicleFontIcon type={vehicleTypesConstants.BIKE}/>}/>
              <ToggleButton.Choice value={vehicleTypesConstants.PEDESTRIAN}
                                   icon={<VehicleFontIcon type={vehicleTypesConstants.PEDESTRIAN}/>}/>
            </Layout.RowChildren>
            <Layout.OneColumn spacing={20}></Layout.OneColumn>
          </Layout>
        </ToggleButton>
      </FadeView.Child>
    );
  }
}
