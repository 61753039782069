import React from "react";

export const ConfigContext = React.createContext({});

/*
Ovo je od AS pa probajte prvo dali je za njega dozvoljen localhost
AIzaSyCGdGE7YGUJfSRpvyYb81r1HumT98z47PQ ---> ne prolazi geocoder

Ako nije - ovaj dolje će vam onda raditi - samo napomena, ovaj je za development, pa molim da ne završi na produkciji da mi nebi počeli stizati računi :-):
AIzaSyD7VRVNTScB3d4YhTb4R00F9q3GU37Cuas

 */

const defaultConfig = {
  keys: {
    googleMap: "AIzaSyD7VRVNTScB3d4YhTb4R00F9q3GU37Cuas",
  },
  url: {
    registry: "/api/registry",
    translations: "/api/translations",
    screenConfiguration: "/api/screenConfiguration",
    zipCodes: "/api/zipCodes",
    cities: "/api/cities",
    streets: "/api/streets",
    vehicleData: "/api/vehicleData",
    registration: "https://mojas.adriatic-slovenica.si/web/moj-as/registracija",
    customerDataByInsuranceInfo: "/api/getCustomerDataByInsuranceInfo",
    getLoggedInUser: "/api/getLoggedInUser",
    getMobileAppUser: "/api/getMobileAppUser",
    certificate: "/api/certificate/read",
    upload: "/api/file/upload",
    download: (id) => `/api/file/${id}`,
    downloadFile: (id) => `/api/download/${id}`,
    delete: "/api/file/delete",
    claim: (id, esig, user) => (esig ? `/api/claim/${id}/${esig}` : user ? `/api/claim/id/user` : `/api/claim/id/esig`),
    summaryHTML: (id) => `/api/summaryHTML/${id}`,
    postSummaryHTML: "/api/summaryHTML",
    saveClaim: "/api/saveClaim",
    submitClaim: "/api/submitClaim",
    forgotPassword: "https://mojas.adriatic-slovenica.si/web/moj-as/spremeni-geslo",
    login: "/api/login",
    logout: "/api/logout",
    report: "/api/report",
    banks: "/api/banks",
    countries: "/api/countries",
    counterUser: "/api/counterUser",
    continuedUserInfo: (encId) => `/api/continuedForm/${encId}`,
    cascoData: "/api/hasCasco",
    userLogout: "/api/userLogout",
    userLogoutRecono: "/api/userLogoutRecono",
    log: "/api/log"
  },
  company: "generali",
  claimExpired: false
};

export default function ConfigContainer({ children }) {
  return <ConfigContext.Provider value={window.asSkodeConfig || defaultConfig}>{children}</ConfigContext.Provider>;
}
