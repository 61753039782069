import {isUndefined} from "underscore";

export default function isSlovenianBankAccount(userInfo) {
  if(isUndefined(userInfo)){
    return true;
  }

  const {bankAccount} = userInfo;

  return isSlovenianBankAccountString(bankAccount);
}

export function isSlovenianBankAccountString(bankAccount) {
  return isUndefined(bankAccount) || bankAccount.toUpperCase().startsWith("SI56") || bankAccount.replace('_','').length === 0;
}