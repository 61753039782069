import React, {Component} from "react";

import * as dateFns from "date-fns/esm";
import {Field} from "formik";
import cx from "classnames";
import _ from "lodash";
import {isMobile} from "react-device-detect";
import {isDate} from "underscore";

import Layout from "components/common/Layout";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import IcoMoon from "components/icon/IcoMoon";
import {Picker, Wrapper} from "components/form/CommonDatePicker";
import CompositeField from "components/form/CompositeField";
import {InnerTextField} from "components/form/TextField";
import fontConstants from "constants/font";
import {RegistryContext} from "containers/context/RegistyContainer";

import styles from "components/form/CommonDatePicker.module.scss";
import isUndefinedOrNull from "utility/utilityFunctions";

export default function DateField({name, ...props}) {
  return (
      <Field
        name={name}
        render={(fieldProps) => (
          <CompositeField name={name} {...props}>
            {context => (
              <RegistryContext.Consumer>
                {(registryContext) => (
                  <InnerDateField fieldProps={fieldProps} {...props} context={context} registryContext={registryContext} />
                )}
              </RegistryContext.Consumer>

            )}
          </CompositeField>
        )}
      />
  );
};

class InnerDateField extends Component {
  state = {
    showCalendar: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (state.previousValue !== props.fieldProps.field.value) {
      return {
        previousValue: props.fieldProps.field.value,
        rawValue: isDate(props.fieldProps.field.value) ? dateFns.format(props.fieldProps.field.value, "dd/MM/yyyy") : "",
      };
    }

    return state;
  }

  onOpenCalendar = () => {
    this.setState({
      showCalendar: true,
    })
  };

  onCloseCalendar = () => {
    this.setState({
      showCalendar: false,
    })
  };

  handleChangePicker = (date) => {
    const {fieldProps, context} = this.props;
    const {field: {name}} = fieldProps;
    const {label: {handleBlur}} = context;

    fieldProps.form.setFieldValue(name, date);
    handleBlur(date, name);

    this.setState({
      showCalendar: false,
    });
  };

  handleChangeText = (e) => {
    this.setState({
      rawValue: e.target.value,
    });
  };

  handleBlur = () => {
    const {fieldProps, context} = this.props;
    const {label: {handleBlur}} = context;
    const {field: {name}, form: {setTouched, setFieldValue, touched}} = fieldProps;
    const [day, month, year] = this.state.rawValue ? this.state.rawValue.split(/[./]/) : [];

    const date = new Date(year, parseInt(month) - 1, day);

    if (day && month && year && date instanceof Date && !isNaN(date) && dateFns.isBefore(date, dateFns.subDays(new Date(), 1))) {
      setFieldValue(name, date);

      handleBlur(date, name);
    } else {
      setFieldValue(name, null);

      handleBlur("", name);
    }

    setTouched(_.set(_.cloneDeep(touched), name, true));
  };

  onKeyDown = (e) => {
    if (e.key === "Tab") {
      this.handleBlur();
    }
  };

  render() {
    const {fieldProps, placeholder, modal = false, context, registryContext, isRequired, legalAgeField = false} = this.props;
    const initialDate = legalAgeField ? dateFns.startOfMonth(dateFns.subYears(new Date(), registryContext.registry.birthDateDefaultAge)) : undefined
    const openToDate = isUndefinedOrNull(fieldProps.field.value) ? initialDate : fieldProps.field.value;

    if (isMobile) {
      return (
        <>
          <Layout flex={1}>
            <Layout.RowChildren>
              <Layout.Row>
                <Layout.Column width="calc(100% - 70px)">
                  <InnerTextField value={this.state.rawValue}
                                  onChange={this.handleChangeText}
                                  handleBlur={this.handleBlur}
                                  context={context}
                                  placeholder={placeholder}
                                  isRequired={isRequired}/>
                </Layout.Column>
                <Layout.Column width="15px"></Layout.Column>
                <Layout.Column width="55px">
                  <Button onClick={this.onOpenCalendar}
                          icon={<IcoMoon icon={fontConstants.ICOMOON.CALENDAR}/>}
                          variant="variant17"/>
                </Layout.Column>
              </Layout.Row>
            </Layout.RowChildren>
          </Layout>
          <Modal visible={this.state.showCalendar} onClose={this.onCloseCalendar} date="true">
            <Wrapper modal="true">
              <Picker
                maxDate={dateFns.subDays(new Date(), 1)}
                openToDate={openToDate}
                inline
                fixedHeight
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                dropdownMode="select"
                dateFormatCalendar="MMMM"
                dateFormat={["dd/MM/yyyy", "dd.MM.yyyy", "d.M.yyyy", "dd.M.yyyy", "d.MM.yyyy", "d/M/yyyy", "dd/M/yyyy", "d/MM/yyyy"]}
                selected={fieldProps.field.value ? new Date(fieldProps.field.value) : undefined}
                onChange={this.handleChangePicker}
                strictParsing={true}/>
            </Wrapper>
          </Modal>
        </>
      );
    } else {
      return (
        <Layout flex={1} spacing={10} width="100%">
          <Layout.RowChildren width="100%">
            <Layout.OneRow width="100%">
              <div className={cx({[styles.modalPopper]: modal})}>
                <Picker
                  customInput={<InnerTextField context={context} isRequired={isRequired} handleBlur={this.handleBlur}/>}
                  placeholderText={placeholder}
                  maxDate={dateFns.subDays(new Date(), 1)}
                  openToDate={openToDate}
                  fixedHeight
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  dropdownMode="select"
                  dateFormatCalendar="MMMM"
                  dateFormat={["dd/MM/yyyy", "dd.MM.yyyy", "d.M.yyyy", "dd.M.yyyy", "d.MM.yyyy", "d/M/yyyy", "dd/M/yyyy", "d/MM/yyyy"]}
                  selected={fieldProps.field.value ? new Date(fieldProps.field.value) : undefined}
                  onChange={this.handleChangePicker}
                  onBlur={this.handleBlur}
                  onKeyDown={this.onKeyDown}
                  strictParsing={true}/>
              </div>
            </Layout.OneRow>
          </Layout.RowChildren>
        </Layout>
      );
    }
  }
}
