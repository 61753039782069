import React from "react"

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import Address from "components/step/Address";
import TaxNumber from "components/step/TaxNumber";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import {capitalize} from "utility/textTransform";
import isUndefinedOrNull, {isSloAddress} from "utility/utilityFunctions";

export default function LegalPerson({slovenia, loggedInFromCounter, values}) {
  const address = isUndefinedOrNull(values.legalPerson) ? null : values.legalPerson.address;

  return (
    <Layout spacing={5} width="100%" maxWidth={450} justifyContent="center">
      <Layout.OneColumn spacing={20}/>
      <Layout.OneColumn>
        <Text element="h4" label="userIdentification.content.noAs.title.legal" variant="variant2"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="legalPerson.name"
                   transform={capitalize}
                   placeholder="userIdentification.content.legalPerson.name.placeholder"
                   label="userIdentification.content.legalPerson.name.label"
                   isRequired={true}
                   maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].name + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Address name="legalPerson.address"
                 isRequired={!loggedInFromCounter}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="legalPerson.telephoneNumber"
                   placeholder="userIdentification.content.legalPerson.telephoneNumber.placeholder"
                   label="userIdentification.content.legalPerson.telephoneNumber.label"
                   isRequired={!loggedInFromCounter}
                   maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].telephoneNumber + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <Text element="span" variant="variant8" label="userIdentification.content.telephoneNumber.info"/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="legalPerson.email"
                   placeholder="userIdentification.content.legalPerson.email.placeholder"
                   label="userIdentification.content.legalPerson.email.label"
                   isRequired={!loggedInFromCounter}
                   maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].email + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TaxNumber name="legalPerson.taxNumber"
                   placeholder="userIdentification.content.taxNumber.placeholder"
                   label="userIdentification.content.taxNumber.label"
                   isRequired={!loggedInFromCounter}
                   isSlovenia={isSloAddress(address, slovenia)}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        <TextField name="legalPerson.policyNumber"
                   placeholder="userIdentification.content.legalPerson.policyNumber.placeholder"
                   label="userIdentification.content.legalPerson.policyNumber.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.USER_IDENTIFICATION.NAME].policyNumber + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
      <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
        <Text element="span" variant="variant8" label="userIdentification.content.check"/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
      {
        loggedInFromCounter
          ? <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
            <Text element="span" variant="variant8" label="userIdentification.taxNumber.info"/>
          </Layout.OneColumn>
          : null
      }
      <Layout.OneColumn spacing={30}></Layout.OneColumn>
    </Layout>
  );
}
