import React, {Component} from "react";

import {FieldArray} from "formik";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import OwnVehiclePassenger from "containers/step/ownVehiclePassengers/OwnVehiclePassenger";

export default class OwnVehiclePassengersRight extends Component {
  render() {
    const {values, onNextStep} = this.props;

    return (
      <Layout spacing={10}>
        <Layout.OneColumn flex={1}>
          <NextStepButton component={ToggleButton}
                          onNextStep={onNextStep}
                          isValid={true}
                          condition={value => value === booleanTypesConstants.NO}
                          buttonProps={{name: "exist"}}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
              <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={values.exist}>
            <OwnVehiclePassengersChoice exist={values.exist}/>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}

function OwnVehiclePassengersChoice({exist}) {
  if (exist !== booleanTypesConstants.YES) {
    return null;
  }

  return (
    <FieldArray name="passengers" render={arrayHelpers => (
      <OwnVehiclePassengers arrayHelpers={arrayHelpers}/>
    )}/>
  );
}

class OwnVehiclePassengers extends Component {
  add = () => {
    this.props.arrayHelpers.push({});
  };

  render() {
    const {arrayHelpers} = this.props;

    const passengers = arrayHelpers.form.values.passengers;

    const values =
      passengers.map((passenger, i) => (
        <FadeView.Child childKey={i} key={i}>
          <OwnVehiclePassenger index={i} onRemove={() => arrayHelpers.remove(i)} showRemove={passengers.length > 1}/>
        </FadeView.Child>
      ));

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn>
          <FadeView>
            {values}
          </FadeView>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={20}></Layout.OneColumn>
        <Layout.OneColumn textAlign="right">
          <Button variant="variant10" label="ownVehiclePassengers.button.add" onClick={this.add}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}