import React, {Component} from "react";

import * as Yup from "yup";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import VehicleInfoContainer from "components/step/VehicleInfoContainer";
import booleanTypesConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import Header from "containers/step/Header";
import OtherParticipantsInfoRight from "containers/step/otherParticipantsInfo/OtherParticipantsInfoRight";
import SplitViewContainer from "containers/view/SplitViewContainer";
import {getMax} from "selectors/yup";

const otherParticipantsValidationSchema = Yup.object().shape({
  name: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME].name),
  surname: getMax(Yup.string(), fieldLengthConstants[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME].surname),
  address: Yup.object().shape({
    address: Yup.object().shape({
      streetNumber: getMax(Yup.string().nullable(), fieldLengthConstants[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME].streetNumber),
      streetName: getMax(Yup.string().nullable(), fieldLengthConstants[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME].streetName),
    }),
    city: Yup.object().shape({
      zip: getMax(Yup.string().nullable(), fieldLengthConstants[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME].zip),
      city: getMax(Yup.string().nullable(), fieldLengthConstants[flowConstants.steps.OTHER_PARTICIPANTS_INFO.NAME].city),
    }),
  }),
});

const validationSchema = Yup.object().shape({
  otherParticipantsInfo: Yup.object()
    .when("otherParticipantsInfoType", {
      is: booleanTypesConstants.YES,
      then: otherParticipantsValidationSchema,
    }),
});


// step 24
export default class OtherParticipantsInfoStepContainer extends Component {
  left = ({fromSummary, step}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="otherParticipantsInfo.sidebar.title"/>
        <Text element="div" label="otherParticipantsInfo.sidebar.subtitle"/>
        <VehicleInfoContainer {...step.participant}/>
      </SplitViewContainer.Left>
    );
  };

  right(props) {
    return (
      <SplitViewContainer.Right {...props}>
        <OtherParticipantsInfoRight {...props}/>
      </SplitViewContainer.Right>
    );
  }

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}
