import React, {Component} from "react";

export const SummaryContext = React.createContext({});

export default class SummaryContainer extends Component {
  renderedRefs = React.createRef();

  constructor(props) {
    super(props);

    this.renderedRefs.current = {};
  }

  addRef = (name, ref) => {
    this.renderedRefs.current[name] = ref;
  };

  getRefs = () => {
    return this.renderedRefs.current;
  };

  state = {
    renderedRefs: {},
    context: {
      addRef: this.addRef,
      getRefs: this.getRefs,
    },
  };

  render() {
    const {children} = this.props;

    return (
      <SummaryContext.Provider value={this.state.context}>
        {children(this.state.context)}
      </SummaryContext.Provider>
    );
  }
}
