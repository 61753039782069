import React from "react";

import cx from "classnames";
import { isMobile } from "react-device-detect";

import MapSearch from "components/step/map/MapSearch";

import style from "components/map/MapSearchForm.module.scss";

export default function MapForm({name, markers, searchSelected, children}) {
  const className = cx({
    [style.root]: true,
    [style.isMobile]: isMobile,
  });

  return (
    <div className={className}>
      <div className={style.search}>
        <MapSearch name={name} markers={markers} searchSelected={searchSelected}/>
      </div>
      <div className={style.map}>
        {children}
      </div>
    </div>
  );
}