import React from "react";

import {FieldArray} from "formik";

import Layout from "components/common/Layout";
import ConditionalField from "components/form/ConditionalField";
import FormErrorMessage from "components/form/FormErrorMessage";
import UploadField from "components/form/UploadField";
import IcoMoon from "components/icon/IcoMoon";
import Documents from "components/step/Documents";
import fontConstants from "constants/font";
import {UploadContext} from "containers/context/UploadContainer"

export default function PersonDeathDocumentsRight({values, saveId}) {
  const {deathCertificate = [], deathReport = [], inheritanceDeal = [], medicalDocuments = [], other = []} = values;
  const acceptedTypes = ["doc", "docx", "pdf", "jpg", "jpeg", "bmp", "tiff", "png", "xlsx", "xls"].map(x => `.${x}`).join(", ");

  return (
    <Layout spacing={10} width="100%" maxWidth={450} justifyContent="center">
      <ConditionalField name="documents">
        <Layout.OneColumn>
          <UploadField name="deathCertificate"
                       label="personDocuments.button.uploadDeathCertificate"r
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="deathCertificate" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={deathCertificate}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="deathReport"
                       label="personDocuments.button.uploadDeathReport"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="deathReport" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={deathReport}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="inheritanceDeal"
                       label="personDocuments.button.uploadInheritanceDeal"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="inheritanceDeal" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={inheritanceDeal}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="medicalDocuments"
                       label="personDocuments.button.uploadMedicalDocuments"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="medicalDocuments" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={medicalDocuments}/>
          )}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <UploadField name="other"
                       label="personDocuments.button.uploadOther"
                       saveId={saveId}
                       icon={<IcoMoon icon={fontConstants.ICOMOON.UPLOAD}/>}
                       accept={acceptedTypes}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FieldArray name="other" render={arrayHelpers => (
            <Documents arrayHelpers={arrayHelpers}
                       documents={other}/>
          )}/>
        </Layout.OneColumn>
      </ConditionalField>
      <UploadContext.Consumer>
        {context => (<FormErrorMessage error={context.error} showError={context.error.length > 0}/>)}
      </UploadContext.Consumer>
      <Layout.OneColumn spacing={10}></Layout.OneColumn>
    </Layout>
  );
}
