import React, {Component} from "react";

import NextStepButton from "components/common/NextStepButton";
import Layout from "components/common/Layout";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "constants/booleanTypes";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";

export default class HomeSomeoneElseResponsibleStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="homeSomeoneElseResponsible.sidebar.title"/>
        <Text element="div" label="homeSomeoneElseResponsible.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    const {onNextStep, isValid} = props;
    return (
      <SplitViewContainer.Right showNextStepButton={false} {...props}>
        <NextStepButton component={ToggleButton}
                        onNextStep={onNextStep}
                        isValid={isValid}
                        buttonProps={{name: "someoneElseResponsible"}}>
          <Layout.OneRow spacing={10} flex={1}>
            <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
            <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
            <ToggleButton.Choice value={booleanTypesConstants.DONT_KNOW} label="button.dontKnow"/>
          </Layout.OneRow>
        </NextStepButton>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}