import React from "react";

import Layout from "components/common/Layout";
import Link from "components/common/Link";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";

export default function ReferralNumber({index, onRemove, showRemove}) {
  return (
    <Layout width="100%" spacing={10}>
      <Layout.OneColumn>
        <TextField name={`referralNumbers[${index}].name`}
                   placeholder="referralNumber.name.placeholder"
                   label="referralNumber.name.label"
                   maxLength={fieldLengthConstants[flowConstants.personSteps.REFERRAL_NUMBERS.NAME].referralNumber + 1}
                   number={true}/>
      </Layout.OneColumn>
      <Layout.OneColumn>
        {showRemove ? <Link label="referralNumber.button.remove" onClick={onRemove}/> : null}
      </Layout.OneColumn>
      <Layout.OneColumn spacing={20}/>
    </Layout>
  );
}
