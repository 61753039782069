import React from "react";

import Svg, {Circle, Path, Polygon} from "components/svg/Svg";

export default function BikeSvg(props) {
  return (
    <Svg {...props} viewBox="0 0 647.43 414.5">
      <Polygon part={0}
               points="259.44 62.5 226.48 53.5 155.44 53.5 164.7 74.5 196.56 68.46 209.97 107.73 228.9 101.27 217.35 67.44 251.2 70.5 259.44 62.5"/>
      <Path part={1}
            d="M512.93,160a123.65,123.65,0,0,0-23.24,2.2l7.55,20a102.11,102.11,0,1,1-30.48,9.86l-7.47-19.78A123,123,0,1,0,512.93,160Z"/>
      <Circle part={2} cx="512.93" cy="283" r="29.5"/>
      <Path part={3}
            d="M256.24,274.09a122.63,122.63,0,0,0-39.83-82.94L204,208.37a101.81,101.81,0,0,1,31,63.79Z"/>
      <Path part={4}
            d="M133.5,384a102,102,0,1,1,44.24-193.9l12.44-17.28a123,123,0,1,0,64,133.21l-21.07-1.91A102.18,102.18,0,0,1,133.5,384Z"/>
      <Circle part={5} cx="133.37" cy="282" r="28"/>
      <Path part={6}
            d="M457.93,16.5a11.5,11.5,0,0,0-10,5.82L405.38,36.51,410.6,52l41.77-13.93a11.5,11.5,0,1,0,5.56-21.56Z"/>
      <Path part={7}
            d="M433.94,60.39l-19,6.22,2.82,8.62-179,54.87-4.9-13.92L215,122.82l6.34,18L143.18,249.45a34.06,34.06,0,0,1,16.6,11.17l69.66-96.83,39.4,111.85a26,26,0,0,0-4.14,7.84l-98.16-8.92A33.84,33.84,0,0,1,165,294.5l101.51,9.23A26,26,0,1,0,311,277L427.53,105.1l5.4,16.51,19-6.22ZM294.36,266a26.59,26.59,0,0,0-4.93-.47,25.23,25.23,0,0,0-2.9.17L245.42,149,407.24,99.37Z"/>
      <Path part={8}
            d="M470.79,132l-.1,0-.14-.38-31.44,11.53,5.5,15,12.91-4.73,37.38,99a35.32,35.32,0,0,1,18-4.94c.51,0,1,0,1.5,0Z"/>
    </Svg>
  );
}
