import React, {Component} from "react";

import Layout from "components/common/Layout";
import ToggleButton from "components/form/ToggleButton";
import LegalPerson from "components/step/LegalPerson";
import FadeView from "components/view/FadeView";
import flowConstants from "constants/flow";
import responsiblePersonTypes from "constants/responsiblePersonTypes";
import NaturalPerson from "containers/step/homeResponsiblePerson/NaturalPerson"

export default class HomeResponsiblePersonRight extends Component {
  render() {
    const {values} = this.props;

    return (
      <Layout spacing={10}>
        <Layout.OneColumn flex={1}>
          <ToggleButton name="responsiblePersonChoice">
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={responsiblePersonTypes.NATURAL_PERSON}
                                   label="homeResponsiblePerson.button.naturalPerson"/>
              <ToggleButton.Choice value={responsiblePersonTypes.LEGAL_PERSON}
                                   label="homeResponsiblePerson.button.legalPerson"/>
            </Layout.OneRow>
          </ToggleButton>
        </Layout.OneColumn>
        <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
          <FadeView step={values.responsiblePersonChoice}>
            {values.responsiblePersonChoice === responsiblePersonTypes.NATURAL_PERSON
              ? <NaturalPerson responsiblePersonChoice={values.responsiblePersonChoice} values={values}/>
              : <LegalPerson responsiblePersonChoice={values.responsiblePersonChoice}
                             stepName={flowConstants.homeSteps.HOME_RESPONSIBLE_PERSON.NAME}/>
            }
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}