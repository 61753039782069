import React from "react";

import Svg, {Polygon, Path} from "components/svg/Svg";

export default function MotorcycleSvg(props) {
  return (
    <Svg {...props} viewBox="0 0 438.5 261.79">
      <g>
        <Path part={0} d="M308.86,127.28a.94.94,0,0,0,.1-.1l-3,.06Z"/>
        <Polygon part={1}
                 points="122.08 98.28 190.28 129 191.36 125.33 205.28 78.04 116.83 64.55 121.14 92.22 122.08 98.28"/>
        <Path part={2}
              d="M195.53,129,306,127.24l3-.06a86.27,86.27,0,0,1,7.7-6.27,83.31,83.31,0,0,1,13.9-8.09L311.71,61.55c-5.25-15-57.72-21-100.44,13.49l-14.69,50.37Z"/>
        <Path part={3}
              d="M295.23,143.45a83,83,0,0,0-10.44,65.75c.25.94.5,1.87.78,2.79.71,2.36,1.53,4.68,2.44,7H157c.92-2.28,1.74-4.6,2.44-6.95.28-.92.54-1.86.79-2.8.09-.34.17-.68.25-1s.17-.69.25-1c0-.18.09-.37.13-.55.12-.54.24-1.1.35-1.65.08-.39.16-.77.23-1.16s.1-.48.14-.72h0c0-.24.09-.48.12-.72.09-.47.16-.94.24-1.41s.13-.88.19-1.33.14-1.05.2-1.56.09-.82.14-1.23c.06-.57.11-1.15.16-1.72l.09-1.12c0-.65.07-1.29.1-1.94,0-.3,0-.61,0-.93,0-1,.05-1.91.05-2.87s0-2-.05-3l0-.88c0-.7-.07-1.39-.11-2.08,0-.3-.05-.61-.08-.92l-.18-2c0-.25-.07-.51-.1-.77-.08-.73-.17-1.45-.28-2.18,0-.19-.06-.37-.08-.56-.12-.8-.26-1.6-.4-2.39,0-.09,0-.18,0-.28-.1-.55-.21-1.09-.32-1.63a.35.35,0,0,0,0-.12l-.27-1.31c0-.18-.09-.37-.13-.56-.09-.4-.19-.79-.29-1.19s-.19-.8-.3-1.19-.21-.79-.32-1.18c-.18-.66-.37-1.31-.57-2-.07-.27-.15-.54-.24-.8-.14-.43-.27-.86-.42-1.29l-.34-1c-.11-.34-.23-.67-.35-1l-.33-.89-.33-.87-.31-.78c-.18-.45-.36-.89-.55-1.34a1.71,1.71,0,0,0-.07-.18l-.75-1.7c-.29-.67-.6-1.33-.92-2-.15-.29-.28-.57-.42-.85l-.15-.31-.54-1-.81-1.53c-.36-.66-.73-1.32-1.11-2l-.93-1.56-.07-.11-.55-.87c-.41-.66-.84-1.32-1.27-1.95-.14-.22-.29-.44-.44-.65-.29-.43-.59-.85-.89-1.27s-.45-.63-.68-.94c-.35-.49-.71-1-1.09-1.44l-.32-.42c-.07-.1-.16-.2-.24-.3-.31-.42-.65-.83-1-1.23-.19-.24-.37-.47-.57-.7l-.85-1c-.61-.71-1.24-1.42-1.88-2.11-.12-.13-.24-.26-.38-.39a.63.63,0,0,0-.11-.12c-.34-.38-.69-.75-1.06-1.11l-.14-.15-1-1a.71.71,0,0,0-.13-.12c-.35-.34-.68-.67-1-1l-.61-.58c-.28-.27-.57-.52-.85-.78-.51-.45-1-.9-1.53-1.34s-.93-.78-1.4-1.17l-.57-.47-.58-.46-.68-.53c-1.28-1-2.59-2-3.93-2.85l-.67-.46q-2.51-1.69-5.16-3.19l-1-.54c-.57-.32-1.15-.63-1.73-.93s-1-.5-1.46-.74l3.67-11.25L187.29,135l115.42-1.5C301.05,135.61,296.69,141.18,295.23,143.45Z"/>
        <Path part={4}
              d="M159.08,163.21c-.09-.24-.17-.49-.25-.73.22.69.44,1.38.65,2.08,0-.19-.1-.37-.16-.55S159.17,163.47,159.08,163.21Z"/>
        <Path part={5}
              d="M158.66,161.92c.06.19.12.37.17.56s.16.49.25.73C158.94,162.78,158.81,162.35,158.66,161.92Z"/>
        <Path part={6}
              d="M159.48,164.56c.14.47.28.94.41,1.41-.18-.66-.37-1.31-.57-2C159.38,164.19,159.43,164.37,159.48,164.56Z"/>
        <Path part={7} d="M160.23,209.2h0c.09-.34.17-.68.25-1C160.41,208.52,160.32,208.86,160.23,209.2Z"/>
        <Path part={8}
              d="M153.12,185.3q-.12-3-.48-6c-.07-.65-.16-1.3-.26-2s-.2-1.3-.31-2-.27-1.42-.41-2.12-.24-1.15-.38-1.71-.29-1.27-.46-1.89a73.16,73.16,0,0,0-24.18-37.81l-.74-.6c-.25-.21-.52-.41-.77-.61-.49-.38-1-.76-1.48-1.13l-.15-.11q-.78-.59-1.59-1.14c-.33-.24-.67-.47-1-.69a75.25,75.25,0,0,0-8.34-4.84l-1.39-.7a73.08,73.08,0,1,0,39,87.14c.21-.69.4-1.38.58-2.08.1-.36.19-.72.28-1.08,0-.1.05-.2.08-.3.15-.61.29-1.22.42-1.84l0-.09c.07-.35.15-.7.21-1.05s.11-.5.15-.75c.1-.48.18-1,.26-1.46l.18-1c.06-.45.12-.9.19-1.35s.11-.79.15-1.18.1-.84.14-1.26.09-.89.12-1.33.07-.76.09-1.14c0-.53.07-1.06.09-1.58,0-.31,0-.63,0-.93,0-.85,0-1.7,0-2.54s0-1.76,0-2.63A2.64,2.64,0,0,1,153.12,185.3Z"/>
        <Path part={9}
              d="M80.11,235.06a46.85,46.85,0,1,1,19.62-89.39l1.15.58a49.37,49.37,0,0,1,5.4,3.13l.28.19.25.18.34.23.53.38.45.33.82.62-.16-.12.72.57.52.43-.1-.08a46.55,46.55,0,0,1,15.51,24.22l.1.38c0,.19.1.4.14.6l.09.38c0,.2.1.41.14.62l0,.19c.1.49.21,1,.3,1.48s.13.84.2,1.26l.15,1.09v.15c.15,1.18.25,2.42.3,3.69,0,.24,0,.47,0,.67,0,.46,0,.92,0,1.38s0,1,0,1.45l0,.88c0,.29,0,.57,0,.86l-.08.94v-.15l-.07.83c0,.24,0,.47-.08.7l0,.29c0,.19,0,.37-.07.57l-.13.83c0,.11-.12.77-.12.77,0,.26-.08.48-.12.7l0,.16c-.06.28-.11.55-.16.8,0,.06-.09.41-.12.54-.06.28-.12.56-.19.83l-.12.5c0,.18-.09.36-.14.54-.12.47-.23.89-.36,1.3l0,.13A47.09,47.09,0,0,1,80.11,235.06Z"/>
        <Path part={10} d="M150.81,206.73c.06-.23.12-.46.17-.69-.09.36-.18.72-.28,1.08Z"/>
        <Path part={11}
              d="M396.18,122.15a73.25,73.25,0,0,0-56.11-2.67c-2.09.76-4.13,1.6-6.13,2.54a71.48,71.48,0,0,0-8.81,4.91l-.92.61c-.63.42-1.25.84-1.87,1.28a74.58,74.58,0,0,0-18.92,19.9,73.08,73.08,0,1,0,92.76-26.57Z"/>
        <Path part={12}
              d="M364.92,235.06a47,47,0,0,1-42.5-27.16,46.49,46.49,0,0,1-2.38-6.21,46.65,46.65,0,0,1,11.42-46.23,47.78,47.78,0,0,1,6.17-5.32l1.15-.79.73-.48a46.34,46.34,0,0,1,5.56-3.09c1.29-.61,2.61-1.15,3.95-1.64a46.85,46.85,0,1,1,15.9,90.92Z"/>
        <Path part={13}
              d="M416.78,93.78c3.76,11.24,2.25,20.37-6.68,25a82.86,82.86,0,0,0-73.48-8.48l-18.17-48S331.19,39.06,307.21.84c33.07,9.41,68.68,36.05,90.53,62C387.8,65,380.66,71,380.66,78,380.66,78,380.19,94.53,416.78,93.78Z"/>
        <Path part={14}
              d="M400.08,65.38l-1.69.37-1,.25c-8.13,2.07-13.72,6.93-13.72,12v.08c0,.07.53,12.21,29.06,12.67h2.59A105.45,105.45,0,0,0,400.08,65.38Z"/>
        <g>
          <Path part={15}
                d="M116.83,96.78l-1.29-7.27-10-55.69L13.4,25.57l1.11,7.26a21.73,21.73,0,0,1,4.25.2,20.09,20.09,0,0,1,11.93,6.11,13.75,13.75,0,0,1,3.79,11.39c-1,6.84-7.51,11.77-15.39,12.34l1.06,6.92,92.19,40.48Z"/>
          <Path part={16}
                d="M31.52,50.09a10.81,10.81,0,0,0-3-8.92A17.13,17.13,0,0,0,18.32,36a18,18,0,0,0-2.82-.22l-.54,0,3.67,24.08C25.25,59.5,30.7,55.59,31.52,50.09Z"/>
        </g>
      </g>
    </Svg>
  );
}
