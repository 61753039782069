import React, {Component} from "react";

import {isEmpty} from "underscore";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Text from "components/common/Text";
import carDamageTypeConstants from "constants/carDamageTypes";
import claimTypesConstants from "constants/claimTypes";
import cookie from "constants/cookie";

import mail from "images/mail.svg";
import styles from "containers/step/thankYou/ThankYouStepContainer.module.scss";

export default class ThankYouRight extends Component {
  componentDidMount() {
    document.body.onbeforeunload = "return false";
  }

  redirectToBeginning = async () => {
    window.location.replace(window.location.origin);
  };

  continueForm = async () => {
    const {submitId} = this.props;

    sessionStorage.setItem(cookie.SUBMIT_ID, submitId);

    window.location.replace(window.location.origin);
  };

  render() {
    const {loggedInFromCounter, values, claimType} = this.props;
    const additionalTextLabelParameters = [];
    let additionalTextLabel = `thankYou.content.confirmation.additionalText.${claimType}`;

    if (claimType === claimTypesConstants.CAR) {
      if (values.carDamageType === carDamageTypeConstants.THEFT) {
        additionalTextLabel += ".theft";
      } else {
        if (values.repairServiceType && !isEmpty(values.repairServiceSelectionNameAndAddress)) {
          additionalTextLabel += `.${values.repairServiceType}`;
          additionalTextLabelParameters.push(`<b>${values.repairServiceSelectionNameAndAddress}</b>`);
        } else {
          additionalTextLabel += ".noRepairServiceSelected";
        }
      }
    }

    return (
      <Layout spacing={10} flex={1}>
        <Layout.OneColumn>
          <div className={styles.icon}>
            <img src={mail} alt="mail"/>
          </div>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={30}></Layout.OneColumn>
        <Layout.OneColumn>
          <Text element="h3" variant="variant1" className={styles.title} label="thankYou.content.confirmation"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <p>
            <Text element="span" className={styles.subTitle} label={additionalTextLabel} parameters={additionalTextLabelParameters} isHtml={true}/>
          </p>
          <Text element="p" className={styles.subTitle} label="thankYou.content.thankYou"/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={20}></Layout.OneColumn>
        <Layout.OneColumn spacing={10} textAlign="right">
          {loggedInFromCounter
            ? <Button onClick={this.redirectToBeginning}
                      label="thankYou.content.continue.button.newForm"/>
            : <Button onClick={this.continueForm}
                      label="thankYou.content.continue.button.continueForm.noCounter"/>
          }
        </Layout.OneColumn>
        {loggedInFromCounter
          ? <Layout.OneColumn spacing={10} textAlign="right">
            <Button onClick={this.continueForm}
                    label="thankYou.content.continue.button.continueForm.counter"
                    variant="variant27"/>
          </Layout.OneColumn>
          : null
        }
      </Layout>
    );
  }

  redirectToRegistration = () => {
    const {configContext} = this.props;

    window.location.href = configContext.url.registration;
  };
}