import React, {Component} from "react";

import Header from "containers/step/Header";
import Layout from "components/common/Layout";
import SplitViewContainer from "containers/view/SplitViewContainer";
import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";

// step 20
export default class MechanicalDamageOwnStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="mechanicalDamage.own.sidebar.title"/>
        <Text element="div" label="mechanicalDamage.own.sidebar.subtitle"/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props} showNextStepButton={false}>
        <Layout>
          <Text element="div" variant="variant10" label="mechanicalDamage.own.content" isHtml={true}/>
        </Layout>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
