import React, {Component} from "react";

import {Field} from "formik";

import CheckBox from "components/form/CheckBox/CheckBox";


export default function CheckBoxField({name, label, isHtml, variant = "variant12", ...props}) {
  const labelProps = {
    element: "span",
    variant,
    label,
    isHtml,
  };

  return (
    <Field
      name={name}
      render={(fieldProps) => (
        <InnerCheckBoxField fieldProps={fieldProps}
                            {...props}
                            name={name}
                            labelProps={labelProps}/>
      )}
    />
  );
}

class InnerCheckBoxField extends Component {
  onClick = () => {
    const {fieldProps: {field, form}, name, isDisabled = false} = this.props;
    const {setFieldValue} = form;

    if (!isDisabled) {
      const checked = field.value === true;

      setFieldValue(name, !checked);
    }
  };

  render() {
    const {fieldProps: {field: {value}}, labelProps, variant} = this.props;

    return (
      <CheckBox variant={variant}
                labelProps={labelProps}
                checked={value}
                onChange={() => null}
                onClick={this.onClick}/>
    );
  }
}