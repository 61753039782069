import React, {Component} from "react";

import Layout from "components/common/Layout";
import LinkField from "components/form/LinkField";
import SvgField from "components/form/SvgField";
import PersonSvg from "components/svg/PersonSvg";
import {range} from "underscore";

export default class PersonDamageSelection extends Component {
  onSelectAll = value => {
    const {setFieldValue} = this.props;

    setFieldValue("personDamage", value ? range(0, 57) : []);
  };

  render() {
    const {selectAll = true} = this.props;

    return (
      <Layout justifyContent="center">
        <Layout.OneColumn width="100%" maxWidth={350}>
          <SvgField name="personDamage"
                    component={PersonSvg}/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={30}/>
        {selectAll
          ? <Layout.RowChildren spacing={10}>
            <LinkField name="personDamageSelectAll" onClick={this.onSelectAll}>
              <LinkField.Item value={false}
                              label="personDamage.content.selectAll"/>
              <LinkField.Item value={true}
                              label="personDamage.content.selectAll"/>
            </LinkField>
          </Layout.RowChildren>
          : null}
      </Layout>
    );
  }
}
