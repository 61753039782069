import React from "react";

import Svg, {Circle, Path, Text} from "components/svg/Svg";

export default function CarSvg(props) {
  return (
    <Svg {...props} viewBox="0 0 441 563.17">
      <g>
        <Text x="50%" y="20" textAnchor="middle">Spredaj</Text>
      </g>
      <g transform="translate(0 33)">
        <g>
          <g id="Odbijac-zadaj-levo">
            <g>
              <Path part={0}
                    d="M150.75,459.47a24,24,0,0,0-33.2-22.16c3.55,14.57,10,31,21.81,42.57A24,24,0,0,0,150.75,459.47Z"/>
              <Path part={1}
                    d="M126.75,431.47a28,28,0,0,1,15.66,51.2,52.21,52.21,0,0,0,28.79,12.16c-.82-31.62-12.33-76.61-34.28-90.37a79.45,79.45,0,0,0-23.67-9.79V404a152.91,152.91,0,0,0,3.4,29.39A27.92,27.92,0,0,1,126.75,431.47Z"/>
            </g>
            <g>
              <Path part={2}
                    d="M289.2,459.47a24,24,0,0,1,33.2-22.16c-3.55,14.57-10,31-21.82,42.57A24,24,0,0,1,289.2,459.47Z"/>
              <Path part={3}
                    d="M313.2,431.47a28,28,0,0,0-15.66,51.2,52.21,52.21,0,0,1-28.79,12.16c.81-31.62,12.33-76.61,34.28-90.37a79.48,79.48,0,0,1,23.72-9.79V404a152.91,152.91,0,0,1-3.4,29.39A28,28,0,0,0,313.2,431.47Z"/>
            </g>
          </g>
          <g id="Odbijac-spredaj">
            <Path part={4}
                  d="M234.25,20.45h34C267.75,14,267.63,8,267.62,3H173.38c0,5.07-.16,11-.62,17.48h61.49Z"/>
          </g>
          <g id="Odbijac-spredaj-levo">
            <Path part={5} d="M365.25,160.47c0-21-32-18-32-18v22h32Z"/>
            <Path part={6} d="M107.75,142.47s-32-3-32,18v4h32Z"/>
            <Path part={7}
                  d="M276.75,229.88a17.28,17.28,0,0,1-2.49.18H166.75a17.28,17.28,0,0,1-2.49-.18L164.43,333h.18l.14,54H276.26l.13-54h.18Z"/>
            <Path part={8}
                  d="M327.75,158a381.48,381.48,0,0,0-2.12-40.69,60.15,60.15,0,0,1-26.43-14.43l-17,39.05c.76,2.16,1.61,4.4,2.59,7a356.44,356.44,0,0,1,13.87,44c2.95,11.82,1.74,21.6-3.49,28.31a22.66,22.66,0,0,1-13.44,8l-.18,52.27h46.2Z"/>
            <Path part={9}
                  d="M159.25,229.2a22.66,22.66,0,0,1-13.44-8c-5.23-6.71-6.44-16.49-3.49-28.31a356.44,356.44,0,0,1,13.87-44c1-2.57,1.83-4.81,2.59-7l-17-39a60.15,60.15,0,0,1-26.43,14.43,379.6,379.6,0,0,0-2.1,40.73V281.5h46.18Z"/>
            <Path part={10}
                  d="M267.25,137h-93.5c-3.67,0-8.49,1.53-10,5.85-.85,2.44-1.8,4.93-2.89,7.82a347.94,347.94,0,0,0-13.69,43.44c-2.57,10.26-1.68,18.56,2.57,24,3.52,4.5,9.4,6.88,17,6.88H274.25c7.6,0,13.48-2.38,17-6.88,4.25-5.45,5.14-13.75,2.57-24a347.94,347.94,0,0,0-13.69-43.44c-1.09-2.89-2-5.38-2.89-7.82C275.75,138.5,270.92,137,267.25,137Z"/>
            <Path part={11}
                  d="M145.6,99l16.3,37.51c3.23-3.3,8-4.58,11.85-4.58h93.5c3.86,0,8.62,1.28,11.85,4.58L295.4,99h0c-17.89-19.57-24.52-49.4-26.75-73.55h-96.3C170.12,49.6,163.49,79.43,145.6,99Z"/>
            <g>
              <Path part={12}
                    d="M152.25,39.47a23.5,23.5,0,0,0-11.19-20c-7.9,8.93-15.13,22.34-19.78,42.29a23.5,23.5,0,0,0,31-22.28Z"/>
              <Path part={13}
                    d="M144.59,15.79a28.5,28.5,0,0,1-24.37,50.88A241.94,241.94,0,0,0,116,111.92a55.65,55.65,0,0,0,25.92-16.25c22.63-24.81,26.26-68.4,26.39-92.41A47,47,0,0,0,144.59,15.79Z"/>
            </g>
            <g>
              <Path part={14}
                    d="M287.75,39.47a23.5,23.5,0,0,1,11.19-20c7.9,8.93,15.13,22.34,19.77,42.29a23.44,23.44,0,0,1-31-22.29Z"/>
              <Path part={15}
                    d="M295.36,15.79a28.5,28.5,0,0,0,24.39,50.88A242,242,0,0,1,324,111.92a55.65,55.65,0,0,1-25.92-16.25c-22.65-24.81-26.31-68.4-26.41-92.41A47.11,47.11,0,0,1,295.36,15.79Z"/>
            </g>
            <g>
              <Path part={16}
                    d="M159.75,386.33l-.28-98.86H113.25v102a84.58,84.58,0,0,1,26.33,10.71,74.85,74.85,0,0,1,6.23,4.37Z"/>
              <Path part={17}
                    d="M174.38,473.47a190.52,190.52,0,0,1,1.84,22.64,121.31,121.31,0,0,0,27,2.86h34.53a121.31,121.31,0,0,0,27-2.86,190.52,190.52,0,0,1,1.84-22.64Z"/>
              <Path part={18}
                    d="M281.55,287.47,281.26,389,294,405.6a74.29,74.29,0,0,1,7.47-5.37,84.58,84.58,0,0,1,26.33-10.71v-102Z"/>
              <Path part={19}
                    d="M267.53,467.47H173.47c-4.79-37.25-23.14-58.9-23.14-58.9L163,392H276l13.5,17.71S271.38,432.47,267.53,467.47Z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <Circle part={20} cx="385.25" cy="102.84" r="37.5"/>
            <Circle part={21} cx="385.25" cy="102.84" r="20.5"/>
          </g>
          <g>
            <Circle part={22} cx="385.25" cy="394.67" r="37.5"/>
            <Circle part={23} cx="385.25" cy="394.67" r="20.5"/>
          </g>
        </g>
        <g>
          <g>
            <Circle part={24} cx="55.75" cy="102.84" r="37.5"/>
            <Circle part={25} cx="55.75" cy="102.84" r="20.5"/>
          </g>
          <g>
            <Circle part={26} cx="55.75" cy="394.67" r="37.5"/>
            <Circle part={27} cx="55.75" cy="394.67" r="20.5"/>
          </g>
        </g>
      </g>
      <g>
        <Text x="50%" y="556.17" textAnchor="middle">Zadaj</Text>
      </g>
    </Svg>
  );
}
