import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import VehicleFontIcon from "components/icon/VehicleFontIcon";
import carDamageTypeConstants from "constants/carDamageTypes";
import vehicleTypesConstants from "constants/vehicleTypes";

export default class VehicleTypeRight extends Component {
  state = {
    loaded: false,
  };

  componentDidMount() {
    this.setState({
      loaded: true,
    })
  }

  render() {
    const {onNextStep, values} = this.props;
    const {carDamageType} = values;

    return (
      <Layout width="100%" spacing={10} justifyContent="center">
        <Layout.OneColumn justifyContent="center">
          <Text element="h3" variant="variant1" label="vehicleType.content.title"/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={30}/>
        <Layout.OneColumn spacing={10} width="100%" textAlign="right">
          <NextStepButton component={ToggleButton}
                          isValid={true}
                          onNextStep={onNextStep}
                          buttonProps={{
                            name: "vehicleType",
                          }}>
            <Layout.OneRow spacing={5} flex="1" rowClassName="full-width-mobile">
              <ToggleButton.Choice value={vehicleTypesConstants.CAR}
                                   label={`vehicleType.content.vehicleType.${vehicleTypesConstants.CAR}`}
                                   icon={<VehicleFontIcon type={vehicleTypesConstants.CAR}/>}
                                   variant="variant28"/>
              <ToggleButton.Choice value={vehicleTypesConstants.SEMI_TRUCK}
                                   label={`vehicleType.content.vehicleType.${vehicleTypesConstants.SEMI_TRUCK}`}
                                   icon={<VehicleFontIcon type={vehicleTypesConstants.SEMI_TRUCK}/>}
                                   variant="variant28"/>
              <ToggleButton.Choice value={vehicleTypesConstants.MOTORCYCLE}
                                   label={`vehicleType.content.vehicleType.${vehicleTypesConstants.MOTORCYCLE}`}
                                   icon={<VehicleFontIcon type={vehicleTypesConstants.MOTORCYCLE}/>}
                                   variant="variant28"/>
              {
                carDamageType === carDamageTypeConstants.CAR_ACCIDENT
                  ? <ToggleButton.Choice value={vehicleTypesConstants.BIKE}
                                         label={`vehicleType.content.vehicleType.${vehicleTypesConstants.BIKE}`}
                                         icon={<VehicleFontIcon type={vehicleTypesConstants.BIKE}/>}
                                         variant="variant28"/>
                  : null
              }
              <ToggleButton.Choice value={vehicleTypesConstants.WORK_OTHER}
                                   label={`vehicleType.content.vehicleType.${vehicleTypesConstants.WORK_OTHER}`}
                                   icon={<VehicleFontIcon type={vehicleTypesConstants.TRACTOR}/>}
                                   variant="variant28"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
