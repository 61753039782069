import React from "react";

import cx from "classnames";

import fontConstants from "constants/font";
import vehicleTypesConstants from "constants/vehicleTypes";

import styles from "components/icon/VehicleFontIcon.module.scss";

const iconMapping = {
  [vehicleTypesConstants.SEMI_TRUCK]: fontConstants.VEHICLE_ICON.SEMI_TRUCK,
  [vehicleTypesConstants.CAR]: fontConstants.VEHICLE_ICON.CAR,
  [vehicleTypesConstants.MOTORCYCLE]: fontConstants.VEHICLE_ICON.MOTORCYCLE,
  [vehicleTypesConstants.SCOOTER]: fontConstants.VEHICLE_ICON.SCOOTER,
  [vehicleTypesConstants.BIKE]: fontConstants.VEHICLE_ICON.BIKE,
  [vehicleTypesConstants.PEDESTRIAN]: fontConstants.VEHICLE_ICON.PEDESTRIAN,
  [vehicleTypesConstants.TRACTOR]: fontConstants.VEHICLE_ICON.TRACTOR,
  [vehicleTypesConstants.COMPANY]: fontConstants.VEHICLE_ICON.COMPANY,
  [vehicleTypesConstants.WORK_OTHER]: fontConstants.VEHICLE_ICON.TRACTOR,
};

export default function VehicleFontIcon({className, icon, type}) {
  const containerClassName = cx({
    [styles.root]: true,
    [className]: true,
    [styles.vehicle]: type !== vehicleTypesConstants.COMPANY,
    [styles.company]: type === vehicleTypesConstants.COMPANY,
  });

  return (
    <div className={containerClassName}>
      {icon || iconMapping[type]}
    </div>
  );
}
