import React, {Component} from "react";

import Lottie from "react-lottie";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "constants/booleanTypes";

import styles from "containers/step/drawingIntro/DrawingIntroRight.module.scss";

import sketchVideo from "images/sketch-video.json";

export default class DrawingIntroRight extends Component {
  render() {
    const {onNextStep} = this.props;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: sketchVideo,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      }
    };

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn>
          <div className={styles.lottieWrapper}>
            <div className={styles.lottieSvg}>
              <Lottie options={defaultOptions}
                      width={"100%"}
                      isStopped={false}
                      isPaused={false}/>
            </div>
          </div>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <Text element="div" label="drawingIntro.content.subtitle" className={styles.text}/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={40}></Layout.OneColumn>
        <Layout.OneColumn>
          <NextStepButton onNextStep={onNextStep} component={ToggleButton} isValid={true} buttonProps={{name: "wantDrawing"}}>
            <Layout.OneRow spacing={10} justifyContent="center">
              <ToggleButton.Choice variant="variant13" value={booleanTypesConstants.YES} label="drawingIntro.button.draw"/>
              <ToggleButton.Choice variant="variant13" value={booleanTypesConstants.NO} label="drawingIntro.button.dontDraw"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
