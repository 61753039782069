import React, {Component} from "react";

import Layout from "components/common/Layout";
import TextField from "components/form/TextField";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import DateField from "components/form/DateField";
import SexField from "components/form/SexField";
import Address from "components/step/Address";
import TaxNumber from "components/step/TaxNumber";
import {RegistryContext} from "containers/context/RegistyContainer";
import {capitalize} from "utility/textTransform";
import {isSloAddress} from "utility/utilityFunctions";

export default function DeceasedPersonInformationRight(props) {
  return (
    <RegistryContext.Consumer>
      {context => (
        <DeceasedPerson {...props} slovenia={context.registry.slovenia}/>
      )}
    </RegistryContext.Consumer>
  );
}

class DeceasedPerson extends Component {
  render() {
    const {slovenia, values} = this.props;
    const address = values.deceasedPersonInformation ? values.deceasedPersonInformation.address : null;
    const addressLabels = {
      country: "deceasedPersonInformation.address.country.label",
      city: "deceasedPersonInformation.address.city.label"
    }

    return (
      <Layout width="100%" maxWidth={450} justifyContent="center" spacing={5}>
        <Layout.OneColumn spacing={20}/>
        <Layout.OneColumn>
          <TextField name="deceasedPersonInformation.name"
                     transform={capitalize}
                     isRequired={true}
                     placeholder="deceasedPersonInformation.content.name.placeholder"
                     label="deceasedPersonInformation.content.name.label"
                     maxLength={fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].name + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name="deceasedPersonInformation.surname"
                     transform={capitalize}
                     isRequired={true}
                     placeholder="deceasedPersonInformation.content.surname.placeholder"
                     label="deceasedPersonInformation.content.surname.label"
                     maxLength={fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].surname + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <DateField name="deceasedPersonInformation.birthDate"
                     label="deceasedPersonInformation.content.birthDate.label"
                     placeholder="deceasedPersonInformation.content.birthDate.placeholder"
                     legalAgeField={true}
                     isRequired={true}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <SexField name="deceasedPersonInformation.sex"/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <Address name="deceasedPersonInformation.address" addressLabels={addressLabels}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TextField name="deceasedPersonInformation.policyNumber"
                     placeholder="deceasedPersonInformation.content.policyNumber.placeholder"
                     label="deceasedPersonInformation.content.policyNumber.label"
                     maxLength={fieldLengthConstants[flowConstants.personSteps.DECEASED_PERSON_INFORMATION.NAME].policyNumber + 1}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TaxNumber name="deceasedPersonInformation.taxNumber"
                     placeholder="deceasedPersonInformation.content.taxNumber.placeholder"
                     label="deceasedPersonInformation.content.taxNumber.label"
                     isSlovenia={isSloAddress(address, slovenia)}/>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
