import React, {Component} from "react";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Text from "components/common/Text";

import styles from "containers/Login.module.scss";

export default class ClaimExpired extends Component {

    componentDidMount() {
        document.body.onbeforeunload = "return false";
    }

    redirectToBeginning = async () => {
        window.location.replace(window.location.origin);
    };

    render() {
        return (
            <div className={styles.root}>
                <div className={styles.inner}>
                    <Layout width="100%" maxWidth={450} spacing={5}>
                        <Layout.OneColumn>
                            <Text element="p" label="claimExpired.sidebar.title" variant="variant36"/>
                        </Layout.OneColumn>
                        <Layout.OneColumn spacing={10}/>
                        <Layout.OneColumn>
                            <Text element="div" label="claimExpired.sidebar.subtitle"/>
                        </Layout.OneColumn>
                        <Layout.OneColumn spacing={20}/>
                        <Layout.OneColumn textAlign="right">
                            <Button label="claimExpired.button" onClick={this.redirectToBeginning}/>
                        </Layout.OneColumn>
                    </Layout>
                </div>
            </div>
        );
    }
}