import React, {Component} from "react";
import Switch, {Case, Default} from "react-switch-case";

import scrollIntoView from "scroll-into-view";
import Layout from "components/common/Layout";
import Text from "components/common/Text";
import CheckBoxField from "components/form/CheckBox/CheckBoxField";
import CheckboxShowMoreWrapper from "components/form/CheckBox/CheckboxShowMoreWrapper";
import SummaryContainer from "components/step/summary/SummaryContainer";
import booleanTypes from "constants/booleanTypes";
import claimTypesConstants from "constants/claimTypes";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";
import medicalCareTypes from "constants/medicalCareTypes";
import {ConfigContext} from "containers/context/ConfigContainer";
import {NextStepContext} from "containers/context/NextStepContainer";
import {SummaryContext} from "containers/step/summary/Summary";
import SummaryCar from "containers/step/summary/SummaryCar";
import SummaryHome from "containers/step/summary/SummaryHome";
import SummaryPerson from "containers/step/summary/SummaryPerson";
import {getRefForScroll} from "utility/summary";
import isUndefinedOrNull from "utility/utilityFunctions";

export default function SummaryRight(props) {
  return (
    <ConfigContext.Consumer>
      {(configContext) => (
        <NextStepContext.Consumer>
          {nextStepContext => (
            <SummaryContainer>
              {context => (
                <InnerSummaryRight {...props}
                                   context={context}
                                   configContext={configContext}
                                   nextStepContext={nextStepContext}/>
              )}
            </SummaryContainer>
          )}
        </NextStepContext.Consumer>
      )}
    </ConfigContext.Consumer>
  );
}

class InnerSummaryRight extends Component {
  summaryRef = React.createRef();

  componentWillMount() {
    const {nextStepContext} = this.props;

    nextStepContext.setOnClick(this.onClick);
  }

  componentDidMount() {
    const {jumpFromSummaryRef, context} = this.props;

    const ref = getRefForScroll(jumpFromSummaryRef, context.getRefs());
    if (ref) {
      requestAnimationFrame(() => {
        scrollIntoView(ref, {
          align: {
            top: 0.5,
            left: 1,
          }
        });
      });
    }
  }

  onClick = () => {
    const {storeSummaryHTML, configContext} = this.props;
    const summaryHTML = this.summaryRef.current.cloneNode(true);
    storeSummaryHTML(configContext.url.postSummaryHTML, summaryHTML.innerHTML);
  };

  render() {
    const {onJumpFromSummaryStep, values, claimType, repairServiceType} = this.props;
    const owner = claimType === claimTypesConstants.PERSON
    && values.medicalCareType.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH
      ? booleanTypes.NO : values.insuranceOwner.owner;
    const isDamagesClaimFiled = isUndefinedOrNull(values.damagesClaimFiled) ? null : values.damagesClaimFiled.isDamagesClaimFiled;
    let label;

    if (claimType === claimTypesConstants.PERSON && values.medicalCareType.medicalCareType !== medicalCareTypes.OTHER) {
      if (values.medicalCareType.medicalCareType !== medicalCareTypes.HEALTH_SERVICE) {
        if (values.medicalCareType.medicalCareTypeDescription !== medicalCareDescriptionTypes.RETURN_OF_COSTS) {
          if (values.personResponsible.isOtherPersonFault && !isUndefinedOrNull(values.claimCompensationPerson) && values.claimCompensationPerson.claimCompensationPerson === booleanTypes.YES) {
            label = "summary.content.gdpr.notReturnOfCosts.dataAccuracy.first"
          } else {
            label = "summary.content.gdpr.notReturnOfCosts.dataAccuracy.second"
          }
        } else {
          label = "summary.content.gdpr.returnOfCosts.dataAccuracy"
        }
      } else {
        label = "summary.content.gdpr.healthService.dataAccuracy"
      }
    } else {
      label = "summary.content.gdpr.dataAccuracy"
    }

    return (
      <div ref={this.summaryRef} style={{"width": "100%", "height": "100%"}}>
        <SummaryContext.Provider value={{values, onJumpFromSummaryStep}}>
          <Layout maxWidth="600px" width="100%" justifyContent="center">
            <Switch condition={claimType}>
              <Case value={claimTypesConstants.HOME}>
                <SummaryHome claimType={claimType} width="100%"/>
              </Case>
              <Case value={claimTypesConstants.PERSON}>
                <SummaryPerson claimType={claimType} medicalCareType={values.medicalCareType} owner={owner}
                               isDamagesClaimFiled={isDamagesClaimFiled} accidentInvolved={values.accidentInvolved} width="100%"/>
              </Case>
              <Default>
                <SummaryCar claimType={claimType} repairServiceType={repairServiceType} width="100%"/>
              </Default>
            </Switch>
            <Layout.OneColumn>
              <CheckBoxField name="dataAccuracy" label={label} isHtml={true}/>
            </Layout.OneColumn>
            <Layout.OneColumn spacing={20}/>
              <Layout.OneColumn>
                <CheckboxShowMoreWrapper>
                  <CheckBoxField name="emailConsent" label={`summary.content.gdpr.emailConsent`} isHtml={true}/>
                </CheckboxShowMoreWrapper>
                <CheckboxShowMoreWrapper>
                  <CheckBoxField name="directMarketing" label={`summary.content.gdpr.directMarketing`} isHtml={true}/>
                </CheckboxShowMoreWrapper>
              </Layout.OneColumn>
            <Layout.OneColumn>
              <Text element="span" variant="variant12" label="summary.content.gdpr.remark" isHtml={true}/>
            </Layout.OneColumn>
          </Layout>
        </SummaryContext.Provider>
      </div>
    );
  }
}
