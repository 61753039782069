import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import booleanTypesConstants from "../../../constants/booleanTypes";

export default class HasCascoRight extends Component {
  render() {
    const {onNextStep} = this.props;

    return (
      <Layout flex={1}>
        <Layout.OneColumn>
          <NextStepButton component={ToggleButton}
                          isValid={true}
                          buttonProps={{name: "hasCasco"}}
                          onNextStep={onNextStep}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
              <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
