import React from "react";

import _ from "lodash";

import Text from "components/common/Text";
import SummaryRefContainer from "components/step/summary/SummaryRefContainer";
import SummaryTable from "components/step/summary/SummaryTable";
import VehicleIconLabel from "components/step/VehicleIconLabel";
import VehicleInfoLogic, {VehicleInfoContext} from "components/step/VehicleInfoLogic";
import booleanTypeConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import summaryRefMappingConstants from "constants/summaryRefMapping";
import responsiblePersonConstants from "constants/responsiblePerson";
import vehicleTypes from "constants/vehicleTypes";
import Summary from "containers/step/summary/Summary";
import {getLastLabelComponent} from "utility/utilityFunctions";

export default function SummaryResponsiblePerson({accidentInvolved}) {
  return (
    <Summary title="summary.content.responsiblePerson.title" onePart={true}
             refName={summaryRefConstants.RESPONSIBLE_PERSON.REF}>
      <Summary.Items formName={{name: flowConstants.steps.RESPONSIBLE_PERSON.NAME}}
                     condition={form => form.multipleParticipants === booleanTypeConstants.YES}>
        {form => <SummaryResponsiblePersonItem accidentInvolved={accidentInvolved} {...form} />}
      </Summary.Items>
    </Summary>
  );
}

function SummaryResponsiblePersonItem({jumpTo, responsiblePerson, accidentInvolved}) {
  const notVehicle = accidentInvolved && (accidentInvolved.involvementType === vehicleTypes.BIKE || accidentInvolved.involvementType === vehicleTypes.PEDESTRIAN);

  if (responsiblePerson === responsiblePersonConstants.CANT_AGREE || responsiblePerson === responsiblePersonConstants.DONT_KNOW) {
    return (
      <Summary.ItemValue label="summary.content.responsiblePerson.label" formName={jumpTo}
                         refName={summaryRefConstants.RESPONSIBLE_PERSON.RESPONSIBLE_PERSON}>
        {(form) => <Text label={`summary.content.responsiblePerson.value.${responsiblePerson}`}/>}
      </Summary.ItemValue>
    );
  } else {
    return (
      responsiblePerson.map((person, key) => (
        <React.Fragment key={key}>
          <SummaryRefContainer refName={summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REF(person.info.index)}>
            <SummaryTable.HeaderContent>
              <VehicleIconLabel info={person.info}/>
            </SummaryTable.HeaderContent>
            {notVehicle && person.info.isOwnVehicle ?
              <Summary.ItemValue label="summary.content.responsiblePerson.accidentInvolvement.notVehicle.label"
                                 refName={summaryRefConstants.RESPONSIBLE_PERSON.PERSON.REF(0)}
                                 formName={{name: flowConstants.steps.RESPONSIBLE_PERSON.NAME}}
                                  condition={form => form.responsiblePerson[0].info.isOwnVehicle}>
                {form => <Text label={`summary.content.accidentInvolved.${form.responsiblePerson[0].info.type}`}/>}
              </Summary.ItemValue>
                : null
            }
            <SummaryVehicleInfo formName={{name: flowConstants.steps.RESPONSIBLE_PERSON.NAME}}
                                choice={person.participantInfo.choice}
                                person={person}>
              <VehicleInfoLogic {...person} />
            </SummaryVehicleInfo>
          </SummaryRefContainer>
        </React.Fragment>
      ))
    );
  }
}

function SummaryVehicleInfo({formName, choice, children, person}) {
  const {info, participantInfo} = person;
  const {inputType} = participantInfo;
  const {isVehicle} = info;

  const refName = isVehicle
    ? summaryRefMappingConstants[inputType].REF
    : summaryRefConstants.RESPONSIBLE_PERSON.PERSON.PEDESTRIAN.REF;

  return (
    <SummaryRefContainer refName={refName}>
      <VehicleInfoContext.Provider value={{component: SummaryVehicleInfoLine, formName, choice, isVehicle, inputType}}>
        {children}
      </VehicleInfoContext.Provider>
    </SummaryRefContainer>
  );
}

function SummaryVehicleInfoLine({formName, label, children, inputType}) {
  const refName = _.at(summaryRefMappingConstants, `${inputType}.${getLastLabelComponent(label)}`)[0];

  return (
    <Summary.ItemValue label={label} formName={formName} refName={refName}>
      {() => children}
    </Summary.ItemValue>
  );
}