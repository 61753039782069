import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import claimTypes from "constants/claimTypes";
import personTypesConstants from "constants/personTypes";
import FadeView from "components/view/FadeView";
import {RegistryContext} from "containers/context/RegistyContainer";
import LegalPerson from "containers/step/userIdentification/LegalPerson";
import UserIdentificationInfo from "containers/step/userIdentification/UserIdentificationInfo";

export default class UserIdentificationRight extends Component {
  render() {
    return (
      <RegistryContext.Consumer>
        {context => (
          <Layout flex={1} spacing={8}>
            <Layout.OneColumn>
              <InnerUserIdentificationRight {...this.props} slovenia={context.registry.slovenia}/>
            </Layout.OneColumn>
          </Layout>
        )}
      </RegistryContext.Consumer>
    );
  }
}

class InnerUserIdentificationRight extends Component {
  onClick = (value, callback) => {
    callback();
  };

  render() {
    const {values, slovenia, loggedInFromCounter, claimType, insuranceOwner, setFieldValue, mobileUserId, onNextStep, isValid, step} = this.props;
    const {personType} = values;

    return (
      <Layout spacing={5} width="100%" maxWidth={450} justifyContent="center">
        <Layout.OneColumn spacing={35}/>
        <Layout.OneColumn spacing={0}>
          <Text element="div"
                variant="variant24"
                label={`userIdentification.content.${claimType}.personType.title`}
                isHtml={true}/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={15}/>
        {claimType !== claimTypes.PERSON ? <Layout.OneColumn spacing={0}>
          <ToggleButton name="personType" onClick={this.onClick}>
            <Layout.OneRow flex={1} spacing={8}>
              <ToggleButton.Choice value={personTypesConstants.NATURAL}
                                   label="userIdentification.content.personType.button.natural"
                                   variant="variant21"/>
              <ToggleButton.Choice value={personTypesConstants.LEGAL}
                                   label="userIdentification.content.personType.button.legal"
                                   variant="variant21"/>
            </Layout.OneRow>
          </ToggleButton>
        </Layout.OneColumn> : null}
        <FadeView step={personType}>
          <Switch condition={personType}>
            <Case value={personTypesConstants.NATURAL}>
              <UserIdentificationInfo slovenia={slovenia}
                                      values={values}
                                      name="userInfo"
                                      loggedInFromCounter={loggedInFromCounter}
                                      titleLabel={`userIdentification.content.noAs.${claimType}.title.natural`}
                                      claimType={claimType}
                                      insuranceOwner={insuranceOwner}
                                      setFieldValue={setFieldValue}
                                      mobileUserId={mobileUserId}
                                      onNextStep={onNextStep}
                                      isValid={isValid}
                                      canAddBeneficiaries={step.canAddBeneficiaries}/>
            </Case>
            <Case value={personTypesConstants.LEGAL}>
              <LegalPerson slovenia={slovenia}
                           values={values}
                           loggedInFromCounter={loggedInFromCounter}/>
            </Case>
            <Default>
              {null}
            </Default>
          </Switch>
        </FadeView>
      </Layout>
    );
  }
}
