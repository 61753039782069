import React, {Component} from "react";
import Switch, {Case} from 'react-switch-case';

import axios from "axios";
import {Field} from "formik";

import Button from "components/common/Button";
import Layout from "components/common/Layout";
import Text from "components/common/Text";
import NextStepButton from "components/common/NextStepButton";
import LinkField from "components/form/LinkField";
import TextField from "components/form/TextField";
import Country from "components/step/Country";
import VehicleInfo from "components/step/VehicleInfo";
import VehicleInfoLogic from "components/step/VehicleInfoLogic";
import VehicleMark from "components/step/VehicleMark";
import FadeView from "components/view/FadeView";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import licencePlateLengthConstants from "constants/licencePlateLengths";
import vehicleInfoInputTypesConstants from "constants/vehicleInfoInputTypes"
import vehicleTypesConstants from "constants/vehicleTypes";
import {ConfigContext} from "containers/context/ConfigContainer";
import {detectLicencePlateType, getRegistrationMask} from "selectors/vahicleInfo";
import isUndefinedOrNull from "utility/utilityFunctions";

export default class VehicleInfoInputContainer extends Component {
  render() {
    const {name, ...props} = this.props;

    return (
      <Field
        name={name}
        render={(fieldProps) => (
          <VehicleInfoInputLayout fieldProps={fieldProps} {...props}/>
        )}/>
    );
  }
}

class VehicleInfoInputLayout extends Component {
  static contextType = ConfigContext;

  constructor(props) {
    super(props);
    const vehicleData = this.props.fieldProps.field.value.vehicleData;

    this.state = {
      loading: false,
      requestCount: 0,
      success: isUndefinedOrNull(vehicleData) ? true : vehicleData.success,
    };
  }

  onFilledRegistration = async (registration) => {
    const {fieldProps: {form: {setFieldValue}, field: {name}}, step} = this.props;
    const regClean = registration.replace(/_/g, "");

    setFieldValue(`${name}.inSlovenia.registrationNumber`, regClean.toUpperCase());

    if (regClean.length >= licencePlateLengthConstants[detectLicencePlateType(regClean)]) {
      const requestId = this.state.requestCount;

      this.setState(state => ({
        ...state,
        loading: true,
        requestCount: state.requestCount + 1,
      }));

      setFieldValue(`${name}.vehicleData`, null);

      const formattedRegistration = regClean.replace(' ', '+');
      const result = await axios.get(this.context.url.vehicleData, {
        params: {
          regNo: formattedRegistration,
          date: step.accidentDate
        }
      });

      if (requestId === this.state.requestCount - 1) {
        this.setState(state => ({
          ...state,
          loading: false,
          success: result.data.success,
        }));

        setFieldValue(`${name}.vehicleData`, {...result.data, registrationNumber: regClean.toUpperCase()});
      }
    } else {
      setFieldValue(`${name}.vehicleData`, null);
    }
  };

  onClearRegistration = () => {
    const {fieldProps: {form: {setFieldValue}, field: {name}}} = this.props;

    setFieldValue(`${name}.inSlovenia.registrationNumber`, null);
    setFieldValue(`${name}.vehicleData`, null);
  };

  setCountry = (value) => {
    const {fieldProps: {form: {setFieldValue}, field: {name}}} = this.props;

    setFieldValue(`${name}.country`, value);
  };

  render() {
    const {fieldProps: {field: {name, value}}, info, onNextStep} = this.props;
    const {inputType, vehicleData} = value;

    return (
      <Layout flex={1} spacing={10}>
        <Layout.OneColumn spacing={10}></Layout.OneColumn>
        <Layout.OneColumn>
          <NextStepButton component={LinkField}
                          onNextStep={onNextStep}
                          condition={linkValue => linkValue === vehicleInfoInputTypesConstants.UNKNOWN}
                          isValid={linkValue => linkValue === vehicleInfoInputTypesConstants.UNKNOWN}
                          buttonProps={{
                            name: `${name}.inputType`,
                          }}>
            <LinkField.Item label="participantsInfo.vehicleInfo.inputType.registeredInSlovenia"
                            condition={info.type !== vehicleTypesConstants.COMPANY}
                            value={vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA}/>
            <LinkField.Item label="participantsInfo.vehicleInfo.inputType.companyVehicle"
                            condition={info.type !== vehicleTypesConstants.MOTORCYCLE}
                            value={vehicleInfoInputTypesConstants.COMPANY_VEHICLE}/>
            <LinkField.Item label="participantsInfo.vehicleInfo.inputType.registeredAbroad"
                            condition={info.type !== vehicleTypesConstants.COMPANY}
                            value={vehicleInfoInputTypesConstants.REGISTERED_ABROAD}/>
            <LinkField.Item label="participantsInfo.vehicleInfo.inputType.notRegistered"
                            condition={info.type !== vehicleTypesConstants.COMPANY}
                            value={vehicleInfoInputTypesConstants.NOT_REGISTERED}/>
            {onNextStep
              ? <LinkField.Item label="participantsInfo.vehicleInfo.inputType.unknown"
                                condition={info.type !== vehicleTypesConstants.COMPANY}
                                value={vehicleInfoInputTypesConstants.UNKNOWN}/>
              : null}
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={15}></Layout.OneColumn>
        <Layout.OneColumn>
          <FadeView step={inputType}>
            <VehicleInfoInput name={name}
                              info={info}
                              setCountry={this.setCountry}
                              onFilledRegistration={this.onFilledRegistration}
                              onClearRegistration={this.onClearRegistration}
                              inputType={inputType}
                              vehicleData={vehicleData}
                              loading={this.state.loading}
                              success={this.state.success}/>
          </FadeView>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={10}></Layout.OneColumn>
      </Layout>
    );
  }
}

function VehicleInfoInput(props) {
  return (
    <Switch condition={props.inputType}>
      <Case value={vehicleInfoInputTypesConstants.REGISTERED_IN_SLOVENIA}>
        <VehicleInfoInputRegisteredInSlovenia {...props} />
      </Case>
      <Case value={vehicleInfoInputTypesConstants.COMPANY_VEHICLE}>
        <VehicleInfoInputCompanyVehicle {...props}/>
      </Case>
      <Case value={vehicleInfoInputTypesConstants.REGISTERED_ABROAD}>
        <VehicleInfoInputRegisteredAbroad {...props}/>
      </Case>
      <Case value={vehicleInfoInputTypesConstants.NOT_REGISTERED}>
        <VehicleInfoInputNotRegistered {...props}/>
      </Case>
    </Switch>
  );
}

function VehicleInfoInputRegisteredInSlovenia(props) {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <Text variant="variant2"
              element="span"
              label="participantsInfo.vehicleInfo.inputType.registeredInSlovenia.title"/>
      </Layout.OneColumn>
      <Layout.OneColumn spacing={10}>
        {props.success
          ? <RegisteredInSlovenia {...props}/>
          : <AlternativeRegisteredInSlovenia {...props}/>}
      </Layout.OneColumn>
    </Layout>
  );
}

function RegisteredInSlovenia({name, ...props}) {
  return (
    <>
      <Layout.OneColumn width="100%">
        <TextField name={`${name}.inSlovenia.registrationNumber`}
                   placeholder="participantsInfo.vehicleInfo.registrationNumber.placeholder"
                   label="participantsInfo.vehicleInfo.registrationNumber.label"
                   mask={getRegistrationMask}
                   onValueChange={props.onFilledRegistration}
                   maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber + 1}/>
      </Layout.OneColumn>
      <Layout.OneColumn width="100%">
        {props.vehicleData || props.loading ? <RegistrationData name={name} {...props}/> : null}
      </Layout.OneColumn>
    </>
  );
}

function AlternativeRegisteredInSlovenia({name}) {
  return (
    <>
      <TextField name={`${name}.inSlovenia.registrationNumber`}
                 placeholder="participantsInfo.vehicleInfo.registrationNumber.placeholder"
                 label="participantsInfo.vehicleInfo.registrationNumber.label"
                 maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber + 1}/>
      <VehicleMark name={`${name}.inSlovenia.vehicleMark`}/>
      <TextField name={`${name}.inSlovenia.vehicleType`}
                 placeholder="participantsInfo.vehicleInfo.vehicleType.placeholder"
                 label="participantsInfo.vehicleInfo.vehicleType.label"
                 maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleType + 1}/>
    </>
  );
}

function VehicleInfoInputRegisteredAbroad({name, setCountry}) {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <Text element="span" variant="variant2" label="participantsInfo.vehicleInfo.inputType.registeredAbroad.title"/>
        <Layout.OneColumn spacing={10}/>
        <Country name={`${name}.abroad.countryName`}
                 setCountry={setCountry}/>
        <TextField name={`${name}.abroad.registrationNumber`}
                   placeholder="participantsInfo.vehicleInfo.registrationNumber.abroad.placeholder"
                   label="participantsInfo.vehicleInfo.registrationNumber.abroad.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].registrationNumber + 1}/>
        <VehicleMark name={`${name}.abroad.vehicleMark`}/>
        <TextField name={`${name}.abroad.vehicleType`}
                   placeholder="participantsInfo.vehicleInfo.vehicleType.placeholder"
                   label="participantsInfo.vehicleInfo.vehicleType.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleType + 1}/>
        <TextField name={`${name}.abroad.insuranceCompany`}
                   placeholder="participantsInfo.vehicleInfo.insuranceCompany.abroad.placeholder"
                   label="participantsInfo.vehicleInfo.insuranceCompany.abroad.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].insuranceCompany + 1}/>
      </Layout.OneColumn>
    </Layout>
  );
}

function VehicleInfoInputCompanyVehicle({name}) {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <Text element="span" variant="variant2" label="participantsInfo.vehicleInfo.inputType.companyVehicle.title"/>
        <Layout.OneColumn spacing={10}/>
        <TextField name={`${name}.company.vehicleChassisNumber`}
                   placeholder="participantsInfo.vehicleInfo.vehicleChassisNumber.placeholder"
                   label="participantsInfo.vehicleInfo.vehicleChassisNumber.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleChassisNumber + 1}/>
        <TextField name={`${name}.company.vehicleMark`}
                   placeholder="participantsInfo.vehicleInfo.vehicleMark.placeholder"
                   label="participantsInfo.vehicleInfo.vehicleMark.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleMark + 1}/>
      </Layout.OneColumn>
    </Layout>
  );
}

function VehicleInfoInputNotRegistered({name}) {
  return (
    <Layout flex={1} spacing={10}>
      <Layout.OneColumn>
        <Text element="span" variant="variant2" label="participantsInfo.vehicleInfo.inputType.notRegistered.title"/>
        <Layout.OneColumn spacing={10}/>
        <TextField name={`${name}.notRegistered.vehicleDescription`}
                   placeholder="participantsInfo.vehicleInfo.vehicleDescription.placeholder"
                   label="participantsInfo.vehicleInfo.vehicleDescription.label"
                   maxLength={fieldLengthConstants[flowConstants.steps.PARTICIPANTS_INFO.NAME].vehicleDescription + 1}/>
      </Layout.OneColumn>
    </Layout>
  );
}

function RegistrationData({name, info, onClearRegistration, ...props}) {
  return (
    <VehicleInfo {...info}
                 variant="variant4"
                 showIcon={false}>
      {
        props.loading
          ? <Text label="participantsInfo.vehicleInfo.loading.label"/>
          : (
            <Layout spacing={10} width="100%">
              <Layout.OneColumn>
                <VehicleInfoLogic participantInfo={props} info={info}/>
              </Layout.OneColumn>
              <Layout.OneColumn spacing="10"/>
              <Layout.OneColumn>
                <Button onClick={onClearRegistration}
                        variant="variant18"
                        label="participantsInfo.vehicleInfo.notMyVehicle.label"/>
              </Layout.OneColumn>
            </Layout>
          )
      }
    </VehicleInfo>
  );
}