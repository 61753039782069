import React, {Component} from "react";

import SummaryHeader from "components/common/SummaryHeader";
import Text from "components/common/Text";
import Header from "containers/step/Header";
import HelpInformationRight from "containers/step/helpInformation/HelpInformationRight";
import SplitViewContainer from "containers/view/SplitViewContainer";

// step 4
export default class HelpInformationStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="helpInformation.sidebar.title"/>
        <Text element="div" label="helpInformation.sidebar.subtitle" isHtml={true}/>
      </SplitViewContainer.Left>
    );
  };

  right(props) {
    return (
      <SplitViewContainer.Right showNextStepButton={false} {...props}>
        <HelpInformationRight {...props} />
      </SplitViewContainer.Right>
    );
  }

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right}/>
    );
  }
}
