import React, {Component} from "react";

import {connect} from "react-redux";

import {loadUserInfo} from "actions/flow";
import Loading from "components/common/Loading";
import {ConfigContext} from "containers/context/ConfigContainer";
import StateAppContainer from "containers/StateAppContainer";
import cookie from "../constants/cookie";

class LoadUserInfoContainer extends Component {
  static contextType = ConfigContext;

  componentDidMount() {
    const {submitId, loadUserInfo} = this.props;

    loadUserInfo(this.context.url.continuedUserInfo(submitId));

    sessionStorage.setItem(cookie.SUBMIT_ID, "false");
  }

  render() {
    if (!this.props.userLoaded) {
      return <Loading/>;
    }

    return (
      <StateAppContainer/>
    );
  }
}


const mapStateToProps = (state) => ({
  userLoaded: state.flow.userLoaded || false,
});

const mapDispatchToProps = (dispatch) => ({
  loadUserInfo: (url) => dispatch(loadUserInfo(url)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadUserInfoContainer);
