import React, {Component} from "react";

import cx from "classnames";

import {ConfigContext} from "containers/context/ConfigContainer";
import flowConstants from "constants/flow";

import Translation from "components/common/Translation";

import styles from "components/common/Button.module.scss";

export default class Button extends Component {
  static defaultProps = {
    isSelected: false,
    showCheckMark: true,
    spacing: true,
    isDisabled: false,
  };

  static contextType = ConfigContext;

  render() {
    const company = this.context.company === flowConstants.companies.GENERALI ? '' : this.context.company;
    const isWiz = company === flowConstants.companies.WIZ;
    const {icon, label, descriptionLabel, isSelected, showCheckMark, isDisabled, loading, spacing, onClick, className, render, children, variant} = this.props;

    let descriptionElement = null;
    if (descriptionLabel) {
      descriptionElement = <Translation label={descriptionLabel} element="span"/>;
    }

    let labelElement = null;
    if (render) {
      labelElement = render({isSelected});
    } else if (label) {
      labelElement = <Translation label={loading ? "button.loading" : label} element="span"/>;
    } else {
      labelElement = children;
    }

    const onlyIcon = icon && !label && !descriptionLabel && React.Children.count(children) === 0;
    const onlyLabel = !icon && label && !descriptionLabel && React.Children.count(children) === 0;

    const containerClassName = cx({
      [styles.button]: true,
      [styles.disabled]: isDisabled || loading,
      [styles.spacing]: spacing,
      [styles.selected]: isSelected,
      [styles.checkmark]: isSelected && showCheckMark && !onlyIcon,
      [styles.iconLabelDescription]: icon && (label || children) && descriptionLabel,
      [styles.iconLabel]: icon && (label || children) && !descriptionLabel,
      [styles.onlyIcon]: onlyIcon,
      [styles.onlyLabel]: onlyLabel,
      [styles.variant1]: variant === "variant1",
      [styles.variant2]: variant === "variant2",
      [styles.variant3]: variant === "variant3",
      [styles.variant4]: variant === "variant4",
      [styles.variant5]: variant === "variant5",
      [styles.variant6]: variant === "variant6",
      [styles.variant7]: variant === "variant7",
      [styles.variant8]: variant === "variant8",
      [styles.variant9]: variant === "variant9",
      [styles.variant10]: variant === "variant10",
      [styles.variant11]: variant === "variant11",
      [styles.variant12]: variant === "variant12",
      [styles.variant13]: variant === "variant13",
      [styles.variant14]: variant === "variant14",
      [styles.variant15]: variant === "variant15",
      [styles.variant16]: variant === "variant16",
      [styles.variant17]: variant === "variant17",
      [styles.variant18]: variant === "variant18",
      [styles.variant19]: variant === "variant19",
      [styles.variant20]: variant === "variant20",
      [styles.variant21]: variant === "variant21",
      [styles.variant22]: variant === "variant22",
      [styles.variant23]: variant === "variant23",
      [styles.variant24]: variant === "variant24",
      [styles.variant25]: variant === "variant25",
      [styles.variant26]: variant === "variant26",
      [styles.variant27]: variant === "variant27",
      [styles.variant28]: variant === "variant28",
      [styles.wiz]: isWiz,
      [className]: true,
    });

    return (
      <div className={containerClassName} onClick={loading ? () => {} : onClick}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.label}>{labelElement}</div>
        <div className={styles.description}>{descriptionElement}</div>
      </div>
    );
  }
}