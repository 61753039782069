import React from "react";

import cx from "classnames";

import sketchConstants from "constants/sketch";
import Button from "components/common/Button";
import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import ToggleButton from "components/form/ToggleButton";
import SketchIcon from "components/sketch/icon/SketchIcon";
import {SketchContext} from "components/sketch/SketchContainer";

import forwardArrow from "images/forward-arrow.svg";

import styles from "components/sketch/navigation/SketchMainNavigation.module.scss";

export default function SketchMainNavigation({parked}) {
  return (
    <ToggleButton name="navigationChoiceType">
      <Layout layoutClassName={styles.root} flex={1}>
        {parked
          ? null
          : <Layout.OneColumn>
            <TypeChoice iconClassName={styles.roadCrossings} type={sketchConstants.NAVIGATION.ROAD_CROSSINGS}/>
          </Layout.OneColumn>}
        <Layout.OneColumn>
          <TypeChoice iconClassName={styles.shapes} type={sketchConstants.NAVIGATION.SHAPES}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TypeChoice iconClassName={styles.participants} type={sketchConstants.NAVIGATION.PARTICIPANTS}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TypeChoice iconClassName={styles.weather} type={sketchConstants.NAVIGATION.WEATHER}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TypeChoice iconClassName={styles.draw} type={sketchConstants.NAVIGATION.DRAW}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TypeChoice iconClassName={styles.eraser} type={sketchConstants.NAVIGATION.ERASER}/>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <TypeChoice iconClassName={styles.text} type={sketchConstants.NAVIGATION.TEXT}/>
        </Layout.OneColumn>
        <Layout.Fill/>
        <Layout.OneColumn>
          <ForwardButton/>
        </Layout.OneColumn>
      </Layout>
    </ToggleButton>
  )
}

function TypeChoice({type, iconClassName}) {
  return (
    <ToggleButton.Choice className={styles.button} value={type.NAME} variant="variant3"
                         icon={<SketchIcon className={cx(styles.icon, iconClassName)} icon={type.IMAGE}/>}/>
  );
}

function ForwardButton() {
  return (
    <SketchContext.Consumer>
      {({onNextStep}) => (
        <NextStepButton component={Button}
                        onNextStep={onNextStep}
                        isValid={true}
                        buttonProps={{
                          icon: <ForwardIcon/>,
                          label: "button.next",
                          variant: "variant5",
                          className: styles.next,
                        }}/>
      )}
    </SketchContext.Consumer>
  );
}

function ForwardIcon() {
  return (
    <img src={forwardArrow} alt="forward"/>
  )
}
