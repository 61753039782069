import React, {Component} from "react";

import SketchContainer from "components/sketch/SketchContainer";
import ViewContainer from "containers/view/ViewContainer";

export default class DrawingStepContainer extends Component {
  renderChildren = (props) => {
    return (
        <SketchContainer name="sketch" {...props} />
    );
  };

  render() {
    return (
      <ViewContainer render={this.renderChildren}/>
    );
  }
}
