import React, {Component} from "react";

import cx from "classnames";
import {connect} from "formik";
import {isUndefined} from "underscore";

import SketchMainNavigation from "components/sketch/navigation/SketchMainNavigation";
import SketchSubNavigation from "components/sketch/navigation/SketchSubNavigation";
import sketchConstants from "constants/sketch";

import styles from "components/sketch/navigation/SketchNavigation.module.scss";

class SketchNavigation extends Component {
  onClose = () => {
    this.props.formik.setFieldValue("navigationChoiceType", undefined);
  };

  render() {
    const {name, isDragging} = this.props;
    const {values: {navigationChoiceType, participants, text, parked}} = this.props.formik;

    const navigationType =
      isUndefined(navigationChoiceType) || [sketchConstants.NAVIGATION.DRAW.NAME, sketchConstants.NAVIGATION.ERASER.NAME].indexOf(navigationChoiceType) !== -1
        ? undefined
        : navigationChoiceType;

    const hidden = isUndefined(navigationType) || isDragging;

    const className = cx({
      [styles.root]: true,
      [styles.hidden]: hidden,
      [styles.visible]: !hidden,
    });

    return (
      <div className={className}>
        <SketchMainNavigation parked={parked}/>
        <div className={styles.sub}>
          <SketchSubNavigation name={name} mainChoice={navigationType} text={text}
                               onClose={this.onClose} participants={participants}/>
        </div>
      </div>
    );
  }
}

export default connect(SketchNavigation);
