import React, {Component} from "react";
import ReactDOM from "react-dom";

import {InfoWindow} from 'google-maps-react';

export default class InfoWindowMap extends Component {
  containerRef = React.createRef();
  infoWindowRef = React.createRef();

  onInfoWindowOpen = () => {
    ReactDOM.render(<div>{this.props.children}</div>, this.containerRef.current);
    this.infoWindowRef.current.infowindow.setContent(this.containerRef.current);
  };

  componentDidMount() {
    const {callOpen, map, marker} = this.props;

    if (callOpen && map && marker) {
      this.infoWindowRef.current.infowindow.open(map, marker);
    }
  }

  render() {
    const {children, ...props} = this.props;

    return (
      <>
        <div ref={this.containerRef}/>
        <InfoWindow onOpen={this.onInfoWindowOpen} ref={this.infoWindowRef} {...props}>
          {children || <div />}
        </InfoWindow>
      </>
    );
  }
}
