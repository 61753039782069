import React, {Component} from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import TextField from "components/form/TextField";
import ToggleButton from "components/form/ToggleButton";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import medicalCareDescriptionTypes from "constants/medicalCareDescriptionTypes";

export default class ResponsiblePersonRelationRight extends Component {
  render() {
    const {values, onNextStep, step} = this.props;
    const label = step.medicalCareTypeDescription === medicalCareDescriptionTypes.DEATH
      ? "responsiblePersonRelation.content.death.title" : "responsiblePersonRelation.content.title";

    return (
      <Layout flex={1}>
        <Text label={label} element="h3" variant="variant1"/>
        <Layout.OneColumn spacing={30}/>
        <Layout.OneColumn>
          <NextStepButton component={ToggleButton}
                          condition={value => value === booleanTypesConstants.NO}
                          isValid={true}
                          buttonProps={{name: "responsiblePersonRelation"}}
                          onNextStep={onNextStep}>
            <Layout.OneRow spacing={10} flex={1}>
              <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
              <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
            </Layout.OneRow>
          </NextStepButton>
        </Layout.OneColumn>
        <Layout.OneColumn>
          <FadeView step={values.responsiblePersonRelation}>
            <Switch condition={values.responsiblePersonRelation}>
              <Case value={booleanTypesConstants.YES}>
                <Layout width="100%" maxWidth={450} justifyContent="center">
                  <Layout.OneColumn spacing={35}/>
                  <Layout.OneColumn>
                    <TextField name="description"
                               label="responsiblePersonRelation.content.locationDescription.title"
                               placeholder="responsiblePersonRelation.content.locationDescription.placeholder"
                               multiLine={true}
                               maxLength={fieldLengthConstants[flowConstants.personSteps.RESPONSIBLE_PERSON_RELATION.NAME].description + 1}/>
                  </Layout.OneColumn>
                </Layout>
              </Case>
              <Default>
                {null}
              </Default>
            </Switch>
          </FadeView>
        </Layout.OneColumn>
      </Layout>
    );
  }
}
