import React from "react";

import Switch, {Case, Default} from "react-switch-case";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import Witnesses from "components/step/Witnesses";
import FadeView from "components/view/FadeView";
import booleanTypesConstants from "constants/booleanTypes";
import flowConstants from "constants/flow";

export default function WitnessesPresentRight(props) {
  const {values, onNextStep} = props;

  return (
    <Layout width="100%" spacing={10}>
      <Layout.OneColumn spacing={40}/>
      <Layout.OneColumn>
        <Text element="p" label="witnessesPresent.content.witnesses.present.title" variant="variant1"/>
      </Layout.OneColumn>
      <Layout.OneColumn flex={1}>
        <NextStepButton component={ToggleButton}
                        onNextStep={onNextStep}
                        isValid={true}
                        condition={value => value !== booleanTypesConstants.YES}
                        buttonProps={{
                          name: "witnessesPresent",
                        }}>
          <Layout spacing={10}>
            <Layout.OneColumn flex={1}>
              <Layout.OneRow spacing={10} flex={1}>
                <ToggleButton.Choice value={booleanTypesConstants.YES} label="button.yes"/>
                <ToggleButton.Choice value={booleanTypesConstants.NO} label="button.no"/>
                <ToggleButton.Choice value={booleanTypesConstants.DONT_KNOW} label="button.dontKnow"/>
              </Layout.OneRow>
            </Layout.OneColumn>
          </Layout>
        </NextStepButton>
      </Layout.OneColumn>
      <Layout.OneColumn width="100%" maxWidth={450} justifyContent="center">
        <FadeView step={values.witnessesPresent}>
          <Switch condition={values.witnessesPresent}>
            <Case value={booleanTypesConstants.YES}>
              <Witnesses screenName={flowConstants.personSteps.WITNESSES_PRESENT.NAME}
                         witnessesPresent={values.witnessesPresent}/>
            </Case>
            <Default>
              {null}
            </Default>
          </Switch>
        </FadeView>
      </Layout.OneColumn>
    </Layout>
  );
}
