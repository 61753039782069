/* eslint-disable import/no-webpack-loader-syntax */

import React from "react";
import vehicleTypesConstants from "constants/vehicleTypes";

import car from "!url-loader!images/vehicle/car.svg";
import company from "!url-loader!images/vehicle/company.svg";
import pedestrian from "!url-loader!images/vehicle/pedestrian.svg";
import motorcycle from "!url-loader!images/vehicle/motorcycle.svg";
import semiTruck from "!url-loader!images/vehicle/semi-truck.svg";
import bike from "!url-loader!images/vehicle/bike.svg";
import scooter from "!url-loader!images/vehicle/scooter.svg";
import tractor from "!url-loader!images/vehicle/tractor.svg";

/* eslint-enable import/no-webpack-loader-syntax */

export default function VehicleSvgIcon({type, style}) {
  let image = null;
  if (type === vehicleTypesConstants.CAR) {
    image = car;
  } else if (type === vehicleTypesConstants.PEDESTRIAN) {
    image = pedestrian;
  } else if (type === vehicleTypesConstants.MOTORCYCLE) {
    image = motorcycle;
  } else if (type === vehicleTypesConstants.SEMI_TRUCK) {
    image = semiTruck;
  } else if (type === vehicleTypesConstants.BIKE) {
    image = bike;
  } else if (type === vehicleTypesConstants.SCOOTER) {
    image = scooter;
  } else if (type === vehicleTypesConstants.TRACTOR) {
    image = tractor;
  } else if (type === vehicleTypesConstants.COMPANY) {
    image = company;
  } else if (type === vehicleTypesConstants.WORK_OTHER) {
    image = tractor;
  }

  return (
    <image xlinkHref={image} style={style} x={style.x} y={style.y} width={style.width} height={style.height}/>
  );
}