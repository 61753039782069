import {isUndefined} from "underscore";

import booleanTypesConstants from "constants/booleanTypes"
import carDamageTypeConstants from "constants/carDamageTypes";
import flowConstants from "constants/flow";
import vehicleTypeConstants from "constants/vehicleTypes";
import calculateFinalParticipants from "selectors/participantsNumber";

export function calculateFutureFormState(step, forms) {
  const result = {...forms};
  const steps = flowConstants.steps;

  const carDamageTypeForm = {...forms[steps.CAR_DAMAGE_TYPE.NAME] || {}};
  const vehicleTypeForm = {...forms[steps.VEHICLE_TYPE.NAME] || {}};
  const parkedForm = {...forms[steps.PARKED.NAME] || {}};
  const participantsNumberForm = {...forms[steps.PARTICIPANTS_NUMBER.NAME] || {}};
  const participantsInfoForm = [...forms[steps.PARTICIPANTS_INFO.NAME] || []];


  if (step.name === steps.CAR_DAMAGE_TYPE.NAME) {
    if (carDamageTypeForm.carDamageType === carDamageTypeConstants.MECHANICAL_DAMAGE) {
      vehicleTypeForm.finalVehicleType = vehicleTypeConstants.COMPANY;
    } else {
      vehicleTypeForm.finalVehicleType = vehicleTypeForm.vehicleType;
    }

    const isParkedForms = [carDamageTypeConstants.THEFT, carDamageTypeConstants.MALICIOUS_ACTS, carDamageTypeConstants.KEY_LOSS, carDamageTypeConstants.DAMAGE_PARKING_LOT];
    if (isParkedForms.indexOf(carDamageTypeForm.carDamageType) !== -1) {
      parkedForm.finalParked = true;
    } else {
      parkedForm.finalParked = parkedForm.parked;
    }
  }

  if (step.name === steps.CAR_DAMAGE_TYPE.NAME || step.name === steps.VEHICLE_TYPE.NAME || step.name === steps.THEFT_TYPE.NAME) {
    const noMultipleParticipantsForms = [
      carDamageTypeConstants.THEFT,
      carDamageTypeConstants.MALICIOUS_ACTS,
      carDamageTypeConstants.NATURAL_DISASTER,
      carDamageTypeConstants.HAIL,
      carDamageTypeConstants.BREAKING_GLASS,
      carDamageTypeConstants.BREAKING_LIGHTS_MIRRORS,
      carDamageTypeConstants.ANIMAL_ENCOUNTERS,
      carDamageTypeConstants.KEY_LOSS,
      carDamageTypeConstants.MECHANICAL_DAMAGE,
      carDamageTypeConstants.COLLISION,
    ];
    if (noMultipleParticipantsForms.indexOf(carDamageTypeForm.carDamageType) !== -1) {
      participantsNumberForm.finalMultipleParticipants = booleanTypesConstants.NO;
    } else {
      participantsNumberForm.finalMultipleParticipants = participantsNumberForm.multipleParticipants;
    }

    participantsNumberForm.finalParticipants = calculateFinalParticipants(
      vehicleTypeForm.finalVehicleType,
      participantsNumberForm.participants,
      participantsNumberForm.finalMultipleParticipants,
    );
  }

  if ((step.name === steps.CAR_DAMAGE_TYPE.NAME || step.name === steps.VEHICLE_TYPE.NAME) && !isUndefined(participantsInfoForm[0])) {
    participantsInfoForm[0] = {
      ...participantsInfoForm[0],
      info: participantsNumberForm.finalParticipants[0],
    };
  }

  if (step.name === steps.PARTICIPANTS_NUMBER.NAME && !isUndefined(participantsInfoForm)) {
    participantsNumberForm.finalParticipants.forEach(participantNumber => {
      const participantInfo = participantsInfoForm[participantNumber.index];

      if (!isUndefined(participantInfo)) {
        participantsInfoForm[participantNumber.index] = {
          ...participantInfo,
          info: participantNumber,
        };
      }
    });
  }

  if (step.name === steps.VEHICLE_TYPE.NAME && vehicleTypeForm.vehicleType === vehicleTypeConstants.BIKE) {
    parkedForm.finalParked = false;
  }

  result.vehicleType = vehicleTypeForm;
  result.parked = parkedForm;
  result.participantsNumber = participantsNumberForm;
  result.participantsInfo = participantsInfoForm;

  return result;
}
