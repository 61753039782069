import React from 'react';

import SelectField from "components/form/SelectField";
import sexConstants from "constants/sex";

export default function SexField({ name, label = "sex.label", placeholder = "sex.placeholder", isRequired }) {
    const placeholderLabel = isRequired ? placeholder + ".required": placeholder;

    return (
      <SelectField name={name}
                   isRequired={isRequired}
                   placeholder={placeholder}
                   label={label}>
        <SelectField.Option value="" label={placeholderLabel} disabled hidden/>
        <SelectField.Option value={sexConstants.FEMALE} label="sex.female"/>
        <SelectField.Option value={sexConstants.MALE} label="sex.male"/>
      </SelectField>
    )
}
