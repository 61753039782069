import React, {Component} from "react";

import cx from "classnames";
import {connect} from "react-redux";
import _ from "lodash";

import {jumpStep, openLogOut, openSaveDialog, previousStep} from "actions/flow";
import Layout from "components/common/Layout";
import Text from "components/common/Text";
import ProgressBar from "components/step/ProgressBar";
import flowConstants from "constants/flow";
import {getInitials, getStepCount, getStepIndex, getStoreKey, hasPreviousStep} from "selectors/flow";

import headerClose from "images/header-close.svg";
import save from "images/save.svg";
import headerIllustration from "images/generali-lion.svg";

import styles from "containers/step/Header.module.scss"

class Header extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.stepKey === this.props.stepKey;
  }

  render() {
    const {
      index,
      count,
      fromSummary,
      jumpStep,
      showJumpToSummary,
      disabledJumpToSummary,
      openSaveDialog,
      showEnumeration = false,
      loggedInFromCounter,
      openLogOut,
      loggedInInitials,
      showSave = true,
    } = this.props;

    const jumpToSummaryClassName = cx({
      [styles.back]: true,
      [styles.close]: true,
    });

    const counterLogOutClassName = cx({
      [styles.back]: true,
    });

    const headerClassName = cx({
      [styles.root]: true,
      [styles.fromSummary]: fromSummary,
      [styles.hide]: showJumpToSummary,
    });

    const wrapperClassName = cx({
      [styles.wrapper]: true,
      [styles.fromSummary]: fromSummary,
    });

    const jumpToSummary = (
      <button className={jumpToSummaryClassName} type="button" disabled={disabledJumpToSummary}
              onClick={jumpStep({name: flowConstants.steps.SUMMARY.NAME})}>
        <img src={headerClose} alt="close"/>
      </button>
    );

    const counterLogOut = (
      <button className={counterLogOutClassName}
              type="button"
              onClick={openLogOut}
              style={{marginLeft: "1px"}}>
        <Text>{loggedInInitials}</Text>
      </button>
    );

    return (
      <div className={wrapperClassName}>
        <div className={headerClassName}>
          <Layout layoutAutoSize={true}>
            <Layout.Row>
              <Layout.Column>
                {showEnumeration
                  ? <div className={styles.count}>
                    {index}/{count}
                  </div>
                  : null}
              </Layout.Column>
              <Layout.Column>
                <Layout.Row alignItems="stretch">
                  {fromSummary || !showSave
                    ? null
                    : <button className={styles.back} type="button" onClick={openSaveDialog}>
                        <img src={save} alt="save"/>
                        <Text label="button.save" element="span" variant="variant28"/>
                      </button>
                  }
                  {loggedInFromCounter ? counterLogOut : null}
                </Layout.Row>
              </Layout.Column>
            </Layout.Row>
          </Layout>
          <ProgressBar value={index} size={count}/>
          <img className={styles.illustration} src={headerIllustration} alt="header illustration"/>
        </div>
        {showJumpToSummary ? jumpToSummary : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stepKey: getStoreKey(state.flow.step),
  index: getStepIndex(state.flow) + 1,
  count: getStepCount(state.flow),
  hasPreviousStep: hasPreviousStep(state.flow),
  disabledJumpToSummary: state.flow.isDirty,
  showJumpToSummary: state.flow.jumpedFromSummary,
  fromSummary: state.flow.inSummary || false,
  loggedInFromCounter: _.at(state, "flow.counterUser.authenticated")[0] || false,
  loggedInInitials: getInitials(state.flow),
});

const mapDispatchToProps = (dispatch) => ({
  jumpStep: (step) => () => dispatch(jumpStep(step)),
  previousStep: () => dispatch(previousStep()),
  openSaveDialog: () => dispatch(openSaveDialog()),
  openLogOut: () => dispatch(openLogOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
