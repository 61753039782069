import React, {Component} from "react";

import cx from "classnames";
import {Field} from "formik";
import {isEmpty} from "underscore";

import Translation from "components/common/Translation";
import CompositeField from "components/form/CompositeField";

import styles from "components/form/SelectField.module.scss";

function SelectField({name, children, ...props}) {
  return (
    <Field
      name={name}
      render={(fieldProps) => (
        <CompositeField name={name} {...props}>
          {context => (
            <InnerSelectField {...fieldProps.field} form={fieldProps.form} {...props} context={context}>
              {children}
            </InnerSelectField>
          )}
        </CompositeField>
      )}
    />
  );
}

export class InnerSelectField extends Component {
  componentDidMount() {
    const {value, context, name} = this.props;

    if (!isEmpty(value)) {
      context.label.initialNotEmpty(name);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const {value, context, name} = this.props;

    if (isEmpty(prevProps.value) && !isEmpty(value)) {
      context.label.initialNotEmpty(name);
    }
  }

  onBlur = (e) => {
    const {name, context, value} = this.props;
    const {label: {handleBlur}} = context;

    handleBlur(value, name);

    if (this.props.handleBlur) {
      this.props.handleBlur(e);
    }
  };

  onFocus = (e) => {
    const {context, name} = this.props;
    const {label: {handleFocus}} = context;

    handleFocus(name);
  };

  onChange = (e) => {
    const {form, name, onValueChange} = this.props;

    form.setFieldValue(name, e.target.value)

    if (onValueChange) {
      onValueChange();
    }
  };

  render() {
    const {children, value, context, onValueChange, ...props} = this.props;
    const className = cx({
      [styles.root]: true,
      [styles.invalid]: context.invalid,
    });

    return (
      <div className={styles.wrapper}>
        <select {...props}
                className={className}
                value={value || ""}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={this.onChange}>
          {children}
        </select>
      </div>
    );
  }
}

function SelectFieldOption({value, label, children, ...props}) {
  if (children) {
    return (
      <option value={value} {...props}>{children}</option>
    );
  }

  return (
    <Translation label={label} render={({value: text}) => (
      <option value={value} {...props}>{text}</option>
    )}/>
  );
}

SelectField.Option = SelectFieldOption;

export default SelectField;
