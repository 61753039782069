import React, {Component} from "react";

import Layout from "components/common/Layout";
import NextStepButton from "components/common/NextStepButton";
import Text from "components/common/Text";
import ToggleButton from "components/form/ToggleButton";
import RepairServiceIcon from "components/icon/RepairServiceIcon";
import fontConstants from "constants/font";
import repairServiceTypeConstants from "constants/repairServiceTypes";

export default class MapsIntroRight extends Component {
  render() {
    const {onNextStep} = this.props;

    return (
      <Layout>
        <Layout.OneColumn>
          <Text element="h2" label="mapsIntro.content.title" variant="variant1"/>
        </Layout.OneColumn>
        <Layout.OneColumn spacing={10} />
        <Layout.OneColumn width="100%">
          <NextStepButton component={ToggleButton}
                          isValid={true}
                          onNextStep={onNextStep}
                          buttonProps={{
                            name: "repairServiceType",
                          }}>
            <Layout spacing={10} width="100%" textAlign="center">
              <Layout.OneColumn>
                <ToggleButton.Choice value={repairServiceTypeConstants.VEHICLE_DAMAGE_INSPECTION_POINT}
                                     icon={<RepairServiceIcon icon={fontConstants.REPAIR_SERVICE_ICON.CAR_SERVICE}/>}
                                     label="mapsIntro.content.button.vehicleDamageInspectionPoint.title"
                                     descriptionLabel="mapsIntro.content.button.vehicleDamageInspectionPoint.subtitle"
                                     showCheckMark={false}
                                     variant="variant24"/>
              </Layout.OneColumn>
              <Layout.OneColumn>
                <ToggleButton.Choice value={repairServiceTypeConstants.BUSINESS_UNIT}
                                     icon={<RepairServiceIcon icon={fontConstants.REPAIR_SERVICE_ICON.GARAGE}/>}
                                     label="mapsIntro.content.button.businessUnit.title"
                                     showCheckMark={false}
                                     variant="variant24"/>
              </Layout.OneColumn>
            </Layout>
          </NextStepButton>
        </Layout.OneColumn>
      </Layout>
    );
  }
}