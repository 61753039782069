import React, {Component} from "react";

import Text from "components/common/Text";
import SummaryHeader from "components/common/SummaryHeader";
import fieldLengthConstants from "constants/fieldLength";
import flowConstants from "constants/flow";
import wasDriverTypeConstants from "constants/wasDriverTypes";
import ApplicantDataRight from "containers/step/applicantData/ApplicantDataRight";
import Header from "containers/step/Header";
import SplitViewContainer from "containers/view/SplitViewContainer";
import Yup, {getMax} from "selectors/yup";

const addressValidationSchema = Yup.object().shape({
  countryName: Yup.string().trim().required("validation.address.country"),
  address: Yup.object().shape({
    streetNumber: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].streetNumber),
    streetName: getMax(Yup.string().typeError("validation.address.streetNo").trim().required("validation.address.streetNo"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].streetName),
  }),
  city: Yup.object().shape({
    zip: getMax(Yup.string().trim().required("validation.address.zip"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].zip),
    city: getMax(Yup.string().trim().required("validation.address.city"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].city),
  }),
});

const generalValidationSchema = Yup.object().shape({
  name: getMax(Yup.string().trim().required("validation.required"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].name),
  surname: getMax(Yup.string().trim().required("validation.required"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].surname),
});

const driverValidationSchema = Yup.object().shape({
  name: getMax(Yup.string().trim().required("validation.required"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].name),
  surname: getMax(Yup.string().trim().required("validation.required"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].surname),
  birthDate: Yup.mixed().nullable().required("validation.required"),
  sex: Yup.string().trim().required("validation.required"),
  address: addressValidationSchema,
  telephoneNumber:getMax(Yup.string().trim().validatePhoneNumber("validation.telephoneNumber.format").required("validation.telephoneNumber"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].telephoneNumber),
  licenceNumber: getMax(Yup.string().trim().required("validation.required"), fieldLengthConstants[flowConstants.steps.APPLICANT_DATA.NAME].licenceNumber),
});

const validationSchema = Yup.object().shape({
  general: Yup.object().when("wasDriver", {
    is: wasDriver => wasDriver !== wasDriverTypeConstants.YES,
    then: generalValidationSchema
  }),
  driver: Yup.object().when("wasDriver", {
    is: wasDriver => wasDriver === wasDriverTypeConstants.YES,
    then: driverValidationSchema
  }),
})

export default class ApplicantDataStepContainer extends Component {
  left = ({fromSummary}) => {
    return (
      <SplitViewContainer.Left>
        <Header/>
        <SummaryHeader fromSummary={fromSummary}/>
        <Text element="h2" label="applicantData.sidebar.title"/>
        <Text element="div" label="applicantData.sidebar.subtitle" isHtml={true}/>
      </SplitViewContainer.Left>
    );
  };

  right = (props) => {
    return (
      <SplitViewContainer.Right {...props}>
        <ApplicantDataRight {...props}/>
      </SplitViewContainer.Right>
    );
  };

  render() {
    return (
      <SplitViewContainer left={this.left} right={this.right} validationSchema={validationSchema}/>
    );
  }
}