import React from "react";

import {LightgalleryProvider} from "@croz/react-lightgallery";

import Layout from "components/common/Layout";
import PhotosIcon from "components/icon/PhotosIcon";
import SummaryTable from "components/step/summary/SummaryTable";
import claimTypesConstants from "constants/claimTypes";
import flowConstants from "constants/flow";
import summaryRefConstants from "constants/summaryRef";
import Summary from "containers/step/summary/Summary";

export default function SummaryPhotos({claimType}) {
  return (
    <Summary title={`summary.content.photos.${claimType}.title`}
             onePart={true}
             refName={summaryRefConstants.PHOTOS}>
      <Summary.Items formName={{
        name: claimType === claimTypesConstants.HOME
          ? flowConstants.homeSteps.HOME_DOCUMENTS.NAME
          : claimType === claimTypesConstants.GENERAL
            ? flowConstants.generalSteps.GENERAL_DOCUMENTS.NAME
            : flowConstants.steps.DOCUMENTS.NAME
      }}>
        {form => (
          <SummaryTable.ItemContent jumpTo={form.jumpTo} clickyTable={false}>
            <LightgalleryProvider lightgallerySettings={{download: false, thumbnail: false}}>
              <Layout flex={1} spacing={10}>
                {form.photos.map((photo, index) =>
                  <PhotosIcon key={index} {...photo} group="summaryPhotosGroup"/>
                )}
              </Layout>
            </LightgalleryProvider>
          </SummaryTable.ItemContent>
        )}
      </Summary.Items>
    </Summary>
  );
}
