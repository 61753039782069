import React, {Component} from "react";

import _ from "lodash";

import {I18nContext} from "containers/context/I18nContainer";

export default class Translation extends Component {
  static contextType = I18nContext;

  render() {
    const {label, element, render = null, parameters = []} = this.props;
    const labelAndParams = label.toString().split("|");
    const cleanLabel = labelAndParams[0];
    const cleanParameters = [...labelAndParams.slice(1), ...parameters];

    const translations = this.context.translations || {};
    const isTranslated = translations.hasOwnProperty(cleanLabel);
    const translation = isTranslated ? translations[cleanLabel] : label;
    const value = injectParameters(translation, cleanParameters);

    if (element) {
      return React.createElement(element, {}, value);
    } else if (render) {
      return render({isTranslated, value});
    } else {
      return null;
    }
  }
}

// foo {0} bar {1} foo => ["foo ", 0, " bar ", 1, " foo"]
function splitValue(value) {
  const regex = /\{(\d+)\}/g;
  const result = [];

  let m;
  let lastIndex = 0;
  while ((m = regex.exec(value)) !== null) {
    if (m.index > lastIndex) {
      result.push(value.slice(lastIndex, m.index));
    }

    result.push(parseInt(m[1]));
    lastIndex = m.index + m[0].length;
  }

  if (value.length > lastIndex) {
    result.push(value.slice(lastIndex, value.length));
  }

  return result;
}

function injectParameters(value, parameters) {
  const parts = splitValue(value);

  return parts.map((part, index) => {
    if (!_.isNumber(part)) {
      return part;
    }

    const parameter = parameters[part];

    return React.isValidElement(parameter) ? React.cloneElement(parameter, {key: index}) : parameter;
  });
}