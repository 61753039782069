import React from "react";

import sketchConstants from "constants/sketch";
import VehicleIconSvg from "components/svg/VehicleIconSvg";
import SketchEraserPath from "components/sketch/item/SketchEraserPath";
import SketchPath from "components/sketch/item/SketchPath";
import SketchText from "components/sketch/item/SketchText";
import {findImage, getItemDimension} from "selectors/sketch";

export default function SketchItem({className, image, item, ...props}) {
  const {type, value} = item;

  switch (type) {
    case sketchConstants.TYPES.ITEM:
      return (
        <svg {...props}
             className={className}
             xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink"
             width={sketchConstants.ITEM_DIMENSION.SOURCE_WIDTH}
             height={sketchConstants.ITEM_DIMENSION.SOURCE_HEIGHT}>
          <rect x={0} y={0} width="100%" height="100%"
                stroke={"#555"} strokeOpacity={props.selected ? 1 : 0} strokeDasharray="2" strokeWidth={2}
                fillOpacity={0}/>
          <g dangerouslySetInnerHTML={{__html: image(findImage(value))}}/>
        </svg>
      );
    case sketchConstants.TYPES.PARTICIPANT:
      return (
        <VehicleIconSvg className={className}
                        value={value}
                        {...props} />
      );
    case sketchConstants.TYPES.TEXT:
      return (
        <SketchText value={value} {...props} />
      );
    case sketchConstants.TYPES.DRAW:
      return (
        <SketchPath value={value} dimensions={getItemDimension(item)} {...props} />
      );
    case sketchConstants.TYPES.ERASER:
      return (
        <SketchEraserPath value={value} dimensions={getItemDimension(item)} {...props} />
      );
    default:
      return null;
  }
}
