import React from "react";

import cx from "classnames";

import SketchNavigationItem from "components/sketch/item/SketchNavigationItem";

import styles from "components/sketch/navigation/SketchNavigationButton.module.scss";

export default function SketchNavigationButton({iconClassName, ...props}) {
  return (
    <div className={styles.container}>
      <SketchNavigationItem buttonClassName={styles.button} className={cx(styles.icon, iconClassName)} item={props} />
    </div>
  )
}
