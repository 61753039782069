import React from "react";

import Layout from "components/common/Layout";
import SummaryBankAccounts from "containers/step/summary/bankAccounts/SummaryBankAccounts";
import SummaryDocuments from "containers/step/summary/documents/SummaryDocuments";
import SummaryDamagedGoodsInfo from "containers/step/summary/home/SummaryDamagedGoodsInfo";
import SummaryInfoHome from "containers/step/summary/home/SummaryInfoHome";
import SummaryWitnesses from "containers/step/summary/home/SummaryWitnesses";
import SummaryHomeResponsiblePerson from "containers/step/summary/home/SummaryHomeResponsiblePerson";
import SummaryPhotos from "containers/step/summary/photos/SummaryPhotos";
import SummaryUser from "containers/step/summary/userInfo/SummaryUser";
import SummaryApplicant from "./userInfo/SummaryApplicant";

export default function SummaryHome({claimType, ...props}) {
  return (
    <>
      <Layout.OneColumn {...props}>
        <SummaryInfoHome claimType={claimType}/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryDamagedGoodsInfo/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryPhotos claimType={claimType}/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryDocuments claimType={claimType}/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryWitnesses/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryHomeResponsiblePerson/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryApplicant/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryUser/>
      </Layout.OneColumn>

      <Layout.OneColumn {...props}>
        <SummaryBankAccounts/>
      </Layout.OneColumn>

    </>
  );
}