import React from "react";

import sketchConstants from "constants/sketch";
import {svgTransform} from "selectors/sketch";

export default function SketchSvg({dimensions, groupClassName, children, shouldCenter = true, useCanvasDimensions = true}) {
  const minDimension = Math.min(dimensions.width, dimensions.height);
  const transformSvg =
    shouldCenter
      ? (
        svgTransform(
          minDimension, minDimension,
          sketchConstants.DIMENSIONS.CANVAS.WIDTH, sketchConstants.DIMENSIONS.CANVAS.HEIGHT
        )
      )
      : (
        svgTransform(
          dimensions.width, dimensions.height,
          sketchConstants.DIMENSIONS.CANVAS.WIDTH, sketchConstants.DIMENSIONS.CANVAS.HEIGHT
        )
      );

  const svgProps =
    useCanvasDimensions
      ? {width: sketchConstants.DIMENSIONS.CANVAS.WIDTH, height: sketchConstants.DIMENSIONS.CANVAS.HEIGHT}
      : {width: minDimension, height: minDimension};

  return (
    <svg {...svgProps}
         xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <g transform={transformSvg} className={groupClassName}>
        {children}
      </g>
    </svg>
  );
}
